import * as React from 'react';
import { LoadingIndicatorInline } from '../LoadingIndicator';
import { __ } from '../../helpers/TranslationService';

interface StateProps {
    links: any;
    isLoading: boolean;
}

interface DispatchProps {
    loadMoreReviews: Function;
}

type HomeProps = StateProps & DispatchProps;

export const LoadMoreReviewsBtn = ({ links, isLoading, loadMoreReviews }: HomeProps) => {
    const onLoadMoreResults = () => {
        loadMoreReviews(links.next);
    };

    const getButton = () => {
        if (links?.next && !isLoading) {
            return (
                <a
                    className="load-more-reviews-btn__link icon-arrowDown"
                    onClick={onLoadMoreResults}
                >
                    {__('load more', 'dreamlines')}
                </a>
            );
        }
    };
    return (
        <div className="load-more-reviews-btn">
            {getButton()}
            {isLoading && <LoadingIndicatorInline />}
        </div>
    );
};
