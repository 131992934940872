import { IUGCLabel } from '../interfaces/ISearchApi';

export function objectKeys<T>(obj: T) {
    return Object.keys(obj) as (keyof T)[];
}

export type ILabelName =
    | 'bestseller'
    | 'highDemand'
    | 'shipRating'
    | 'cabinRating'
    | 'gastronomyRating'
    | 'entertainmentRating'
    | 'serviceRating'
    | 'firstCruise'
    | 'experiencedCruiseTraveler'
    | 'ageRangeYoung'
    | 'ageRangeSenior'
    | 'itineraryRating'
    | 'excursionsRating'
    | 'constellationcouple'
    | 'constellationfamily'
    | 'constellationfamilysmallchildren'
    | 'constellationsingle'
    | 'constellationfriends'
    | 'atmospherefamiliar'
    | 'atmosphererelaxed'
    | 'atmospherechaotic'
    | 'atmospherediverse'
    | 'atmosphereelegant'
    | 'atmosphereloud'
    | 'atmosphereboring'
    | 'recommend';

interface IClusterItem {
    name: ILabelName;
    priority: number;
    subpriority?: number;
    [key: string]: any;
}

export type IClusterName =
    | 'productData'
    | 'shipRatings'
    | 'experienceLabels'
    | 'ageRange'
    | 'routeRatings'
    | 'constellation'
    | 'atmosphere'
    | 'ugcReview';

type ILabelsCluster = { [key in IClusterName]: IClusterItem[] };

const labelsCluster: ILabelsCluster = {
    productData: [
        {
            name: 'bestseller',
            priority: 3,
        },
        {
            name: 'highDemand',
            priority: 12,
        },
    ],
    shipRatings: [
        {
            name: 'shipRating',
            priority: 4,
        },
        {
            name: 'cabinRating',
            priority: 6,
        },
        {
            name: 'gastronomyRating',
            priority: 7,
        },
        {
            name: 'entertainmentRating',
            priority: 11,
        },
        {
            name: 'serviceRating',
            priority: 16,
        },
    ],
    experienceLabels: [
        {
            name: 'firstCruise',
            priority: 8,
            subpriority: 1,
        },
        {
            name: 'experiencedCruiseTraveler',
            priority: 8,
            subpriority: 2,
        },
    ],
    ageRange: [
        {
            name: 'ageRangeYoung',
            priority: 9,
            subpriority: 1,
        },
        {
            name: 'ageRangeSenior',
            priority: 9,
            subpriority: 2,
        },
    ],
    routeRatings: [
        {
            name: 'itineraryRating',
            priority: 5,
        },
        {
            name: 'excursionsRating',
            priority: 10,
        },
    ],
    constellation: [
        {
            name: 'constellationcouple',
            priority: 13,
            subpriority: 1,
        },
        {
            name: 'constellationfamily',
            priority: 13,
            subpriority: 2,
        },
        {
            name: 'constellationfamilysmallchildren',
            priority: 13,
            subpriority: 3,
        },
        {
            name: 'constellationsingle',
            priority: 13,
            subpriority: 4,
        },
        {
            name: 'constellationfriends',
            priority: 13,
            subpriority: 5,
        },
    ],
    atmosphere: [
        {
            name: 'atmospherefamiliar',
            priority: 14,
            subpriority: 1,
        },
        {
            name: 'atmosphererelaxed',
            priority: 14,
            subpriority: 2,
        },
        {
            name: 'atmospherechaotic',
            priority: 14,
            subpriority: 3,
        },
        {
            name: 'atmospherediverse',
            priority: 14,
            subpriority: 4,
        },
        {
            name: 'atmosphereelegant',
            priority: 14,
            subpriority: 5,
        },
        {
            name: 'atmosphereloud',
            priority: 14,
            subpriority: 6,
        },
        {
            name: 'atmosphereboring',
            priority: 14,
            subpriority: 7,
        },
    ],
    ugcReview: [
        {
            name: 'recommend',
            priority: 15,
        },
    ],
};

export interface INormalizedUGCLabel {
    label: ILabelName;
    type: IClusterName;
}
export const normalizeLabels = ({
    ugcLabels,
    excludeCluster = [],
}: {
    ugcLabels: IUGCLabel[];
    excludeCluster?: IClusterName[];
}): INormalizedUGCLabel[] => {
    let prioritizedLabels = [];
    for (let key of objectKeys(labelsCluster)) {
        let cluster: IClusterItem[] = labelsCluster[key];

        const filteredLabelsClusterItems =
            cluster &&
            !excludeCluster.includes(key) &&
            cluster.filter((clusterItem: IClusterItem) =>
                ugcLabels?.some((ugcLabel: IUGCLabel) => {
                    const trimmedUGCLabel = ugcLabel?.label.replace(/[^a-zA-Z]/g, '');
                    return trimmedUGCLabel === clusterItem.name;
                }),
            );

        const mappedClusterItems = filteredLabelsClusterItems
            ? filteredLabelsClusterItems.map((label: IClusterItem) => {
                  return { label: label.name, type: key };
              })
            : [];

        filteredLabelsClusterItems && prioritizedLabels.push(...mappedClusterItems);
    }
    return prioritizedLabels;
};
