import React from 'react';
import styled from '@emotion/styled';
import { __ } from '../../../../helpers/TranslationService';
import * as InclusivesIcons from '../../helper/InclusiveIcons';

interface IProps {
    icons: { icon: string; text: string }[];
}

const PILLS_GAP_PX = 4;
const MAX_PILLS_ROW_COUNT = 2;

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0 0 0;
    margin: 0 16px 16px 16px;
    gap: ${PILLS_GAP_PX}px;
    position: relative;
`;

const FeaturePill = styled.p<{ isVisible?: boolean }>`
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    align-items: center;
    background: ${(props) => props.theme.background.layerSecondary};
    border: 2px solid ${(props) => props.theme.border.onLayer};
    border-radius: 8px;
    padding: 8px;

    span {
        margin-left: 4px;
    }
`;

const ShowMoreBtn = styled.button`
    background: transparent;
    cursor: pointer;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.background.layerSecondary};
    border: 2px solid ${(props) => props.theme.border.onLayer};
    border-radius: 8px;
    padding: 8px;
`;

// Defined order as per DREAM-17751
// Cruise, Flight, Additional Flight, Train Ride,Hotel, Tour, Cruise Tour, Bicycle Tour, All Inclusive, Free Wifi, Drinks, Full Board, Half Board, Tips, Children Travel Free, Breakfest

const INCLUSIVES_ORDER = [
    'CruiseIcon',
    'FlightIcon',
    'FlightExtraIcon',
    'TrainIcon',
    'HotelIcon',
    'TourIcon',
    'BicycleTourIcon',
    'AllInclusiveIcon',
    'FreeWiFiIcon',
    'WineIcon',
    'FullBoardIcon',
    'MoneyIcon',
    'KidsIcon',
    'BreakfastIcon',
];

const getInclusivePillWidth = (el: HTMLParagraphElement) => {
    return (el?.getBoundingClientRect().width || 0) + PILLS_GAP_PX;
};

export const SearchResultItemFeatures = (props: IProps) => {
    const containerRef = React.useRef(null);
    const pillRefs = React.useRef<HTMLParagraphElement[]>([]);
    const [visiblePills, setVisiblePills] = React.useState([]);
    const [overflowPills, setOverflowPills] = React.useState([]);
    const [showAll, setShowAll] = React.useState(true);

    const inclusives = React.useMemo(
        () =>
            [{ icon: 'CruiseIcon', text: 'cruiseData.cruiseVal' }, ...props?.icons]
                ?.filter((item) => INCLUSIVES_ORDER.includes(item?.icon) && item?.icon)
                ?.sort((a, b) => {
                    const indexA = INCLUSIVES_ORDER.indexOf(a.icon);
                    const indexB = INCLUSIVES_ORDER.indexOf(b.icon);

                    if (indexA !== -1 && indexB !== -1) return indexA - indexB;
                    if (indexA !== -1) return -1;
                    if (indexB !== -1) return 1;
                    return 0;
                }),
        [props.icons],
    );

    React.useLayoutEffect(() => {
        const calculateVisiblePills = () => {
            const containerWidth = containerRef.current?.offsetWidth;
            let totalWidth = 0;
            let rowCount = 1;
            let lastRowElementIndex = 0;

            pillRefs.current?.forEach((el, idx) => {
                const pillWidth = getInclusivePillWidth(el);
                totalWidth += pillWidth;

                if (totalWidth > containerWidth) {
                    rowCount = rowCount + 1;
                    totalWidth = pillWidth;
                    lastRowElementIndex = idx;
                }
                if (rowCount > MAX_PILLS_ROW_COUNT) {
                    // remove last pill from last row to replace with '+' button
                    lastRowElementIndex = lastRowElementIndex - 1;
                    return;
                }
            });

            const visible = inclusives.slice(
                0,
                rowCount > 2 ? lastRowElementIndex : inclusives.length,
            );
            const overflow = inclusives.slice(
                rowCount > 2 ? lastRowElementIndex : inclusives.length,
            );

            setVisiblePills(visible);
            setOverflowPills(overflow);

            if (overflow.length) {
                setShowAll(false);
            }
        };

        if (pillRefs.current?.length === inclusives.length) {
            calculateVisiblePills();
        }
    }, [inclusives]);

    return (
        <StyledContainer ref={containerRef}>
            {inclusives?.map((item, idx) => {
                const IconComp = InclusivesIcons[item?.icon];
                return (
                    <FeaturePill
                        isVisible={visiblePills.includes(item) || showAll}
                        key={item?.icon}
                        ref={(el) => (pillRefs.current[idx] = el)}
                    >
                        {IconComp && <IconComp width={12} height={12} />}
                        <span>{__(item?.text, 'dreamlines')}</span>
                    </FeaturePill>
                );
            })}

            {overflowPills.length > 0 && !showAll && (
                <ShowMoreBtn onClick={() => setShowAll(true)}>+{overflowPills.length}</ShowMoreBtn>
            )}
        </StyledContainer>
    );
};
