import * as React from 'react';
import { config } from '../../config';
import * as Colors from '../../consts/variables';

let style: any = {
    labelContainer: {
        height: '25px',
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.labels.main2,
    },
    labelText: {
        fontSize: '12px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        color: Colors.labels.main2,
    },
    svgImageDiv: {
        width: '25px',
        height: '25px',
        marginLeft: '-8px',
        marginRight: '5px',
    },
    svgImgStyle: {
        width: '25px',
        height: '25px',
        padding: '5px',
    },
};

export type ILabelName =
    | 'bestseller'
    | 'highDemand'
    | 'shipRating'
    | 'cabinRating'
    | 'gastronomyRating'
    | 'entertainmentRating'
    | 'serviceRating'
    | 'firstCruise'
    | 'experiencedCruiseTraveler'
    | 'ageRangeYoung'
    | 'ageRangeSenior'
    | 'itineraryRating'
    | 'excursionsRating'
    | 'constellationcouple'
    | 'constellationfamily'
    | 'constellationfamilysmallchildren'
    | 'constellationsingle'
    | 'constellationfriends'
    | 'atmospherefamiliar'
    | 'atmosphererelaxed'
    | 'atmospherechaotic'
    | 'atmospherediverse'
    | 'atmosphereelegant'
    | 'atmosphereloud'
    | 'atmosphereboring'
    | 'recommend';

export type IClusterName =
    | 'productData'
    | 'shipRatings'
    | 'experienceLabels'
    | 'ageRange'
    | 'routeRatings'
    | 'constellation'
    | 'atmosphere'
    | 'ugcReview';

export interface IUGCLabelProps {
    label: ILabelName;
    type: IClusterName;
}

interface ILabelCluster {
    [key: string]: {
        fontColor: string;
        backgroundColor: string;
        icon?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
    };
}

const translationKeysReplace = {
    routeRating: 'itineraryRating',
};

export const getTranslationKey = (key: string) => {
    return translationKeysReplace[key] ?? key.replace(' ', '');
};

const labelClusterType: ILabelCluster = {
    bestseller: {
        fontColor: Colors.white,
        backgroundColor: Colors.labels.bestseller,
    },
    highDemand: {
        fontColor: Colors.white,
        backgroundColor: Colors.labels.highDemand,
    },
    shipRating: {
        fontColor: Colors.white,
        backgroundColor: Colors.labels.shipRatings,
    },
    atmosphere: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    ageRange: { fontColor: Colors.white, backgroundColor: Colors.labels.main2 },
    productData: { fontColor: Colors.white, backgroundColor: Colors.labels.bestseller },
    cabinRating: {
        fontColor: Colors.white,
        backgroundColor: Colors.labels.cabinRatings,
    },
    gastronomyRating: { fontColor: Colors.white, backgroundColor: Colors.labels.gastroRating },
    entertainmentRating: {
        fontColor: Colors.white,
        backgroundColor: Colors.labels.entertainmentRating,
    },
    serviceRating: { fontColor: Colors.white, backgroundColor: Colors.labels.serviceRating },
    firstCruise: { fontColor: Colors.white, backgroundColor: Colors.labels.dreamdealMain },
    experienceLabels: {
        fontColor: Colors.white,
        backgroundColor: Colors.labels.dreamdealMain,
    },

    experiencedCruiseTraveler: {
        fontColor: Colors.white,
        backgroundColor: Colors.labels.dreamdealMain,
    },
    ageRangeYoung: { fontColor: Colors.white, backgroundColor: Colors.labels.main2 },
    ageRangeSenior: { fontColor: Colors.white, backgroundColor: Colors.labels.main2 },
    itineraryRating: { fontColor: Colors.white, backgroundColor: Colors.labels.routeRatings },
    excursionsRating: { fontColor: Colors.white, backgroundColor: Colors.labels.routeRatings },
    constellationcouple: {
        icon: (
            <img
                style={style.svgImgStyle}
                src={`${config.images}/common/labels/couple.svg`}
                alt="friends.svg"
            />
        ),
        fontColor: Colors.white,
        backgroundColor: Colors.labels.main2,
    },
    constellationfamily: {
        icon: (
            <img
                style={style.svgImgStyle}
                src={`${config.images}/common/labels/family.svg`}
                alt="family.svg"
            />
        ),
        fontColor: Colors.white,
        backgroundColor: Colors.labels.main2,
    },
    constellationfamilysmallchildren: {
        icon: (
            <img
                style={style.svgImgStyle}
                src={`${config.images}/common/labels/family-small-child.svg`}
                alt="family-small-child.svg"
            />
        ),
        fontColor: Colors.white,
        backgroundColor: Colors.labels.main2,
    },
    constellationsingle: {
        icon: (
            <img
                style={style.svgImgStyle}
                src={`${config.images}/common/labels/single.svg`}
                alt="single.svg"
            />
        ),
        fontColor: Colors.white,
        backgroundColor: Colors.labels.main2,
    },
    constellationfriends: {
        icon: (
            <img
                style={style.svgImgStyle}
                src={`${config.images}/common/labels/friends.svg`}
                alt="friends.svg"
            />
        ),
        fontColor: Colors.white,
        backgroundColor: Colors.labels.main2,
    },
    atmospherefamiliar: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    atmosphererelaxed: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    atmospherechaotic: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    atmospherediverse: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    atmosphereelegant: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    atmosphereloud: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    atmosphereboring: { fontColor: Colors.white, backgroundColor: Colors.labels.quote },
    recommend: { fontColor: Colors.white, backgroundColor: Colors.labels.main4 },
};

export const LabelBox = ({ label, type }) => {
    const labelType = labelClusterType[type];

    const labelBgColor = labelType ? labelType.backgroundColor : Colors.labels.quote;
    const labelColor = labelType ? labelType.fontColor : Colors.white;
    const labelIcon = labelType ? labelType.icon : null;
    style.labelContainer = {
        ...style.labelContainer,
        backgroundColor: labelIcon ? labelBgColor + 'B3' : labelBgColor,
    };

    style.labelText = { ...style.labelText, color: labelColor };

    return (
        <span style={style.labelContainer}>
            <span style={style.labelText}>
                {labelIcon ? (
                    <div
                        style={{
                            ...style.svgImageDiv,
                            backgroundColor: labelBgColor,
                        }}
                    >
                        {labelType.icon}
                    </div>
                ) : null}
                "{label}"
            </span>
        </span>
    );
};
