import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function PlusIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path d="M449.7,236.4H271.2V67.3c0-11.2-9.1-20.3-20.3-20.3c-11.2,0-20.3,9.1-20.3,20.3v169.1H62.7c-11.2,0-20.3,9.1-20.3,20.3c0,11.2,9.1,20.3,20.3,20.3h167.8v177.2c0,11.2,9.1,20.3,20.3,20.3c11.2,0,20.3-9.1,20.3-20.3V277h178.5c11.2,0,20.3-9.1,20.3-20.3C470,245.5,460.9,236.4,449.7,236.4z" />
        </Icon>
    );
}
