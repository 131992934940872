import * as React from 'react';

export function RatingBar(props) {
    const { rating, quantity, isOverallReview, caption, percentageRatingView } = props;
    const filledStyle = { width: `${rating * 10}%` };
    const ratingValuePercentage = Math.round(rating * 10) || 0;
    const ratingValueNumeric = rating?.toFixed(1);

    const Quantity = () => (
        <div className="rating-bar__rating-value">
            {quantity}
            <span className="rating-bar__icon icon-pegasus_user"></span>
        </div>
    );
    const Rating = () => (
        <div className="rating-bar__rating-value">
            <span>{percentageRatingView ? ratingValuePercentage + '%' : ratingValueNumeric}</span>
            {!percentageRatingView && (
                <span className="rating-bar__rating-value-grey">{` / 10`}</span>
            )}
        </div>
    );
    const barClasses = `rating-bar__bar ${isOverallReview ? '' : 'rating-bar__bar--shorter'}`;
    return (
        <tr className="rating-bar">
            <td className="rating-bar__description">
                <div className="rating-bar__caption">
                    {caption}
                    {isOverallReview ? <Quantity /> : <Rating />}
                </div>
            </td>
            <td className={barClasses}>
                <div className="rating-bar__bar-filled" style={filledStyle} />
            </td>
        </tr>
    );
}
