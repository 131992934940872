import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function BreakfastIcon({ width, height, viewBox, style, className }: IIcon) {
    return (
        <Icon width={width} height={height} viewBox={viewBox} style={style} className={className}>
            <g>
                <path d="M244,85.4L244,85.4c-13.2,13.2-19.5,26-19.5,39.3c0,13.3,6.5,27,19.5,40.8c2.1,2.1,4.3,3.1,7.2,3.1c2.8,0,5.1-0.9,7.2-3.1c4.1-4.1,4.1-10.2,0.1-14.3c-9.2-10-13.4-18.4-13.4-26.6c0-8.3,4.6-16.9,13.3-24.8c12.8-11.9,19.5-25.5,19.5-39.2c0-14.8-6.8-28.9-19.5-40.8c-4.1-4.1-10.2-4.1-14.3,0c-4.1,4.1-4.1,10.2-0.1,14.3c9.2,10,13.4,18.4,13.4,26.6C257.3,68.9,252.7,77.5,244,85.4z" />
                <path d="M314.8,124.7c0,13.3,6.5,27,19.5,40.8c2.1,2.1,4.3,3.1,7.2,3.1c1.9,0,5.1-0.9,6.5-3.3c1.8-1.9,2.8-4.4,2.8-7c0-2.7-1.1-5.2-3-7.1c-9.2-10-13.4-18.4-13.4-26.6c0-8.3,4.6-16.9,13.3-24.8c13.3-11.7,20.4-25.3,20.4-39.3c0-15.2-6.4-28.6-19.5-40.8c-2-2-4.5-3.1-7.2-3.1s-5.2,1.1-7.2,3.1c-2,2-3.1,4.5-3.1,7.2s1.1,5.2,3,7.1c9.2,10,13.4,18.4,13.4,26.6c0,7.9-4.1,15.6-13.4,24.9C321.2,98.6,314.8,111.4,314.8,124.7z" />
                <path d="M142.3,124.7c0,13.3,6.5,27,19.5,40.8c2.1,2.1,4.3,3.1,7.2,3.1c1.9,0,5.1-0.9,6.5-3.3c1.8-1.9,2.8-4.4,2.8-7c0-2.7-1.1-5.2-3-7.1c-9.2-10-13.4-18.4-13.4-26.6c0-8.3,4.6-16.9,13.3-24.8c13.3-11.7,20.4-25.3,20.4-39.3c0-15.2-6.4-28.6-19.5-40.8c-2-2-4.5-3.1-7.2-3.1s-5.2,1.1-7.2,3.1c-2,2-3.1,4.5-3.1,7.2s1.1,5.2,3,7.1c9.2,10,13.4,18.4,13.4,26.6c0,7.9-4.1,15.6-13.4,24.9C148.7,98.6,142.3,111.4,142.3,124.7z" />
                <path d="M203.8,386.4L203.8,386.4c-56.9-9.6-86.4-41.6-98.7-106.9c-1-5.2-7-9.2-12.2-8.1c-2.5,0.5-4.8,2.2-6.4,4.5s-2.2,5.2-1.7,7.6C98,357,133.5,395,199.8,406.8l2,0c5.2,0,9.2-3.2,10.2-8.2C212.9,392.9,209.3,387.5,203.8,386.4z" />
                <path d="M438.1,427.4h-78.5c24.1-11.6,37.8-21.9,51.9-38.3c61.4-9.7,88.9-60.9,88.9-107.5c0-32.4-17.9-60.7-41.9-66.6c-0.2-4.5-2.2-8.7-5.5-12.1c-3.6-3.6-8.1-5.5-12.9-5.5H62.1c-4.8,0-9.4,2-12.9,5.5c-3.6,3.6-5.5,8.1-5.5,12.9c0.4,54.3,6.6,94.7,19.6,127.1c15.8,39.5,41.6,67.2,78.5,84.4H62.5c-17.4,0-31.5,14.1-31.5,31.5v0.6c0,17.4,14.1,31.5,31.5,31.5h375.6c17.4,0,31.5-14.1,31.5-31.5v-0.6C469.6,441.5,455.4,427.4,438.1,427.4z M246.8,427.4C88.6,425.6,65.2,325.9,64.2,217.9H438c-1.1,108-24.5,207.8-182.6,209.5H246.8z M479.1,281.6c0,29.6-13.1,65.7-49.3,80.7c21.9-38.7,26.8-84.9,28.5-125.4C470.5,243.1,479.1,261.2,479.1,281.6z M448.8,459.5c0,5.9-4.8,10.7-10.7,10.7H62.5c-5.9,0-10.7-4.8-10.7-10.7v-0.6c0-5.9,4.8-10.7,10.7-10.7h375.6c5.9,0,10.7,4.8,10.7,10.7V459.5z" />
            </g>
        </Icon>
    );
}
