import React, { memo, useEffect, useRef } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import styled from '@emotion/styled';
import { IZoneTab } from '../../interfaces/IZoneTabs';
import { CardSlider } from '../Card/CardSlider';
import { Card } from '../Card/Card';
import { CardsWrapper } from '../Card/CardWrapper';
import { mapCruiseRecommendationData } from '../Card/helpers';
import Link from '../Link';
import { Button } from '../Button/Button';
import { __ } from '../../helpers/TranslationService';
import { getDiscountAboveThreshold } from '../../helpers/priceHelpers';
export interface IZoneTabsProps {
    title?: string;
    buttonTitle?: string;
    data: IZoneTab[];
    className?: string;
}

const ZoneTabsContainer = styled.div`
    margin-bottom: 60px;
    @media (min-width: ${BreakpointsEnum.xs}) and (max-width: ${BreakpointsEnum.md}) {
        .slider-item {
            flex: 0 0 34% !important;
            min-width: 330px;
        }
    }
    @media (min-width: ${BreakpointsEnum.md}) {
        .priceDiscountStartFrom {
            display: none;
        }

        .slider {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .slider-arrow {
            display: none;
        }

        .slider-item {
            flex: 0 0 33.333333% !important;
            max-width: calc(100% / 3) !important;
            padding: 11px !important;
            > a:hover {
                color: ${({ theme }) => theme.icon.brandColor1};
            }
        }

        h2 {
            font-size: 25px !important;
            margin-top: 40px !important;
        }
    }
`;

const PillButtonWrapper = styled.div`
    text-align: center;
    padding: 0 6px;
    margin: 36px 0;
    & > span {
        margin: 0 6px;
    }
    @media (max-width: ${BreakpointsEnum.md}) {
        text-align: left;
        margin: 24px 0;
        white-space: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
`;

const PillButton = styled.span<{
    active: boolean;
}>`
    color: ${({ active, theme }) =>
        active ? theme.colors.white[100] : theme.background.brandColor2};
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    padding: 12px 20px;
    background-color: ${({ active, theme }) =>
        active ? theme.background.brandColor2 : theme.colors.white[100]};
    border-radius: 36px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.02);
    cursor: pointer;
`;

const Title = styled.h2`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 36px 0 10px 0;
`;

const ShowAllButton = styled(Button)`
    width: 100%;
`;

const LinkStyled = styled(Link)`
    margin: 0 16px;
    @media (min-width: ${BreakpointsEnum.sm}) {
        width: 345px;
        margin: 36px auto;
    }
`;

const RecommendationZones = (props: IZoneTabsProps) => {
    const { title, buttonTitle, data, className } = props;
    const [selectedItem, setSelectedItem] = React.useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        // just to scroll the slider to the start point between switching tabs
        // in case we scroll items and move to the next tab
        if (zonesWithItems.length > 0) {
            sliderRef?.current?.slickGoTo(0);
        }
    }, [selectedItem]);

    const { pageData } = useTypedSelector((state) => state);
    const { deviceType, discountThreshold } = pageData;
    const isMobile = deviceType !== 'desktop';

    const zonesWithItems = data?.filter((item) => item.items?.length > 0);

    const pills = zonesWithItems.map((item, index) => {
        const isSelected = index === selectedItem;
        return (
            <PillButton key={item.name} active={isSelected} onClick={() => setSelectedItem(index)}>
                {item.name}
            </PillButton>
        );
    });

    const getShowAllButtonText = () => {
        if (zonesWithItems[selectedItem]?.name) {
            return __('All %s', 'dreamlines', zonesWithItems[selectedItem].name);
        }
        return __('show all offers', 'dreamlines');
    };

    if (!zonesWithItems || zonesWithItems.length === 0) return null;

    const zoneCards = zonesWithItems[selectedItem].items.map((slide, index) => {
        const {
            nid,
            title,
            image,
            url,
            companyImage,
            description,
            price,
            discount,
            features,
            includesFlight,
            shipTitle,
        } = mapCruiseRecommendationData(slide);
        return (
            <Card
                key={`${title}${index}`}
                cruiseNid={nid}
                imageUrl={image}
                companyLogoUrl={companyImage}
                url={url}
                title={title}
                description={description}
                features={features}
                price={price}
                shipTitle={shipTitle}
                discount={getDiscountAboveThreshold(discount, discountThreshold)}
                withFlight={includesFlight}
            />
        );
    });

    return (
        <ZoneTabsContainer className={className}>
            {title && <Title>{title}</Title>}
            <PillButtonWrapper>{pills}</PillButtonWrapper>
            {isMobile ? (
                <CardSlider ref={sliderRef}>{zoneCards}</CardSlider>
            ) : (
                <CardsWrapper>{zoneCards}</CardsWrapper>
            )}

            {zonesWithItems[selectedItem].url && (
                <LinkStyled href={zonesWithItems[selectedItem].url} mask={true}>
                    <ShowAllButton color="primary">
                        {buttonTitle || getShowAllButtonText()}
                    </ShowAllButton>
                </LinkStyled>
            )}
        </ZoneTabsContainer>
    );
};

export default memo(RecommendationZones);
