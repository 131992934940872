import { format } from 'date-fns';
import { __ } from '../../helpers/TranslationService';
import { IZoneTabItem } from '../../interfaces/IZoneTabs';
import { getCurrencyString } from '../../components/PriceString';
import { getCruiseTitle } from '../../helpers/cruisePageHelpers';

const getImage = (zone: IZoneTabItem) => {
    const { shipImage, teaserImage, routeMapImage } = zone;

    const shipImageUrl = teaserImage || shipImage;
    const imageSrc = routeMapImage || shipImageUrl;

    return imageSrc;
};

const getNightsString = (nights: number) => {
    const nightsString = nights > 1 ? __('Nights', 'dreamlines') : __('Night', 'dreamlines');
    return `${nights} ${nightsString}`;
};

const getTitle = (zone: IZoneTabItem) => {
    const {
        isDreamlinesPackage,
        startHarbourTitle,
        companyTitle,
        companyNid,
        regions,
        title,
        shipTitle,
        mainRegion,
        isPreserveOriginalTitle,
    } = zone;

    return getCruiseTitle({
        isDreamlinesPackage: isDreamlinesPackage,
        companyNid: companyNid,
        shipTitle: shipTitle,
        cruiseTitle: title,
        companyTitle: companyTitle,
        destination: mainRegion?.title,
        startHarbour: startHarbourTitle,
        isPreserveOriginalTitle,
    });
};

const getDescription = (zone: IZoneTabItem) => {
    const { nights, regions } = zone;
    const earliestDepartureDate = new Date(parseInt(zone.earliestDeparture, 10) * 1000);

    const earliestDeparture = isNaN(earliestDepartureDate.getTime())
        ? ''
        : format(new Date(parseInt(zone.earliestDeparture, 10) * 1000), 'do MMM yy');
    const regionFormatted = regions.join(', ');

    return `${earliestDeparture}, ${getNightsString(nights)} - ${regionFormatted}`;
};

const getPrice = (zone: IZoneTabItem) => {
    const { lowestPriceDualCurrency, lowestPrice, dualCurrency, currency } = zone;
    const currentPrice = lowestPriceDualCurrency || lowestPrice;
    const currentCurrency = dualCurrency || currency;

    if (!currentPrice || currentPrice < 1) {
        return __('on request', 'dreamlines');
    }

    return getCurrencyString(currentPrice, currentCurrency);
};

export const mapCruiseRecommendationData = (zone: IZoneTabItem) => {
    const {
        featureBulletPoints,
        maxDiscount,
        cruisePageUrl,
        companyImage,
        cruiseNid,
        includesFlight,
        shipTitle,
    } = zone;
    const image = getImage(zone);
    const title = getTitle(zone);
    const description = getDescription(zone);
    const price = getPrice(zone);

    return {
        nid: cruiseNid,
        title: title,
        image: image,
        url: cruisePageUrl,
        companyImage: companyImage,
        description: description,
        price: price,
        discount: Number(maxDiscount) || 0,
        features: featureBulletPoints || [],
        includesFlight: includesFlight,
        shipTitle,
    };
};
