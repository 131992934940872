import * as React from 'react';
import { Icon } from '../../Icon';
import { IIcon } from '../../../interfaces/IIcon';

export function EuroIcon({ width, height, viewBox, style, className }: IIcon) {
    const newViewProps = '0 0 12 17' ?? viewBox;
    return (
        <Icon
            width={width}
            height={height}
            viewBox={newViewProps}
            style={style}
            className={className}
        >
            <path d="M11.5231 0.5H0.477294C0.21842 0.5 0.00854492 0.709875 0.00854492 0.968749V14.6505C0.00854492 14.7748 0.0579512 14.894 0.145826 14.9819L1.52657 16.3627C1.70964 16.5457 2.00642 16.5457 2.18951 16.3627L3.23879 15.3134L4.28801 16.3627C4.37591 16.4506 4.49513 16.5 4.61944 16.5C4.74375 16.5 4.863 16.4506 4.95091 16.3627L6.00016 15.3134L7.04944 16.3627C7.13734 16.4506 7.25659 16.5 7.38088 16.5C7.50519 16.5 7.62444 16.4506 7.71234 16.3627L8.76159 15.3134L9.81084 16.3627C9.9024 16.4542 10.0223 16.5 10.1423 16.5C10.2622 16.5 10.3822 16.4542 10.4737 16.3627L11.8545 14.9819C11.9424 14.894 11.9918 14.7748 11.9918 14.6505V0.968749C11.9919 0.709875 11.782 0.5 11.5231 0.5ZM5.53138 4.02096V3.47918C5.53138 3.22031 5.74128 3.01043 6.00013 3.01043C6.25903 3.01043 6.46888 3.22031 6.46888 3.47918V4.01459H7.00434C7.26319 4.01459 7.47309 4.22446 7.47309 4.48334C7.47309 4.74221 7.26319 4.95209 7.00434 4.95209H5.66547C5.37025 4.95209 5.13007 5.19227 5.13007 5.48749C5.13007 5.78274 5.37025 6.02296 5.66547 6.02296H6.33494C7.14709 6.02296 7.80787 6.68368 7.80787 7.49586C7.80787 8.26283 7.2185 8.89448 6.46888 8.96242V9.5042C6.46888 9.76308 6.25903 9.97295 6.00013 9.97295C5.74128 9.97295 5.53138 9.76308 5.53138 9.5042V8.96873H4.99597C4.73713 8.96873 4.52722 8.75886 4.52722 8.49998C4.52722 8.24111 4.73713 8.03124 4.99597 8.03124H6.33494C6.63016 8.03124 6.87038 7.79105 6.87038 7.49583C6.87038 7.20061 6.63019 6.96042 6.33494 6.96042H5.66547C4.85329 6.96042 4.19257 6.29964 4.19257 5.48746C4.1926 4.72052 4.78188 4.08893 5.53138 4.02096ZM8.51062 12.4833H3.48979C3.23091 12.4833 3.02104 12.2734 3.02104 12.0146C3.02104 11.7557 3.23091 11.5458 3.48979 11.5458H8.51062C8.76947 11.5458 8.97937 11.7557 8.97937 12.0146C8.97937 12.2734 8.76947 12.4833 8.51062 12.4833Z" />
        </Icon>
    );
}
