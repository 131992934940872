import { format } from 'date-fns';
import * as React from 'react';
import styled from '@emotion/styled';
import { PriceString } from '../../components/PriceString';
import { CabinTypeIdsMapper } from '../../components/SearchFilters/CabinFilter';
import * as Colors from '../../consts/variables';
import { parseAbsoluteUtcDate } from '../../helpers/date';
import { roundBy } from '../../helpers/priceHelpers';
import { __ } from '../../helpers/TranslationService';
import { ICruiseFilterParamValue } from '../../interfaces/ISearchApi';
import { ISearchReducer } from '../../reducers/search';
import { CrossIcon } from '../Icons/CrossIcon';
import { ResetButton } from './ResetButton';
import { ActiveFiltersLoader } from '../LoadingIndicator/ActiveFiltersLoader';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '0 0 15px',
        gap: 10,
        marginBottom: 16,
    },
    filterItem: {
        background: Colors.darkAcqa,
        color: Colors.white,
        marginTop: '0',
        padding: '10px',
        fontSize: '15px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    filterItemClose: {
        paddingLeft: '10px',
        fill: Colors.white,
    },
};

const shipSizes = {
    '1': 'small',
    '501': 'medium',
    '1501': 'large',
};

const defaultPriceMin = 0;

interface StateProps {
    search: ISearchReducer;
    currency: string;
    dualCurrency: string | null;
    exchangeRate: number;
}

interface DispatchProps {
    removeFilter(any): void;
    clearFilterParams?: any;
}

const facetsMap = {
    company: {
        type: 'facetLookup',
        facetName: 'companyNids',
        valueField: 'short_title',
    },
    zone: {
        type: 'facetLookup',
        facetName: 'zoneNids',
        valueField: 'title',
    },
    ship: {
        type: 'facetLookup',
        facetName: 'shipNids',
        valueField: 'title',
    },
    startHarbourCountry: {
        type: 'callback',
        facetName: 'startHarbourCountriesCombined',
        callback: (_startHarbour, _props, { title }) => {
            return `${__('from', 'dreamlines')}: ${title}`;
        },
    },
    endHarbourCountry: {
        type: 'callback',
        facetName: 'endHarbourCountriesCombined',
        callback: (_endHarbour, _props, { title }) => {
            return `${__('unitl', 'dreamlines')}: ${title}`;
        },
    },
    country: {
        type: 'callback',
        facetName: 'harbourCountriesCombined',
        callback: (_harbour, _props, { title }) => {
            return `${__('including', 'dreamlines')}: ${title}`;
        },
    },
    startHarbour: {
        type: 'callback',
        facetName: 'startHarbourCountriesCombined',
        callback: (_startHarbour, _props, { title }) => {
            return `${__('from', 'dreamlines')}: ${title}`;
        },
    },
    endHarbour: {
        type: 'callback',
        facetName: 'endHarbourCountriesCombined',
        callback: (_endHarbour, _props, { title }) => {
            return `${__('unitl', 'dreamlines')}: ${title}`;
        },
    },
    harbour: {
        type: 'callback',
        facetName: 'harbourCountriesCombined',
        callback: (_harbour, _props, { title }) => {
            return `${__('including', 'dreamlines')}: ${title}`;
        },
    },
    banderoles: {
        type: 'facetLookup',
        facetName: 'banderoles',
        valueField: 'title',
    },
    discount: {
        type: 'callback',
        callback: () => __('Only reduced offers', 'dreamlines'),
    },
    areaType: {
        type: 'callback',
        callback: () => __('travel area', 'dreamlines'),
    },
    inclFlight: {
        type: 'callback',
        callback: () => __('Only deals with flights', 'dreamlines'),
    },
    cabinKind: {
        type: 'callback',
        callback: (cabinTypeId) => {
            const IdsCabinTypeMapper = {};

            Object.entries(CabinTypeIdsMapper).forEach(([key, value]) => {
                IdsCabinTypeMapper[value] = key;
            });

            return __(IdsCabinTypeMapper[cabinTypeId], 'dreamlines');
        },
    },
    hasTvOffer: {
        type: 'callback',
        callback: () => __('onlyTvOfferDeals', 'dreamlines'),
    },
    nightsDuration: {
        type: 'callback',
        callback: (nights) => `${nights} ${__('nights', 'dreamlines')}`,
    },
    nightsMinOnly: {
        type: 'callback',
        callback: (nights) => {
            const nightsStringTranslated =
                nights > 1 ? __('Nights', 'dreamlines') : __('Night', 'dreamlines');
            return `${__('from', 'dreamlines')}: ${nights} ${nightsStringTranslated}`;
        },
    },
    nightsMaxOnly: {
        type: 'callback',
        callback: (nights) => {
            const nightsStringTranslated =
                nights > 1 ? __('Nights', 'dreamlines') : __('Night', 'dreamlines');
            return `${__('unitl', 'dreamlines')}: ${nights} ${nightsStringTranslated}`;
        },
    },
    priceMin: {
        type: 'callback',
        callback: (priceMin, props) => {
            if (props.dualCurrency && props.exchangeRate) {
                return (
                    <PriceString
                        price={roundBy(priceMin * props.exchangeRate, 0)}
                        currency={props.dualCurrency}
                        prefix={__('min.', 'dreamlines')}
                    />
                );
            }
            return (
                <PriceString
                    price={priceMin}
                    currency={props.currency}
                    prefix={__('min.', 'dreamlines')}
                />
            );
        },
    },
    priceMax: {
        type: 'callback',
        callback: (priceMax, props) => {
            if (props.dualCurrency && props.exchangeRate) {
                return (
                    <PriceString
                        price={roundBy(priceMax * props.exchangeRate, 0)}
                        currency={props.dualCurrency}
                        prefix={__('max.', 'dreamlines')}
                    />
                );
            }
            return (
                <PriceString
                    price={priceMax}
                    currency={props.currency}
                    prefix={__('max.', 'dreamlines')}
                />
            );
        },
    },
    shipCategory: {
        type: 'callback',
        callback: (stars) => {
            return `${stars} ${__('Stars', 'dreamlines')}`;
        },
    },
    shipSizeMin: {
        type: 'callback',
        callback: (shipSizeMin) => {
            return __(shipSizes[shipSizeMin.toString()], 'dreamlines');
        },
    },
    dateMin: {
        type: 'callback',
        callback: (dateMinTimestamp) => {
            return `${__('from', 'dreamlines')}: ${format(
                parseAbsoluteUtcDate(dateMinTimestamp * 1000),
                __('DD.MM.YYYY', 'dreamlines').toLowerCase().replace('mm', 'MM'),
            )}`;
        },
    },
    dateMax: {
        type: 'callback',
        callback: (dateMaxTimestamp) => {
            return `${__('unitl', 'dreamlines')}: ${format(
                parseAbsoluteUtcDate(dateMaxTimestamp * 1000),
                __('DD.MM.YYYY', 'dreamlines').toLowerCase().replace('mm', 'MM'),
            )}`;
        },
    },
};

export const calculateOnlyStartEndSelected = (sortedFilters) => {
    const selectedParamNames = sortedFilters.map((item) => item.paramName);

    const maxNightsOnly =
        selectedParamNames.includes('nightsMax') &&
        !selectedParamNames.includes('nightsMin') &&
        !selectedParamNames.includes('nightsMaxOnly');

    const minNightsOnly =
        !selectedParamNames.includes('nightsMax') &&
        selectedParamNames.includes('nightsMin') &&
        !selectedParamNames.includes('nightsMinOnly');

    if (maxNightsOnly) {
        sortedFilters.push({
            paramName: 'nightsMaxOnly',
            paramValue: sortedFilters.find((item) => {
                return item.paramName === 'nightsMax';
            })?.paramValue,
        });
    } else if (minNightsOnly) {
        sortedFilters.push({
            paramName: 'nightsMinOnly',
            paramValue: sortedFilters.find((item) => {
                return item.paramName === 'nightsMin';
            })?.paramValue,
        });
    }

    const onlyMinOrMaxSelected = sortedFilters.find((item) => {
        return item.paramName === 'nightsMinOnly' || item.paramName === 'nightsMaxOnly';
    });

    if (onlyMinOrMaxSelected) {
        sortedFilters = sortedFilters.filter((item) => {
            return item.paramName !== 'nightsMax' && item.paramName !== 'nightsMin';
        });
    }
    return sortedFilters;
};

export const calculateNightsDuration = (sortedFilters) => {
    const selectedParamNames = sortedFilters.map((item) => item.paramName);
    const isStartEndDatesSet =
        selectedParamNames.includes('nightsMax') &&
        selectedParamNames.includes('nightsMin') &&
        !selectedParamNames.includes('nightsDuration');

    if (isStartEndDatesSet) {
        const maxValue = sortedFilters.find((item) => {
            return item.paramName === 'nightsMax';
        })?.paramValue;

        const minValue = sortedFilters.find((item) => {
            return item.paramName === 'nightsMin';
        })?.paramValue;

        sortedFilters.push({
            paramName: 'nightsDuration',
            paramValue: `${minValue}-${maxValue}`,
        });
    }

    const isDurationSelected = sortedFilters.find((item) => {
        return item.paramName === 'nightsDuration';
    });

    if (isDurationSelected) {
        sortedFilters = sortedFilters.filter((item) => {
            return item.paramName !== 'nightsMax' && item.paramName !== 'nightsMin';
        });
    }
    return sortedFilters;
};

type IProps = StateProps & DispatchProps;

const FilterItemStyled = styled.div`
    background: ${({ theme }) => theme.background.brandColor2};
    color: ${({ theme }) => theme.colors.white[100]};
    padding: 10px;
    font-size: 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 7px;
    cursor: pointer;
    span {
        display: flex;
    }
`;
export class ActiveFilters extends React.Component<IProps, any> {
    removeFilter(filterParam: ICruiseFilterParamValue) {
        switch (filterParam.paramName) {
            case 'shipSizeMin':
                this.props.removeFilter({ paramName: 'shipSizeMin', paramValue: null });
                this.props.removeFilter({ paramName: 'shipSizeMax', paramValue: null });
                break;
            case 'nightsMaxOnly':
            case 'nightsMinOnly':
            case 'nightsDuration':
                this.props.removeFilter({ paramName: 'nightsMin', paramValue: null });
                this.props.removeFilter({ paramName: 'nightsMax', paramValue: null });
                break;
            case 'cabinKind':
                this.props.removeFilter({
                    paramName: 'cabinKind',
                    paramValue: filterParam.paramValue,
                });
                break;
            default:
                this.props.removeFilter(filterParam);
        }
    }

    mapFilterParamToFacet(filterParam: ICruiseFilterParamValue) {
        const conf = facetsMap[filterParam.paramName];
        if (conf) {
            if (conf.type === 'facetLookup') {
                const selectedFacet = this.props.search.searchFacets[conf.facetName].filter(
                    (facet) => {
                        return facet?.id.toString() === filterParam.paramValue.toString();
                    },
                );
                return selectedFacet[0] ? selectedFacet[0][conf.valueField] : filterParam.paramName;
            } else if (conf.type === 'callback') {
                let title;

                if (conf.facetName) {
                    const facetsAll = this.props?.search?.searchFacetsAll?.[conf.facetName];
                    const facets = this.props?.search?.searchFacets?.[conf.facetName];
                    const facetsCombined = facets?.length > 0 ? facets : facetsAll;

                    const selectedFacet = facetsCombined?.find((facet) => {
                        return facet?.id.toString() === filterParam.paramValue.toString();
                    });

                    title = selectedFacet ? selectedFacet?.['title'] : filterParam.paramName;
                }

                return conf.callback(filterParam.paramValue, this.props, { title });
            }
        } else {
            return filterParam.paramName;
        }
    }

    getFilters() {
        //sort filter params to make sure that similar params like dateMin/dateMax
        // or priceMin/priceMax are displayed next to each other
        let activeFilters = this.props.search.filterParamsListActive;
        let extendedFilters = calculateOnlyStartEndSelected(activeFilters);
        extendedFilters = calculateNightsDuration(extendedFilters);

        return extendedFilters
            .filter((param) => {
                return (
                    param.paramName !== 'shipType' &&
                    param.paramName !== 'shipSizeMax' &&
                    param.paramName !== 'cruiseNids' &&
                    param.paramValue
                );
            })
            .sort((filterParamA, filterParamB) => {
                return filterParamA.paramName < filterParamB.paramName ? 1 : -1;
            });
    }

    render() {
        if (!this?.getFilters()?.length) {
            return null;
        }
        return (
            <div style={style.wrapper} className="activeFilterWrapper">
                {this.props.search.isSearchResultsLoading && <ActiveFiltersLoader />}

                {!this.props.search.isSearchResultsLoading && (
                    <>
                        {this.getFilters().map((filterParam) => {
                            const isPriceMinFilter = filterParam.paramName === 'priceMin';

                            if (
                                isPriceMinFilter &&
                                parseInt(filterParam.paramValue, 10) === defaultPriceMin
                            ) {
                                return null;
                            }

                            const filterName = this.mapFilterParamToFacet(filterParam);
                            const showFilter = filterName !== filterParam.paramName;
                            return showFilter ? (
                                <FilterItemStyled
                                    key={filterParam.paramName + filterParam.paramValue}
                                >
                                    <span>{filterName}</span>
                                    <span
                                        style={style.filterItemClose}
                                        onClick={() => {
                                            this.removeFilter(filterParam);
                                        }}
                                    >
                                        <CrossIcon width={16} height={16} />
                                    </span>
                                </FilterItemStyled>
                            ) : null;
                        })}
                        <ResetButton onClick={this.props.clearFilterParams} />
                    </>
                )}
            </div>
        );
    }
}
