import * as React from 'react';
import { ContactButtons } from '../../../components/ContactButtons';
import { CTA } from '../../../components/CTA';
import { config } from '../../../config';
import * as vars from '../../../consts/variables';
import { __ } from '../../../helpers/TranslationService';
import { ISearchReducer } from '../../../reducers/search';
import { SearchResultList } from './SearchResultList';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        textAlign: 'center',
    },
    LightHouseStyle: {
        display: 'inline-block',
        position: 'relative',
        margin: '15px 0px 10px',
        padding: '33px 24px 0px',
    },
    imgStyle: {
        position: 'relative',
        width: 72,
        display: 'inline-block',
    },
    circle: {
        position: 'absolute',
        borderRadius: '50%',
        left: 0,
        right: 0,
        top: 0,
        paddingBottom: '100%',
        backgroundColor: 'rgba(7, 73, 111, 0.1)',
    },
    text: {
        color: vars.darkAcqa,
        textAlign: 'center',
        fontSize: 18,
        padding: '0 15px',
        lineHeight: 1.3,
        fontWeight: 'bold',
    },
    hint: {
        fontSize: 16,
        textAlign: 'center',
        marginTop: 20,
    },
    CTAWrapper: {
        margin: '0 40px',
    },
    CTAbutton: {
        margin: '15px auto',
        fontSize: 19,
        maxWidth: 400,
    },
    horiztalSeperator: {
        borderTop: `solid 1px ${vars.darkGray}`,
        margin: '20px 25px',
    },
    contactCaption: {
        fontSize: 16,
        margin: '0 25px 5px',
        textAlign: 'center',
        lineHeight: 1.3,
        fontWeight: 'bold',
    },
    cruiseSuggestions: {
        background: vars.lightGray,
        paddingBottom: 20,
    },
    cruiseSuggestionsHeadline: {
        margin: 0,
        padding: '20px 15px 5px',
        textAlign: 'center',
        fontSize: 18,
    },
};

interface StateProps {
    isLoading: boolean;
    search: ISearchReducer;
}

interface DispatchProps {
    clearFilterParams: any;
    toggleSearchFilter: any;
}

type Props = StateProps & DispatchProps;

export class NoResult extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.reset = this.reset.bind(this);
    }

    reset() {
        this.props.clearFilterParams(true, true);
        this.props.toggleSearchFilter();
    }

    render() {
        return (
            <div>
                <div style={style.wrapper}>
                    <div style={style.LightHouseStyle}>
                        <div style={style.circle} />
                        <img
                            style={style.imgStyle}
                            src={`${config.images}/common/lighthouse.svg`}
                        />
                    </div>
                </div>
                <div style={style.text} data-test-id="no-result-error-message">
                    <span>
                        {__('We were unable to find cruises matching you filters', 'dreamlines')}
                    </span>
                </div>
                <div style={style.hint}>{__('less filters = more results', 'dreamlines')}</div>
                <div style={style.CTAWrapper}>
                    <CTA onClick={this.reset} wrapperStyle={style.CTAbutton}>
                        <span>{__('Reset filters', 'dreamlines')}</span>
                    </CTA>
                </div>
                <div style={style.horiztalSeperator} />
                <div style={style.contactCaption}>
                    {__("Or get in touch with us directly. We're glad to help", 'dreamlines')}
                </div>
                <ContactButtons />
                <div style={style.cruiseSuggestions}>
                    <h3 style={style.cruiseSuggestionsHeadline}>
                        {__('Our top offers', 'dreamlines')}
                    </h3>
                    <SearchResultList displayTopResults={true} />
                </div>
            </div>
        );
    }
}
