import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function ConferenceIcon({
    width,
    height,
    viewBox,
    style,
    className,
}: IIcon) {
    const newViewProps = '0 0 356.484 356.484' ?? viewBox;
    return (
       
        <Icon width={width} height={height} viewBox={newViewProps} style={style} className={className}>
            <g>
                <path d="M83.199,388.629l0.136-0.312c2.311-5.006,6.526-8.812,12.534-11.33l19.052-8.773l1.01-0.85c-0.726-0.834-1.435-1.691-2.121-2.578c-8.613-11.189-13.345-26.055-13.743-43.025l-2.639-1.205l-9.821-8.293l-15.781,15.702h-0.011l-1.813-0.001h-0.011l-15.78-15.701l-9.823,8.293l-20.442,9.409c-3.025,1.245-5.997,3.128-7.51,6.401c0,0-22.22,52.902-10.167,52.902h76.663C83.082,388.914,83.174,388.693,83.199,388.629z" />
                <path d="M34.043,264.328c0.354,32.673,18.615,52.143,36.866,52.143c15.593,0,36.51-19.47,36.867-52.143c0.226-22.666-10.57-36.238-36.867-36.238C44.611,228.089,33.813,241.662,34.043,264.328z" />
                <path d="M197.729,376.986c6.184,2.588,10.563,6.609,12.675,11.664c0.023,0.051,0.11,0.266,0.261,0.617h24.042c0.15-0.354,0.243-0.574,0.269-0.639l0.141-0.312c2.306-5.006,6.523-8.812,12.526-11.33l19.057-8.773l1.01-0.85c-0.729-0.834-1.438-1.691-2.124-2.578c-8.612-11.189-13.345-26.055-13.737-43.025l-2.638-1.205l-9.821-8.293l-15.787,15.702h-0.006l-1.818-0.001h-0.006l-15.785-15.701l-9.821,8.293l-2.642,1.207c-0.422,16.494-5.673,31.793-14.87,43.205c-0.522,0.645-1.053,1.266-1.594,1.885l1.618,1.361L197.729,376.986z" />
                <path d="M222.685,316.471c15.592,0,36.514-19.471,36.866-52.144c0.229-22.666-10.57-36.238-36.866-36.238c-26.297,0-37.092,13.572-36.864,36.238C186.172,297,204.435,316.471,222.685,316.471z" />
                <path d="M428.936,336.365c-1.318-3.139-4.485-5.156-7.51-6.401l-20.443-9.409l-9.822-8.293l-15.779,15.702h-0.013l-1.812-0.001h-0.012l-15.779-15.701l-9.822,8.293l-2.641,1.207c-0.425,16.494-5.676,31.793-14.878,43.205c-0.52,0.645-1.052,1.266-1.592,1.885l1.618,1.361l19.054,8.773c6.185,2.588,10.564,6.609,12.675,11.664c0.022,0.051,0.114,0.266,0.261,0.617h76.664C451.155,389.268,428.936,336.365,428.936,336.365z" />
                <path d="M337.596,264.328c0.354,32.673,18.616,52.143,36.866,52.143c15.593,0,36.512-19.47,36.865-52.143c0.229-22.666-10.569-36.238-36.865-36.238C348.163,228.089,337.364,241.662,337.596,264.328z" />
                <path d="M193.759,386.061l-20.44-9.416l-9.823-8.275l-15.78,15.691h-0.013h-1.813h-0.01l-15.781-15.691l-9.821,8.275l-20.445,9.416c-3.024,1.236-5.997,3.117-7.508,6.406c0,0-22.221,52.904-10.168,52.904h129.282c12.052,0-10.167-52.904-10.167-52.904C199.953,389.312,196.787,387.297,193.759,386.061z" />
                <path d="M146.795,284.193c-26.297,0-37.093,13.562-36.865,36.23c0.356,32.663,18.618,52.15,36.865,52.15c15.594,0,36.515-19.487,36.866-52.15C183.894,297.754,173.092,284.193,146.795,284.193z" />
                <path d="M345.538,386.061l-20.445-9.416l-9.82-8.275l-15.782,15.691h-0.009h-1.815h-0.01l-15.779-15.691l-9.824,8.275l-20.439,9.416c-3.026,1.236-5.996,3.117-7.514,6.406c0,0-22.218,52.904-10.166,52.904h129.282c12.053,0-10.169-52.904-10.169-52.904C351.729,389.312,348.561,387.297,345.538,386.061z" />
                <path d="M298.575,372.576c15.592,0,36.51-19.488,36.866-52.15c0.226-22.671-10.568-36.23-36.866-36.23c-26.297,0-37.099,13.561-36.864,36.23C262.061,353.087,280.321,372.576,298.575,372.576z" />
                <path d="M209.917,140.722c0.531,0.263,1.104,0.391,1.675,0.391c0.814,0,1.625-0.262,2.296-0.775l38.948-29.77c0.937-0.715,1.486-1.826,1.486-3.004c0-1.179-0.55-2.289-1.486-3.006L213.888,74.79c-1.142-0.873-2.682-1.023-3.972-0.385c-1.29,0.637-2.106,1.951-2.106,3.39v59.536C207.811,138.77,208.626,140.083,209.917,140.722z" />
                <path d="M23.173,215.128h399.023c2.911,0,5.271-2.36,5.271-5.271V5.271c0-2.911-2.36-5.271-5.271-5.271H23.173c-2.911,0-5.271,2.36-5.271,5.271v204.586C17.902,212.768,20.263,215.128,23.173,215.128z M222.686,46.254c33.86,0,61.31,27.449,61.31,61.31c0,33.861-27.449,61.309-61.31,61.309c-33.86,0-61.31-27.448-61.31-61.309C161.375,73.703,188.826,46.254,222.686,46.254z" />
            </g>
        </Icon>
    );
}
