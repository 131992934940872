import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import { IReviewsRating } from '../../interfaces/IReviews';

import { Review } from './Review';
import { LoadingIndicator } from '../LoadingIndicator';
import { LoadMoreReviewsBtn } from './LoadMoreReviewsBtn';
import { DropDown } from '../DropDown';
import { IReviewType } from '../Reviews/Reviews';

interface HomeProps extends IReviewsRating {
    sortString: string;
    isLoading: boolean;
    setSortString: Function;
    filterParams: string;
    reviewsType: IReviewType;
    loadMoreReviews: Function;
}

export function ReviewsList({
    data: reviews,
    meta,
    sortString,
    isLoading,
    setSortString,
    filterParams,
    reviewsType,
    links,
    loadMoreReviews,
}: HomeProps) {
    const getReviews = () => reviews.map((review, i) => <Review key={i} review={review} />);
    const sortOptions = [
        { id: 'date_desc', label: __('by date descending', 'dreamlines') },
        { id: 'date', label: __('by date ascending', 'dreamlines') },
        { id: 'rating_desc', label: __('by rating descending', 'dreamlines') },
        { id: 'rating', label: __('by rating ascending', 'dreamlines') },
    ];
    const onSortChange = (option: string) => {
        setSortString(option, filterParams, reviewsType);
    };
    const selectedFaced = sortOptions.filter((item) => item.id === sortString)[0];

    return (
        <div className="reviews-list">
            <div className="reviews-list__heading-row">
                <div className="reviews-list__heading">
                    {`${meta.total} ${__('Reviews', 'dreamlines')}`}
                </div>
                <div className="reviews-list__sorting">
                    <div className="reviews-list__sorting-header">
                        {__('Sorted by:', 'dreamlines')}
                    </div>
                    <div className="reviews-list__sorting-wrapper">
                        <DropDown options={sortOptions} onChange={onSortChange} />
                    </div>
                </div>
            </div>
            {isLoading && <LoadingIndicator />}
            {getReviews()}
            <LoadMoreReviewsBtn isLoading={false} links={links} loadMoreReviews={loadMoreReviews} />
        </div>
    );
}
