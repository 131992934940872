import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function FilterIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <g>
                <rect x="404.08" y="237.94" width="79.15" height="52.77" />
                <rect x="277.45" y="106.02" width="205.79" height="52.77" />
                <rect x="227.32" y="369.85" width="255.91" height="52.77" />
                <path d="M179.83,185.17A55.4,55.4,0,1,0,130,106H40v52.77h92.82A55.21,55.21,0,0,0,179.83,185.17Z" />
                <path d="M129.7,338.19a55.26,55.26,0,0,0-49.87,31.66H40v52.77H82.7a55.34,55.34,0,1,0,47-84.43Z" />
                <path d="M42.64,290.7H251.55a55.4,55.4,0,1,0-2.86-52.77h-206Z" />
            </g>
        </Icon>
    );
}
