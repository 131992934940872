import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function SortIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <g id="sort">
                <path d="M491,297.84v62.05l-98.78,112h-19.1l-98.56-112V298l82.21,86.29V157h52.38V383.62Z" />
                <path d="M32.37,247.11V185.05l98.78-112h19.1l98.56,112V247L166.59,160.7V388H114.21V161.32Z" />
            </g>
        </Icon>
    );
}
