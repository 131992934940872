import * as React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import * as vars from '../../consts/variables';
import { getRelevanceSorting } from '../../helpers/getSortingBasedOnMarket';
import { TestSelector } from '../../helpers/TestSelector';
import { __ } from '../../helpers/TranslationService';
import { ICountryCode } from '../../interfaces';
import { FilterIcon } from '../Icons/FilterIcon';
import { SortIcon } from '../Icons/SortIcon';
import { css } from '@emotion/react';

const style: Record<string, React.CSSProperties> = {
    ref: {
        height: 40,
    },
    wrapper: {
        padding: '5px 0',
        position: 'relative',
        background: vars.darkAcqa,
        display: 'flex',
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
        transition: 'all 0.15s ease-in-out',
    },
    item: {
        fontSize: 14,
        color: vars.white,
        padding: '6px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        flex: '1 1 50%',
    },
    firstItem: {
        borderRight: `1px solid ${vars.white}`,
        minWidth: '40%',
    },
    secondItem: {
        padding: 0,
    },
    icon: {
        margin: '0 5px',
        fill: vars.white,
        flex: '0 0 auto',
    },
    select: {
        fontSize: 14,
        opacity: 1,
        height: '100%',
        backgroundColor: vars.transparent,
        border: 'none',
        color: vars.white,
    },
};

interface IProps {
    toggleSearchFilter: any;
    setSortOrder: (string) => any;
    sortOrder: string;
    portalId: string;
}

interface IState {
    isSticky: boolean;
}

const StickyBarFixed = css`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
`;

const StickyBarStyled = styled.div<{ isSticky: boolean }>`
    padding: 5px 0;
    position: relative;
    background: ${({ theme }) => theme.background.brandColor2};
    display: flex;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.15s ease-in-out;
    ${({ isSticky }) => (isSticky ? StickyBarFixed : null)}
`;
class FilterAndSortBarClass extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
        };
        this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
    }

    filterBarRef = null;
    getFilterBarRef = (ref) => (this.filterBarRef = ref);

    stickyBar = () => {
        this.setState({
            isSticky: this.filterBarRef.getBoundingClientRect().top <= 0,
        });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.stickyBar);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.stickyBar);
    }

    handleSortOrderChange(event) {
        const target = event.target;
        this.props.setSortOrder(target.value);
    }

    replaceRelevanceSortString(): string {
        return getRelevanceSorting({ market: this.props.portalId as ICountryCode });
    }

    render() {
        const relevanceSortString: string = this.replaceRelevanceSortString();

        return (
            <div ref={this.getFilterBarRef} style={style.ref} className="hideDesktop">
                <StickyBarStyled isSticky={this.state.isSticky}>
                    <div
                        style={{ ...style.item, ...style.firstItem }}
                        onClick={this.props.toggleSearchFilter}
                        {...TestSelector.getSelector('searchFilter')}
                    >
                        <FilterIcon width={18} height={18} style={style.icon} />
                        <span>{__('filter', 'dreamlines')}</span>
                    </div>

                    <div style={{ ...style.item, ...style.secondItem }}>
                        <SortIcon width={18} height={18} style={style.icon} />
                        <select
                            style={{ ...style.select }}
                            onChange={(e) => this.handleSortOrderChange(e)}
                            value={this.props.sortOrder}
                        >
                            <option value={relevanceSortString}>
                                {__('Relevance', 'dreamlines')}
                            </option>
                            <option value="departure">
                                {__('Departure (sort by)', 'dreamlines')}
                            </option>
                            <option value="price">
                                {__('Price (cheapest first)', 'dreamlines')}
                            </option>
                            <option value="price_desc">
                                {__('Price (highest first)', 'dreamlines')}
                            </option>
                            <option value="discount">
                                {__('Discount (highest first)', 'dreamlines')}
                            </option>
                            <option value="duration_desc">
                                {__('Duration (longest first)', 'dreamlines')}
                            </option>
                            <option value="duration_asc">
                                {__('Duration (shortest first)', 'dreamlines')}
                            </option>
                        </select>
                    </div>
                </StickyBarStyled>
            </div>
        );
    }
}

// @TODO Fix state type inconsistency
const mapStateToProps = (state: IProps) => ({
    portalId: (state as any).pageData.appConfig.countryCode,
});

// @TODO Fix state type inconsistency
export const FilterAndSortBar = connect<any, any, any>(mapStateToProps, {})(FilterAndSortBarClass);
