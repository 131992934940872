import React from 'react';
import styled from '@emotion/styled';
import { __ } from '../../helpers/TranslationService';
import { BreakpointsEnum } from '../../helpers/breakpoints';

const ResetButtonStyled = styled.div<{ variant: 'light' | 'dark' }>`
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme, variant }) =>
        variant === 'light' ? theme.colors.white[100] : theme.link.primary};

    display: none;
    cursor: pointer;
    &::before {
        content: '\\f167';
        font-family: 'dreamlines';
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;

        margin-right: 5px;
    }

    @media only screen and (min-width: ${BreakpointsEnum.sm}) {
        display: block;
    }
`;

interface IProps {
    variant?: 'light' | 'dark';
    onClick: (...args: any[]) => void;
}

export const ResetButton = ({ variant, onClick }: IProps) => {
    return (
        <ResetButtonStyled variant={variant} onClick={() => onClick()}>
            {__('Reset filters', 'dreamlines')}
        </ResetButtonStyled>
    );
};
