import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function ShipSizeMIcon({ width, height, viewBox = '0 0 512 256', style, className }: IIcon) {
    return (
        <Icon width={width} height={height} viewBox={viewBox} style={style} className={className}>
            <path
                id="shipsizeM"
                d="M439.1,154.1c-1-1.8-2.8-2.9-4.9-2.9h-53.3l-28.6-43.7l0,0l-5.7-8.8c-8-12.2-21.6-19.6-36.2-19.6H306l-17.3-34.7c-1.3-2.8-4.6-4-7.4-2.7c-2.8,1.3-4,4.6-2.7,7.4c0,0.1,0.1,0.2,0.1,0.3L293.6,79h-38.2c-1.5,0-2.9,0.6-3.9,1.6l-7.8,7.8h-42.6l-7.8-7.8c-1-1-2.4-1.6-3.9-1.6H175l-8.4-33.5c-0.6-2.5-2.8-4.2-5.4-4.2h-28.3c-3,0-5.5,2.5-5.5,5.5V79h-10.8c-6.1,0-11.3,4.3-12.6,10.3l-9.3,44.4v0.1l-4.1,19.5c-0.4,2.1-0.5,4.2-0.3,6.3l6.9,67.1c0.3,2.8,2.7,4.9,5.5,4.9h5.9v3.9c0,3,2.5,5.5,5.5,5.5h273.2c3,0,5.5-2.5,5.5-5.5c0-5,1.5-10,4.3-14.1l41.6-61.5C439.9,158,440,155.9,439.1,154.1z M388.1,215.1c-3,4.4-5,9.5-5.8,14.8H119.6V226c0-3-2.5-5.5-5.5-5.5h-6.5l-3.1-30h282.8c3,0.3,5.7-2,6-5c0.3-3-2-5.7-5-6c-0.3,0-0.6,0-0.9,0H103.4l-1.8-17.2h215.1c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5H102.3l2.3-10.8H180c3,0.3,5.7-2,6-5c0.3-3-2-5.7-5-6c-0.3,0-0.6,0-0.9,0h-73.1l2.8-13.2H180c3,0.3,5.7-2,6-5s-2-5.7-5-6c-0.3,0-0.6,0-0.9,0h-68l2.8-13.4c0.2-0.8,0.9-1.4,1.8-1.5h35.2c3,0.3,5.7-2,6-5s-2-5.7-5-6c-0.3,0-0.6,0-0.9,0h-13.3V52.5h18.4l8.4,33.5v0c0.1,0.3,0.2,0.6,0.3,0.8c0,0,0,0.1,0.1,0.1h0c0.1,0.2,0.3,0.5,0.4,0.7c0,0,0,0.1,0.1,0.1h0c0.1,0.2,0.3,0.4,0.5,0.6l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0,0.1,0.1,0.1,0.1c0.4,0.3,0.9,0.6,1.4,0.8c0.1,0,0.1,0,0.1,0.1l0.7,0.2h0.1c0.2,0.1,0.5,0.1,0.7,0.1h16.6l7.8,7.8c1,1,2.4,1.6,3.9,1.6h47.1c1.5,0,2.9-0.6,3.9-1.6l7.8-7.8h52.6c10.9,0,21,5.5,27,14.6l0.2,0.3H312c-3,0.3-5.3,2.9-5,6c0.2,2.7,2.4,4.8,5,5h32.8l8.7,13.3H312c-3,0.3-5.3,2.9-5,6c0.2,2.7,2.4,4.8,5,5h48.7l7,10.8h-27.4c-3-0.3-5.7,2-6,5c-0.3,3,2,5.7,5,6c0.3,0,0.6,0,0.9,0h83.6L388.1,215.1zM289.2,110.6c0,3-2.5,5.5-5.5,5.5h-75.4c-3,0.3-5.7-2-6-5s2-5.7,5-6c0.3,0,0.6,0,0.9,0h75.4C286.7,105.1,289.2,107.5,289.2,110.6zM289.2,134.9c0,3-2.5,5.5-5.5,5.5h-75.4c-3,0.3-5.7-2-6-5c-0.3-3,2-5.7,5-6c0.3,0,0.6,0,0.9,0h75.4C286.7,129.4,289.2,131.9,289.2,134.9z"
            />
        </Icon>
    );
}
