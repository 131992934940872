import styled from '@emotion/styled';

export const CardsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: stretch;
    align-content: center;
    > div {
        width: calc(33.3% - 16px);
        height: auto;
    }
`;
