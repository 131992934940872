import * as React from 'react';
import * as Colors from '../../consts/variables';
import styled from '@emotion/styled';
import { __ } from '../../helpers/TranslationService';
import { ISearchReducer } from '../../reducers/search';
import { ShipSizeSIcon } from '../Icons/ShipSizeSIcon';
import { ShipSizeMIcon } from '../Icons/ShipSizeMIcon';
import { ShipSizeLIcon } from '../Icons/ShipSizeLIcon';

const shipSize: any = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        gap: '15px',
    },
    item: {
        borderRadius: '5px',
        border: `1px solid ${Colors.darkGray}`,
        fill: Colors.darkAcqa,
        alignItems: 'center',
        width: 100,
        padding: '0 0 10px',
        marginRight: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: 15,
        transition: 'all 0.15s ease-in-out',
    },
    selectedItemStyle: {
        border: `1px solid ${Colors.darkAcqa}`,
        color: Colors.white,
        background: Colors.darkAcqa,
        fill: Colors.white,
    },
    iconStyle: {
        margin: '10px 0px 5px',
        padding: '0 5px',
    },
};

interface StateProps {
    search: ISearchReducer;
}

interface DispatchProps {}

interface OwnProps {
    shipSizeMin: number;
    shipSizeMax: number;
    onShipSizeChange(param: any[]): void;
}

type IProps = StateProps & DispatchProps & OwnProps;

const shipSizeMap = {
    small: {
        min: 1,
        max: 500,
    },
    medium: {
        min: 501,
        max: 1500,
    },
    large: {
        min: 1501,
        max: 9999,
    },
};

const ShipSizeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 15px;
`;

const ShipSizeButton = styled.div`
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.sanctuary[100]};
    fill: ${({ theme }) => theme.background.brandColor2};
    align-items: center;
    width: 100px;
    padding: 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 15px;
    transition: all 0.15s ease-in-out;
    &.active {
        border: 1px solid ${({ theme }) => theme.background.brandColor2};
        color: ${({ theme }) => theme.colors.white[100]};
        background: ${({ theme }) => theme.background.brandColor2};
        fill: ${({ theme }) => theme.colors.white[100]};
    }
`;

export class ShipSizes extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.handleShipSizeChange = this.handleShipSizeChange.bind(this);
    }

    handleShipSizeChange = (event, size) => {
        if (this.props.shipSizeMin === shipSizeMap[size].min) {
            this.props.onShipSizeChange([0, 0]);
        } else {
            this.props.onShipSizeChange([shipSizeMap[size].min, shipSizeMap[size].max]);
        }
    };

    getItems = () => {
        const shipSizes = [
            {
                comp: ShipSizeSIcon,
                label: 'small',
            },
            {
                comp: ShipSizeMIcon,
                label: 'medium',
            },
            {
                comp: ShipSizeLIcon,
                label: 'large',
            },
        ];
        return shipSizes.map((ship, key, arr) => {
            const Comp = ship.comp;
            const isButtonActive = this.props.shipSizeMin === shipSizeMap[ship.label].min;
            return (
                <ShipSizeButton
                    key={key}
                    className={isButtonActive ? 'active' : ''}
                    onClick={(e) => this.handleShipSizeChange(e, ship.label)}
                >
                    <Comp width="100%" height={40} style={shipSize.iconStyle} />
                    <span>{__(ship.label, 'dreamlines')}</span>
                </ShipSizeButton>
            );
        });
    };

    render() {
        return <ShipSizeWrapper>{this.getItems()}</ShipSizeWrapper>;
    }
}
