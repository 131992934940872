import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function FlightIcon(props: IIcon) {
    const newViewProps = '0 -420 512 512' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g id="flight">
                <path
                    transform="scale(1,-1)"
                    d="M493 447c11 5 23 -7 18 -18l-25 -54c-2 -4 -3 -6 -6 -9l-89 -89l37 -304c1 -5 -1 -11 -5 -15l-17 -17c-9 -9 -25 -6 -30 6l-97 218l-63 -63v-109c0 -5 -2 -10 -5 -13l-9 -9c-9 -9 -25 -7 -30 5l-39 93l-93 39c-12 5 -14 21 -5 30l9 9c3 3 8 5 13 5h109l63 63l-218 97
c-12 5 -15 21 -6 30l17 17c4 4 10 6 15 5l304 -37l89 89c3 3 6 4 9 6z"
                />
            </g>
        </Icon>
    );
}
