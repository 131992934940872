import styled from '@emotion/styled';
import React from 'react';
import ReactPagination from 'react-paginate';

import { PaginationItem } from './PaginationItem';

interface IProps {
    pages: number;
    currentPage: number;
    loadMore(page?: number): Promise<boolean>;
}

const PaginationContainer = styled.div`
    .paginationItem {
        color: ${({ theme }) => theme.pagination.textColor};
        border-color: ${({ theme }) => theme.pagination.borderColor};
        background-color: ${({ theme }) => theme.pagination.backgroundColor};
        &.highlight {
            color: ${({ theme }) => theme.pagination.textColorActive};
            border-color: ${({ theme }) => theme.pagination.borderColorActive};
            background-color: ${({ theme }) => theme.pagination.backgroundColorActive};
        }
        &.dots {
            background-color: ${({ theme }) => theme.colors.white[50]};
            border-color: ${({ theme }) => theme.colors.white[50]};
            color: ${({ theme }) => theme.colors.darkCharcoal[50]};
        }
    }
    .previous,
    .next {
        a[aria-disabled='true'] {
            div::before {
                opacity: 0.5;
            }
        }
    }
`;

export const Pagination = ({ pages, currentPage, loadMore }: IProps) => {
    return (
        <PaginationContainer>
            <ReactPagination
                previousLabel={<PaginationItem type="leftArrow" />}
                nextLabel={<PaginationItem type="rightArrow" />}
                breakLabel={<PaginationItem type="dots" />}
                pageCount={pages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                onPageChange={({ selected }) => {
                    loadMore(selected + 1);
                }}
                pageLinkClassName="paginationItem"
                containerClassName="paginationWrapper"
                activeLinkClassName="highlight"
                disabledClassName="dots"
                forcePage={currentPage - 1}
            />
        </PaginationContainer>
    );
};
