import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function CabinIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <g id="cabin">
                <path d="M444.55,79.25A132.13,132.13,0,0,0,227.71,219.83l-13.63,13.63L182,242.63a9.61,9.61,0,0,0-6.6,6.6L167,278.6,137.62,287a9.61,9.61,0,0,0-6.6,6.6L122.63,323l-29.37,8.39a9.61,9.61,0,0,0-6.6,6.6l-8.4,29.37-29.37,8.4A9.61,9.61,0,0,0,42,386.14l9.1,73a9.61,9.61,0,0,0,8,8.29l62.17,10.32a9.6,9.6,0,0,0,8.37-2.68L307.35,297.33A132.12,132.12,0,0,0,444.55,79.25ZM431,252.49a112.72,112.72,0,0,1-122.4,24.76,9.61,9.61,0,0,0-10.41,2.11L119.51,458l-50.22-8.34-7.2-57.73,26.59-7.6a9.61,9.61,0,0,0,6.6-6.6l8.4-29.37L133,340a9.61,9.61,0,0,0,6.6-6.6L148,304l29.37-8.4A9.61,9.61,0,0,0,184,289l8.39-29.37,29.36-8.39a9.62,9.62,0,0,0,4.15-2.44l19.91-19.91a9.61,9.61,0,0,0,2-10.67A112.9,112.9,0,1,1,431,252.49Z" />
                <path d="M439.16,163.31A88.4,88.4,0,0,0,360.5,84.64a31,31,0,0,0-25,52.75l50.89,50.89a30.73,30.73,0,0,0,21.81,9.14,31.41,31.41,0,0,0,13.54-3.1A30.48,30.48,0,0,0,439.16,163.31ZM413.48,177A11.6,11.6,0,0,1,400,174.7L349.1,123.81a11.6,11.6,0,0,1-2.28-13.48,11.34,11.34,0,0,1,10.24-6.65,13.6,13.6,0,0,1,1.42.08,69.19,69.19,0,0,1,61.57,61.57A11.31,11.31,0,0,1,413.48,177Z" />
                <path d="M213.59,291.43,103.64,401.38A9.61,9.61,0,1,0,117.23,415L227.17,305a9.61,9.61,0,1,0-13.59-13.59Z" />
                <path d="M245.77,259.25l-1,1A9.61,9.61,0,1,0,258.4,273.8l1-1a9.61,9.61,0,0,0-13.59-13.59Z" />
            </g>
        </Icon>
    );
}
