import React from 'react';

interface IProps {
    left?: boolean;
    right?: boolean;
    backgroundColor: string;
    textColor?: string;
    heightBox: number;
    children: React.ReactNode;
}

export const LabelledArrow = (props: IProps) => {
    const { left, right, backgroundColor, textColor, heightBox } = props;

    let classes = 'labelledArrow';

    const borderWidthLeft = `${heightBox / 2}px ${Math.floor(heightBox / 3)}px ${
        heightBox / 2
    }px 0`;
    const borderColorLeft = `transparent ${backgroundColor} transparent transparent`;
    const _left = `-${Math.floor(heightBox / 3)}px`;

    const borderWidthRight = `${heightBox / 2}px 0 ${heightBox / 2}px ${Math.floor(
        heightBox / 3,
    )}px`;
    const borderColorRight = `transparent transparent transparent ${backgroundColor}`;
    const _right = `-${Math.floor(heightBox / 3)}px`;

    return (
        <div
            className={classes}
            style={{ backgroundColor, height: heightBox }}
            // textColor={textColor}
            data-testid="triangled-arrow"
        >
            {left && (
                <div
                    className="left"
                    style={{
                        borderWidth: borderWidthLeft,
                        borderColor: borderColorLeft,
                        left: _left,
                    }}
                />
            )}
            {props.children}
            {right && (
                <div
                    className="right"
                    style={{
                        borderWidth: borderWidthRight,
                        borderColor: borderColorRight,
                        right: _right,
                    }}
                />
            )}
        </div>
    );
};
