import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import { IUGCReviewClass } from '../../interfaces/IUGCReviews';
import { RatingBar } from '../RatingBar';
interface HomeProps {
    reviewClasses?: IUGCReviewClass[];
}

export function OverallReviews(props: HomeProps) {
    const { reviewClasses } = props;
    const getReviews = () =>
        reviewClasses
            .map((item) => {
                return (
                    <RatingBar
                        key={item.reviewClass}
                        caption={`${__(item.reviewClass, 'dreamlines')} ${item.suffix}`}
                        rating={item.rating}
                        quantity={item.count}
                        percentageRatingView
                    />
                );
            })
            .reverse();
    if (reviewClasses && reviewClasses.length > 0) {
        return (
            <table className="overall-reviews">
                <thead>
                    <tr>
                        <th>
                            <div className="overall-reviews__heading">
                                {__('Overall Reviews', 'dreamlines')}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>{getReviews()}</tbody>
            </table>
        );
    }
    return null;
}
