import { IMobileWaypoint, ISail } from '../interfaces';
import { AMBASSADOR_COMPANY_ID, SE_TOURS_COMPANY_ID } from '../consts/variables';
import { __ } from './TranslationService';
import { formatShipTitleArticle } from './cruiseSearchHelper';

export function getRequestFormBtnProps(
    url: string,
    sail: ISail,
    cheapestCabinNidForSail: number,
): string {
    const cabinPart = cheapestCabinNidForSail
        ? cheapestCabinNidForSail
        : sail?.minPriceCabin?.cabinType?.nid ?? '';
    return `${url}/${sail?.nid}/${cabinPart}`;
}

export const getHarbourEmbarkations = ({
    waypoints,
}: {
    waypoints: IMobileWaypoint[];
}): {
    start: { title: string; city: string };
    end: { title: string; city: string };
} => {
    const portOfEmbarkation = waypoints.find((waypoint) => waypoint.isPortOfEmbarkation);
    const portOfDisembarkation = waypoints.find((waypoint) => waypoint.isPortOfDisembarkation);
    const startHarbour = portOfEmbarkation ? portOfEmbarkation : null;
    const endHarbour = portOfDisembarkation ? portOfDisembarkation : null;
    return {
        start: { title: startHarbour.title, city: startHarbour.city },
        end: { title: endHarbour.title, city: startHarbour.city },
    };
};

export const getDepartureDate = ({ sails, dateRange }: { sails: ISail[]; dateRange: string }) => {
    if (sails.length > 1) return dateRange;
    return sails.length === 1 ? sails[0].label.split(' - ')[0] : null;
};

export const getCruiseTitle = ({
    isDreamlinesPackage,
    companyNid,
    shipTitle,
    cruiseTitle,
    companyTitle,
    startHarbour,
    isPreserveOriginalTitle,
    destination,
}: {
    isDreamlinesPackage?: boolean;
    isPreserveOriginalTitle?: boolean;
    companyNid: number;
    shipTitle?: string;
    cruiseTitle: string;
    companyTitle?: string;
    startHarbour?: string;
    destination?: string;
}) => {
    const isSeTours = companyNid === SE_TOURS_COMPANY_ID;
    const isAmbassadorCompany = companyNid === AMBASSADOR_COMPANY_ID;

    const useTitleFromBackend =
        isPreserveOriginalTitle ||
        isDreamlinesPackage ||
        isSeTours ||
        !startHarbour ||
        isAmbassadorCompany;

    if (useTitleFromBackend || !shipTitle) {
        return cruiseTitle;
    }
    const formattedShipTitle = `${formatShipTitleArticle(companyTitle)} ${shipTitle}`;
    const destinationString = destination ? `${destination} ` : '';

    return `${destinationString}${__('from', 'dreamlines')} ${startHarbour} ${formattedShipTitle}`;
};
