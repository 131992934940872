import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function DeletedIcon({ width, height, viewBox, style, className }: IIcon) {
    return (
        <Icon width={width} height={height} viewBox={viewBox} style={style} className={className}>
            <g>
                <path d="M78.2,391.2V174.9h75.4c19.2,0,34.8-15.6,34.8-34.8l0-74.4c31.1,0,116.7,0.1,157.9,0.3c6.4,0,11.6,5.3,11.6,11.7v128.5H381V77.6c0-19.1-15.6-34.7-34.7-34.8c-50.6-0.2-168.4-0.3-169.5-0.3c0,0,0,0,0,0c-1.9,0-3.7,0.5-5.3,1.3c-1.4,0.6-2.6,1.4-3.7,2.5L58.5,154.2c-2.4,2.4-3.5,5.6-3.4,8.7c0,0.1,0,0.3,0,0.4v227.9c0,19.2,15.6,34.8,34.8,34.8H197v-23.1H89.9C83.4,402.9,78.2,397.7,78.2,391.2z M165.2,81.3l0,58.8c0,6.4-5.2,11.7-11.7,11.7H93.9L165.2,81.3z" />
                <path d="M438.1,433.2L438.1,433.2l-75.9-75.9l75.8-75.8c5.1-5.1,5.2-13.6,0.1-18.8l-0.1-0.1l-0.1-0.1l-8-8c-2.5-2.5-5.9-3.9-9.5-3.9c-3.5,0-6.9,1.4-9.4,3.8l-0.1,0.1l-0.1,0.1l-75.8,75.8l-75.8-75.8c-2.5-2.5-5.9-3.9-9.5-3.9c-3.5,0-6.9,1.4-9.4,3.8l-0.1,0.1l-0.1,0.1l-8,8c-5.1,5.1-5.2,13.6-0.1,18.8l0.1,0.1l0.1,0.1l75.8,75.8l-75.8,75.8c-5.1,5.1-5.2,13.6-0.1,18.8l0.1,0.1l0.1,0.1l8,8c2.5,2.5,5.9,3.9,9.5,3.9c3.5,0,6.9-1.4,9.4-3.8l0.1-0.1l0.1-0.1l75.8-75.8l75.8,75.8c2.5,2.5,5.9,3.9,9.5,3.9c3.5,0,6.9-1.4,9.4-3.8l0.1-0.1l0.1-0.1l8-8C443.2,447,443.2,438.5,438.1,433.2L438.1,433.2z" />
            </g>
        </Icon>
    );
}
