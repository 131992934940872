import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from '@emotion/styled';

interface IComponentProps {
    className?: string;
    width?: number;
    height?: number;
    title?: string;
    id?: string;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 12px;
    border-radius: 8px;
`;

const SkeletonItem = () => (
    <ContentLoader
        speed={2}
        viewBox="0 0 343 392"
        backgroundColor="#e3ecf1"
        foregroundColor="#dde7ec"
        uniqueKey="search-loader-mobile"
        style={{ backgroundColor: 'white', borderRadius: 8 }}
        width="100%"
    >
        <rect x="12" y="12" rx="5" ry="5" width="319" height="206" />
        <rect x="14" y="232" rx="5" ry="5" width="159" height="8" />
        <rect x="12" y="247" rx="5" ry="5" width="254" height="27" />
        <rect x="12" y="282" rx="5" ry="5" width="111" height="27" />
        <rect x="14" y="319" rx="5" ry="5" width="159" height="8" />
        <rect x="219" y="338" rx="5" ry="5" width="112" height="8" />
        <rect x="172" y="353" rx="5" ry="5" width="159" height="27" />
    </ContentLoader>
);

export const SearchLoaderMobile = (props: IComponentProps) => {
    const list = Array.from({ length: 10 });
    return (
        <Wrapper {...props}>
            {list.map((_, idx) => (
                <SkeletonItem key={`SearchLoader-${idx}`} />
            ))}
        </Wrapper>
    );
};
