import * as React from 'react';
import { normalizeLabels } from '../../helpers/labels';
import { __ } from '../../helpers/TranslationService';
import { IUGCLabel } from '../../interfaces/ISearchApi';
import { IUGCReviewsAggregation } from '../../interfaces/IUGCReviews';
import { LabelBox } from '../LabelBox/LabelBox'; //: TODO
import { RatingBox } from './RatingBox';

interface ISummaryReviewProps {
    data: IUGCReviewsAggregation['data'];
    ugcLabels?: IUGCLabel[];
    labelTitle?: string;
}

export const SummaryReview = ({ data, ugcLabels, labelTitle }: ISummaryReviewProps) => {
    const labels = normalizeLabels({
        ugcLabels,
        excludeCluster: ['constellation', 'productData'],
    }).slice(0, 4);

    return (
        <div className="summaryReview">
            <div className="ratingBoxWithArrow">
                <RatingBox rating={data.attributes.overall} isWhite />
                <span className="ratingBoxArrow" />
            </div>
            <div className="ratingInfo">
                <div>{`${__(data.attributes.reviewClass, 'dreamlines')}`}</div>
                <span>{__(`Based on %s reviews`, 'dreamlines', data.attributes.numRatings)}</span>
            </div>
            {!!labels?.length && (
                <div className="labelsSection">
                    {!!labelTitle && <div>{labelTitle}</div>}
                    <div className="labels">
                        {labels.map((label) => (
                            <LabelBox
                                key={label.label}
                                type={label.label}
                                label={__(label.label, 'dreamlines')}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
