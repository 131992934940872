export const hasHotelServiceCharges = (
    countryCode: string,
    companyTitle: string,
    shipTitle: string,
    departureDate: number,
) => {
    const november1stDate = new Date('2021-11-01T00:00:00').getTime() / 1000;
    const hsc =
        ['de', 'nl', 'ru', 'dreamlines.de', 'dreamlines.nl', 'dreamlines.ru'].indexOf(
            countryCode,
        ) !== -1 &&
        (companyTitle?.indexOf('MSC') !== -1 || shipTitle?.indexOf('MSC') !== -1) &&
        departureDate >= november1stDate;

    return hsc;
};
