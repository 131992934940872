import React from 'react';
import styled from '@emotion/styled';
import { BreakpointsEnum } from '../../../../helpers/breakpoints';

interface IProps {
    title: string;
    logo: string;
    className?: string;
}

const StyledImage = styled.img`
    margin-left: 16px;
    max-height: 50px;
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0 16px;

    @media (min-width: ${BreakpointsEnum.md}) {
        margin-bottom: 8px;
        padding-top: 8px;
    }

    p {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
`;

export const SearchResultItemHeader = (props: IProps) => {
    return (
        <StyledContainer className={props.className}>
            <p> {props.title} </p>
            <StyledImage src={props.logo} />
        </StyledContainer>
    );
};
