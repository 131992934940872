import React from 'react';
import styled from '@emotion/styled';
import { Button } from '../Button/Button';
import { BreakpointsEnum } from '../../helpers/breakpoints';

const PromoBannerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    @media (min-width: ${BreakpointsEnum.sm}) {
        flex-direction: row-reverse;
    }
`;

const PromoBannerImage = styled.div<{ image: string }>`
    width: 100%;
    height: 276px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${({ image }) => image});
    @media (min-width: ${BreakpointsEnum.sm}) {
        width: 376px;
        height: auto;
    }
    @media (max-width: 320px) {
        height: 214px;
    }
`;

const PromoBannerContent = styled.div`
    flex: 1;
    padding: 24px;
    @media (min-width: ${BreakpointsEnum.sm}) {
        display: flex;
        flex-direction: column;
        min-height: 300px;
    }
`;

const PromoBannerSubTitle = styled.div`
    color: ${({ theme }) => theme.text.color.primary2};
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
`;

const PromoBannerTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 6px;
`;

const PromoBannerDescription = styled.div`
    color: ${({ theme }) => theme.text.color.primary};
    font-size: 16px;
    line-height: 24px;
    & p {
        margin: 0 0 6px 0;
    }
    @media (min-width: ${BreakpointsEnum.sm}) {
        flex: 2;
    }
`;

const PromoBannerButton = styled.div`
    margin-top: 42px;
`;

type IPromoBanner = {
    title: string;
    subTitle?: string;
    description: string;
    imageUrl?: string;
    buttonText?: string;
    buttonUrl?: string;
};

export const PromoBanner = (props: IPromoBanner) => {
    const { title, subTitle, imageUrl, description, buttonText, buttonUrl } = props;
    return (
        <PromoBannerContainer>
            {imageUrl && <PromoBannerImage image={imageUrl} />}
            <PromoBannerContent>
                {subTitle && <PromoBannerSubTitle>{subTitle}</PromoBannerSubTitle>}
                <PromoBannerTitle>{title}</PromoBannerTitle>
                <PromoBannerDescription dangerouslySetInnerHTML={{ __html: description }} />
                {buttonUrl && buttonText && (
                    <PromoBannerButton>
                        <Button as="a" href={buttonUrl} withArrow>
                            {buttonText}
                        </Button>
                    </PromoBannerButton>
                )}
            </PromoBannerContent>
        </PromoBannerContainer>
    );
};
