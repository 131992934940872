import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function InfoTransparentIcon({ width, height, viewBox, style, className }: IIcon) {
    const newViewProps = '0 0 17 17' ?? viewBox;
    return (
        <Icon
            width={width}
            height={height}
            viewBox={newViewProps}
            style={style}
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.63013 16.4901C13.0484 16.4901 16.6301 12.9083 16.6301 8.49005C16.6301 4.07177 13.0484 0.490051 8.63013 0.490051C4.21185 0.490051 0.630127 4.07177 0.630127 8.49005C0.630127 12.9083 4.21185 16.4901 8.63013 16.4901ZM7.86353 7.65678C7.86353 7.23338 8.20679 6.89012 8.63019 6.89012C9.05359 6.89012 9.39686 7.23338 9.39686 7.65678V11.9235C9.39686 12.3469 9.05359 12.6901 8.63019 12.6901C8.20679 12.6901 7.86353 12.3469 7.86353 11.9235V7.65678ZM8.63019 5.35672C9.05359 5.35672 9.39686 5.01347 9.39686 4.59005C9.39686 4.16663 9.05359 3.82338 8.63019 3.82338C8.20679 3.82338 7.86353 4.16663 7.86353 4.59005C7.86353 5.01347 8.20679 5.35672 8.63019 5.35672Z"
                fill="currentColor"
            />
        </Icon>
    );
}
