import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function ShipSizeLIcon({ width, height, viewBox = '0 0 512 256', style, className }: IIcon) {
    return (
        <Icon width={width} height={height} viewBox={viewBox} style={style} className={className}>
            <g id="shipsizeL">
                <path d="M346.9,129.9H142.4c-3,0.3-5.3,2.9-5,6c0.2,2.7,2.4,4.8,5,5h204.4c3,0.3,5.7-2,6-5c0.3-3-2-5.7-5-6C347.5,129.9,347.2,129.9,346.9,129.9z" />
                <path d="M346.9,105.5H142.4c-3-0.3-5.7,2-6,5s2,5.7,5,6c0.3,0,0.6,0,0.9,0h204.4c3,0.3,5.7-2,6-5c0.3-3-2-5.7-5-6C347.5,105.5,347.2,105.5,346.9,105.5z" />
                <path d="M346.9,82H142.4c-3-0.3-5.7,2-6,5s2,5.7,5,6c0.3,0,0.6,0,0.9,0h204.4c3-0.3,5.3-2.9,5-6C351.7,84.3,349.5,82.2,346.9,82z" />
                <path d="M502.2,154.6c-0.9-1.8-2.8-3-4.9-2.9H444L415.4,108l0,0l-25-40c-8-12.3-12.3-20.8-26.9-20.8h-4.4l-17.3-34.7c-1.4-2.7-4.8-3.7-7.5-2.3c-2.6,1.4-3.7,4.5-2.4,7.2l14.9,29.7h-38.1c-1.5,0-2.9,0.6-3.9,1.6l-7.8,7.8H159.6l-7.8-7.8c-1-1-2.4-1.6-3.9-1.6h-14.5L125,13.5c-0.6-2.5-2.8-4.2-5.4-4.2H91.3c-3,0-5.5,2.5-5.5,5.5v32.3H58.5c-6.1,0-11.3,4.3-12.6,10.2l-17.1,76.9v0.1l-4.1,19.5c-0.4,2.1-0.5,4.2-0.3,6.3l6.9,67.1c0.3,2.8,2.7,4.9,5.5,4.9h5.9v3.9c0,3,2.5,5.5,5.5,5.5h402.2c3,0,5.5-2.5,5.5-5.5c0-5,1.5-10,4.3-14.1l41.6-61.6C503.1,158.6,503.2,156.4,502.2,154.6z M451.3,215.6c-3,4.4-5,9.5-5.8,14.8H53.7v-3.9c0-3-2.5-5.5-5.5-5.5h-6.5l-3.1-30h411.9c3,0.3,5.7-2,6-5c0.3-3-2-5.7-5-6c-0.3,0-0.6,0-0.9,0h-413l-1.8-17.2h344.2c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5H36.4l2.3-10.8h75.5c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5H41l2.8-13.2h70.4c3,0.3,5.7-2,6-5s-2-5.7-5-6c-0.3,0-0.6,0-0.9,0H46.1L49,92.6h64.6c3,0.3,5.7-2,6-5s-2-5.7-5-6c-0.3,0-0.6,0-0.9,0h-62l5.1-21.9c0.2-0.9,0.9-1.5,1.8-1.5h51.7c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5H96.8V20.5h18.4l8.4,33.5v0.1c0.1,0.3,0.2,0.6,0.3,0.8c0,0,0,0.1,0.1,0.1h0c0.1,0.2,0.3,0.5,0.4,0.7c0,0,0,0.1,0.1,0.1h0c0.1,0.2,0.3,0.4,0.5,0.6l0.2,0.1c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0,0.1,0.1,0.2,0.1c0.4,0.3,0.9,0.6,1.4,0.7c0.1,0,0.1,0,0.2,0.1l0.7,0.2h0.1c0.2,0,0.5,0.1,0.7,0.1h16.6l7.8,7.8c1,1,2.4,1.6,3.9,1.6h142c1.5,0,2.9-0.6,3.9-1.6l7.8-7.8h52.6c6,0,14.8,11.6,22.5,23.4h-10.4c-3-0.3-5.7,2-6,5c-0.3,3,2,5.7,5,6c0.3,0,0.6,0,0.9,0h17.3c3.2,5.4,6,10.1,7.6,12.6l0.2,0.3h-25.6c-3,0.3-5.3,2.9-5,6c0.2,2.7,2.4,4.8,5,5H408l8.7,13.2h-41.6c-3-0.3-5.7,2-6,5c-0.3,3,2,5.7,5,6c0.3,0,0.6,0,0.9,0h48.7l7,10.8h-27.5c-3-0.3-5.7,2-6,5c-0.3,3,2,5.7,5,6c0.3,0,0.6,0,0.9,0H487L451.3,215.6z" />
            </g>
        </Icon>
    );
}
