import * as React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import {
    clearFilterParams,
    doNewSearchViaUrl,
    loadMore,
    getRatesBulk,
    setFilterParam,
    setLoadingState,
    setSortOrder,
    toggleSearchFilter,
    fetchAllFacets,
} from '../../actions/searchActions';
import { loadMoreUGCReviews, setReviewSorting } from '../../actions/ugcAction';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import { CollapsibleText } from '../../components/CollapsibleDropDown/CollapsibleText';
import ReviewsDesktop from '../../components/DesktopReviews/DesktopReviews';
import { Discount } from '../../components/Discount';
import { Discount as DiscountDesktop } from '../../components/Discount/Discount';
import { LoadMoreButtonWrapper } from '../../components/LoadMoreButtonWrapper';
import { ActiveFilters } from '../../components/SearchFilters/ActiveFilters';
import { FilterAndSortBar } from '../../components/SearchFilters/FilterAndSortBar';
import { SearchFilters } from '../../components/SearchFilters/SearchFilters';
import * as vars from '../../consts/variables';
import { IsomorphicHelpers } from '../../helpers/IsomorphicHelpers';
import { __ } from '../../helpers/TranslationService';
import { IUGCReviewsReducer } from '../../interfaces/IUGCReviews';
import { IState } from '../../reducers';
import { ILandingPageReducer } from '../../reducers/landingPage';
import { ISearchReducer } from '../../reducers/search';
import { FAQContact } from '../FAQ/components/FAQContact';
import { NoResult } from '../Search/components/NoResults';
import { SearchResultList } from '../Search/components/SearchResultList';
import { SearchLoader } from '../../components/LoadingIndicator/SearchListLoader';
import { SearchLoaderMobile } from '../../components/LoadingIndicator/SearchLoaderMobile';
import { SearchListModal } from '../../components/SearchListModal';
import { PromoBanner } from '../../components/PromoBanner/PromoBanner';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { FurtherInfo } from '../../components/FurtherInfo/FurtherInfo';

const style: Record<string, React.CSSProperties> = {
    reviewsWrapper: {
        padding: '15px 15px 0px 15px',
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '1px 0 1px 15px',
        backgroundColor: vars.lightGray,
        position: 'relative',
    },
    headline: {
        marginTop: 13,
        marginBottom: 7,
        fontSize: 22,
        fontWeight: 'bold',
        flexGrow: 1,
    },
    description: {
        fontSize: 15,
        margin: 0,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: vars.lightGray,
    },
    discountWrapper: {
        marginRight: 0,
        marginLeft: '12px',
        flex: '0 0 auto',
    },
    discount: {
        fontSize: 16,
        fontWeight: 'bold',
        padding: '0 3px',
    },
    seoText: {
        fontSize: 15,
        paddingLeft: 15,
        paddingRight: 15,
        margin: 0,
    },
};

export const PromoBannerWrapper = styled.div`
    margin: 40px 32px 16px 32px;
    @media (min-width: ${BreakpointsEnum.lg}) {
        margin: 24px 0 0 0;
    }
`;
interface StateProps {
    pageData: any;
    landingPage: ILandingPageReducer;
    search: ISearchReducer;
    history: any;
    reviews: IUGCReviewsReducer;
    homePage: any;
    phoneNumber: string;
    countryCode: string;
}

interface DistpachProps {
    loadMore: (page?: number) => Promise<boolean>;
    getRatesBulk: () => void;
    doNewSearchViaUrl: any;
    setFilterParam: any;
    clearFilterParams: (fetchFacets: boolean) => () => void;
    toggleSearchFilter: any;
    setSortOrder: (string) => any;
    setLoadingState: (isLoading: boolean) => void;
    loadMoreUGCReviews: (link: string) => void;
    setReviewSorting: (sortString: string, filterParams: string, type: string) => void;
    fetchAllFacets: () => void;
}

type Props = StateProps & DistpachProps;

export class LandingPageClass extends React.Component<Props, any> {
    hasMore: boolean;
    infiniteScrollActive: boolean;

    searchFilterRef: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.hasMore = true;
        this.infiniteScrollActive = false;
        this.searchFilterRef = React.createRef();

        const deviceType = this.props?.pageData?.deviceType;

        this.state = {
            isDesktop: deviceType === 'desktop',
            firstLoad: true,
            forceRerender: true,
        };

        this.props?.getRatesBulk();
        this.props.fetchAllFacets();
    }

    componentDidMount() {
        this.resize();
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadMore);
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        if (IsomorphicHelpers.isServerSide) return;

        const width = window.innerWidth;

        const isDesktop = width >= 1200;
        if (this.state.isDesktop !== isDesktop || this.state.firstLoad) {
            this.setState({ isDesktop, firstLoad: false });
            if (isDesktop) {
                window.removeEventListener('scroll', this.loadMore);
            } else {
                window.addEventListener('scroll', this.loadMore);
            }
        }
    };

    loadMore = () => {
        if (this.infiniteScrollActive) {
            const scrollY =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop ||
                0;
            const loadTriggerThreshold = document.body.scrollHeight - 900;
            if (
                loadTriggerThreshold < scrollY + window.innerHeight &&
                !this.props.search.isSearchResultsLoading &&
                !this.props.search.isSearchFilterOpen
            ) {
                this.loadMoreWithRates();
            }
        }
    };

    loadMoreFirstTime = () => {
        this.loadMoreWithRates();
        this.infiniteScrollActive = true;
    };

    loadMoreWithRates = () => {
        this.props.loadMore().then((dataLoaded: boolean) => {
            if (dataLoaded) this.props?.getRatesBulk();
        });
    };

    setSortOrder = (sortOrder: string) => {
        this.props.setSortOrder(sortOrder);
        this.props.doNewSearchViaUrl();
    };

    render() {
        const { landingPage, search } = this.props;
        const { discount } = landingPage;
        const showDiscount = landingPage.showDiscount || false;
        const showSearchModal = search.isSeachListHidden;
        const { defaultCurrency, dualCurrency } = this.props?.pageData?.appConfig;

        const LoadMoreButton =
            !this.infiniteScrollActive && this.props.search.searchResults.numResults > 10 ? (
                <LoadMoreButtonWrapper key={1} onClick={this.loadMoreFirstTime} />
            ) : null;

        const { reviews, sortString, isLoading: areReviewsLoading } = this.props.reviews;
        const showReviewSection = reviews?.rating?.data.length > 0;

        const { cruises, numResults } = this.props.search.searchResults;
        const showReviewsAndSeo = cruises.length >= numResults || cruises.length === 10;

        const activeFilterProps = {
            search,
            removeFilter: this.searchFilterRef?.current?.removeActiveFilter,
            clearFilterParams: this.searchFilterRef?.current?.clearFilterParams(),
            currency: defaultCurrency,
            dualCurrency: dualCurrency,
            exchangeRate: this.props?.pageData?.header?.exchangeRate
                ? this.props?.pageData.header.exchangeRate.exchangeRate
                : null,
        };
        const hasResults = this.props.search.searchResults.cruises.length > 0;
        const showResultList = this.state.isDesktop
            ? hasResults && !search.isSearchResultsLoading
            : hasResults;
        const promoBanner = this.props.landingPage?.promoBanner;

        return (
            <div className="container searchPage">
                {this.state.isDesktop && promoBanner && (
                    <div className="row">
                        <PromoBannerWrapper>
                            <PromoBanner
                                title={promoBanner.title}
                                description={promoBanner.description}
                                subTitle={promoBanner?.subTitle}
                                imageUrl={promoBanner?.imageUrl}
                                buttonText={promoBanner?.button?.text}
                                buttonUrl={promoBanner?.button?.url}
                            />
                        </PromoBannerWrapper>
                    </div>
                )}
                {this.state.isDesktop && (
                    <div className="row headline">
                        {Boolean(discount) && showDiscount && (
                            <DiscountDesktop amount={discount} showUpToText={true} size="L" />
                        )}
                        <div
                            className="headlineWrapper"
                            style={{
                                ...(discount ? {} : { marginLeft: -15 }),
                            }}
                        >
                            <h1>{this.props.landingPage.headerTitle}</h1>
                            <p
                                style={{
                                    ...style.description,
                                    flexBasis: '100%',
                                    ...(discount
                                        ? { paddingLeft: 0, marginTop: '5px' }
                                        : { paddingLeft: 0 }),
                                }}
                            >
                                {this.props.landingPage.headerDescription.replace(
                                    /<\/?[^>]+(>|$)/g,
                                    '',
                                )}
                            </p>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="searchPageFilterWrapper">
                        {(this.props.search.isSearchFilterOpen || this.state.isDesktop) && (
                            <SearchFilters
                                ref={this.searchFilterRef}
                                isDesktop={this.state.isDesktop}
                            />
                        )}
                        {this.state.isDesktop && (
                            <FAQContact
                                countryCode={this.props.countryCode}
                                phoneNumber={this.props.phoneNumber}
                                contactPageUrl={this.props?.pageData?.brandConfig?.contactPagePath}
                            />
                        )}
                    </div>

                    <FilterAndSortBar
                        toggleSearchFilter={this.props.toggleSearchFilter}
                        setSortOrder={this.setSortOrder}
                        sortOrder={this.props.search.searchParams.sortString}
                    />

                    {!this.state.isDesktop && (
                        <>
                            {promoBanner && (
                                <div className="row">
                                    <PromoBannerWrapper>
                                        <PromoBanner
                                            title={promoBanner.title}
                                            description={promoBanner.description}
                                            subTitle={promoBanner?.subTitle}
                                            imageUrl={promoBanner?.imageUrl}
                                            buttonText={promoBanner?.button?.text}
                                            buttonUrl={promoBanner?.button?.url}
                                        />
                                    </PromoBannerWrapper>
                                </div>
                            )}
                            <div style={style.headerWrapper}>
                                <h1 style={style.headline}>{this.props.landingPage.headerTitle}</h1>
                                {Boolean(discount) && showDiscount && (
                                    <Discount
                                        discount={discount}
                                        wrapperStyle={style.discountWrapper}
                                        innerStyle={style.discount}
                                        type="ribbon"
                                    />
                                )}
                            </div>
                            <p style={style.description}>
                                {this.props.landingPage.headerDescription.replace(
                                    /<\/?[^>]+(>|$)/g,
                                    '',
                                )}
                            </p>
                        </>
                    )}
                    <div className="searchPageResultWrapper">
                        {this.state.isDesktop && <ActiveFilters {...activeFilterProps} />}
                        {showResultList && [
                            <SearchResultList setSortOrder={this.setSortOrder} key={0} />,
                            !this.state.isDesktop ? LoadMoreButton : null,
                        ]}
                        {this.props.search.searchResults.cruises.length === 0 &&
                            !this.props.search.isSearchResultsLoading && (
                                <NoResult
                                    isLoading={this.props.search.isSearchResultsLoading}
                                    clearFilterParams={this.props.clearFilterParams}
                                    toggleSearchFilter={this.props.toggleSearchFilter}
                                    search={this.props.search}
                                />
                            )}
                        {search.isSearchResultsLoading &&
                            (this.state.isDesktop ? <SearchLoader /> : <SearchLoaderMobile />)}
                        {showSearchModal && this.state.isDesktop && (
                            <SearchListModal
                                isLoading={search.isFacetsLoading}
                                doNewSearchViaUrl={this.props.doNewSearchViaUrl}
                                toggleSearchFilter={this.props.toggleSearchFilter}
                                numResults={search.searchFacets.numResults}
                            />
                        )}
                    </div>
                </div>
                <div className="row" style={{ clear: 'both' }}>
                    {this.props.landingPage.seo.text && showReviewsAndSeo && (
                        <div style={style.seoText}>
                            <div className="row furtherInfoSection">
                                <h2 className="headline">{this.props.landingPage.title}</h2>
                                <FurtherInfo description={this.props.landingPage.seo.text} />
                            </div>
                        </div>
                    )}

                    {showReviewSection && showReviewsAndSeo && (
                        <>
                            {this.state.isDesktop ? (
                                <div className="ugcSection">
                                    <h2 className="headline">
                                        {__(
                                            'Reviwes for %s',
                                            'dreamlines',
                                            this.props.landingPage.title,
                                        )}
                                    </h2>
                                    <div className="desktopReviews">
                                        <ReviewsDesktop
                                            reviews={reviews}
                                            offers={this.props.landingPage.offers}
                                            priceCurrency={
                                                this.props.pageData.appConfig.defaultCurrency
                                            }
                                            loadMoreReviews={this.props.loadMoreUGCReviews}
                                            setSortString={this.props.setReviewSorting}
                                            sortString={sortString}
                                            filterParams={`filterParams[waypoints][]=${
                                                (this.props.landingPage as any).searchParameters
                                                    .filterParams.harbour
                                            }`}
                                            reviewsType="destinationReviews"
                                            shipTitle={'pageData.shipTitle'}
                                            isLoading={areReviewsLoading}
                                            showDetailedReviews={false}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div style={style.reviewsWrapper}>
                                    <ReviewsDesktop
                                        reviews={reviews}
                                        offers={this.props.landingPage.offers}
                                        priceCurrency={
                                            this.props.pageData.appConfig.defaultCurrency
                                        }
                                        loadMoreReviews={this.props.loadMoreUGCReviews}
                                        setSortString={this.props.setReviewSorting}
                                        sortString={sortString}
                                        filterParams={`filterParams[waypoints][]=${
                                            (this.props.landingPage as any).searchParameters
                                                .filterParams.harbour
                                        }`}
                                        reviewsType="harbourReviews"
                                        shipTitle={'pageData.shipTitle'}
                                        isLoading={areReviewsLoading}
                                        showDetailedReviews={false}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    <div style={{ padding: '15px' }}>
                        <Breadcrumbs breadcrumbs={landingPage.breadcrumbs} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IState): StateProps => ({
    pageData: state.pageData,
    search: state.search,
    landingPage: state.landingPage,
    history: state.router.history,
    reviews: state.reviews,
    homePage: state.homePage,
    phoneNumber: state.pageData.brandConfig.phoneNumber,
    countryCode: state.pageData.appConfig.countryCode,
});

const mapDispatchToProps = {
    loadMore,
    getRatesBulk,
    doNewSearchViaUrl,
    setFilterParam,
    clearFilterParams,
    toggleSearchFilter,
    setSortOrder,
    loadMoreUGCReviews,
    setReviewSorting,
    setLoadingState,
    fetchAllFacets,
};

// @TODO: Fix dispatch props
export const LandingPage = connect<StateProps, any, any>(
    mapStateToProps,
    mapDispatchToProps,
)(LandingPageClass);
export default LandingPage;
