import * as React from 'react';
import { PlusCircleIcon } from '../Icons/PlusCircleIcon';
import { MinusCircleIcon } from '../Icons/MinusCircleIcon';
import { CabinIcon } from '../Icons/CabinIcon';

interface ReviewCommentProps {
    comment: string;
    icon?: 'plus' | 'minus' | 'cabin';
    heading?: string;
}
export function ReviewComment({ comment, icon, heading }: ReviewCommentProps) {
    return comment ? (
        <div className="review-comment">
            <span className={'review-comment__icon '}>
                {icon === 'plus' && <PlusCircleIcon width={30} height={30} />}
                {icon === 'minus' && <MinusCircleIcon width={30} height={30} />}
                {icon === 'cabin' && <CabinIcon className="icon--cabin" width={30} height={30} />}
            </span>
            <div className="review-comment__content">
                <div className="review-comment__heading">{heading}</div>
                {comment}
            </div>
        </div>
    ) : null;
}
