import classnames from 'classnames';
import * as React from 'react';
import { memo, useState } from 'react';
import { __ } from '../../helpers/TranslationService';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IOffers } from '../../interfaces/IReviews';
import { IUGCLabel } from '../../interfaces/ISearchApi';
import { IUGCReviews } from '../../interfaces/IUGCReviews';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { IReviewType } from '../Reviews/Reviews';
import { SummaryReview } from '../Reviews/SummaryReview';
import { DetailedReviews } from './DetailedReviews';
import { OverallReviews } from './OverallReviews';
import { ReviewsGoogleScript } from './ReviewsGoogleScript';
import { ReviewsList } from './ReviewsList';
export interface IReviewsDesktop {
    filterParams: string;
    reviewsType: IReviewType;
    showDetailedReviews?: boolean;
    collapseComments?: boolean;
    reviews?: IUGCReviews;
    offers?: IOffers;
    priceCurrency?: string;
    shipTitle?: string;
    googleScriptName?: string;
    sortString?: string;
    isLoading?: boolean;
    disableGoogleScript?: boolean;
    loadMoreReviews?: Function;
    setSortString?: Function;
    ugcLabels?: IUGCLabel[];
}
const DesktopReviews = (props: IReviewsDesktop) => {
    const {
        collapseComments,
        showDetailedReviews,
        reviewsType,
        filterParams,
        googleScriptName,
        disableGoogleScript,
    } = props;
    const storeState = useTypedSelector((state) => state);
    const { cruisePage, reviews: reviewsData, pageData } = storeState;
    const { reviews, sortString, isLoading } = reviewsData;
    const { ship, ugcLabels } = cruisePage;
    const { title: shipTitle } = ship;
    const { defaultCurrency: priceCurrency } = pageData?.appConfig;
    const { loadMoreUGCReviews, setReviewSorting } = useActions();

    const [state, setState] = useState({
        collapsedComments: collapseComments,
    });

    const toggleCollapsedComments = () => {
        setState({ ...state, collapsedComments: !state.collapsedComments });
    };

    const condition = reviews?.rating?.data && reviews.rating.data.length > 0;

    if (!condition) return null;

    const label = `${__('Thats what our customers say about the', 'dreamlines')} ${shipTitle}:`;
    const toggleText = state.collapsedComments
        ? __('load more', 'dreamlines')
        : __('show less', 'dreamlines');
    return (
        <div className="reviews">
            <SummaryReview
                data={reviews.aggregation.data}
                ugcLabels={ugcLabels}
                labelTitle={shipTitle ? label : undefined}
            />
            <div className="reviews__row">
                <div className="reviews__column reviews__column--left">
                    <div className="reviews__overall-reviews">
                        <OverallReviews
                            reviewClasses={reviews.aggregation.data.attributes.reviewClasses}
                        />
                    </div>
                </div>

                <div className="reviews__column reviews__column--right">
                    {showDetailedReviews !== false && (
                        <div className="reviews__detail-reviews-desktop">
                            <DetailedReviews ratings={reviews.aggregation.data.attributes} />
                        </div>
                    )}
                </div>
            </div>
            {collapseComments && (
                <span className="reviewListToggle" onClick={toggleCollapsedComments}>
                    {toggleText}
                    <ArrowDownIcon
                        className={classnames({ rotate: !state.collapsedComments })}
                        width={14}
                    />
                </span>
            )}
            {!state.collapsedComments && (
                <ReviewsList
                    {...reviews.rating}
                    sortString={sortString}
                    setSortString={setReviewSorting}
                    isLoading={isLoading}
                    filterParams={filterParams}
                    reviewsType={reviewsType}
                    loadMoreReviews={loadMoreUGCReviews}
                />
            )}
            {!disableGoogleScript && (
                <ReviewsGoogleScript
                    name={props.googleScriptName}
                    reviewsType={reviewsType}
                    ratingValue={reviews.aggregation.data.attributes.overall}
                    reviewCount={reviews.aggregation.data.attributes.numRatings}
                    offerCount={props.offers?.count ?? 0}
                    lowPrice={props.offers?.cheapestPrice ?? 0}
                    priceCurrency={priceCurrency}
                />
            )}
        </div>
    );
};
export default memo(DesktopReviews);
