import * as React from 'react';
import { Icon } from '../../Icon';
import { IIcon } from '../../../interfaces/IIcon';

export const OutlinedRouteIcon = (props: IIcon) => {
    return (
        <Icon
            width={props.width ?? 16}
            height={props.height ?? 16}
            viewBox={props.viewBox ?? '0 0 16 16'}
            style={props.style}
            className={props.className}
        >
            <>
                <path
                    d="M12.3481 7.44747H3.6516C2.45708 7.44747 1.48532 6.47519 1.48532 5.28015C1.48532 4.086 2.45708 3.11453 3.6516 3.11453H9.08713C9.39232 3.11453 9.63961 2.86717 9.63961 2.56206C9.63961 2.25694 9.39232 2.00958 9.08713 2.00958H3.6516C1.84789 2.00958 0.380371 3.47673 0.380371 5.28015C0.380371 7.08446 1.84789 8.55241 3.6516 8.55241H12.3481C13.5426 8.55241 14.5143 9.52469 14.5143 10.7198C14.5143 11.914 13.5426 12.8854 12.3481 12.8854H6.8656C6.56049 12.8854 6.31313 13.1328 6.31313 13.4379C6.31313 13.743 6.56049 13.9904 6.8656 13.9904H12.3481C14.1518 13.9904 15.6193 12.5232 15.6193 10.7198C15.6193 8.91543 14.1518 7.44747 12.3481 7.44747Z"
                    fill="currentColor"
                />
                <path
                    d="M3.57344 9.57629C2.29119 9.57629 1.24805 10.6195 1.24805 11.9017C1.24805 13.8807 3.10377 15.7592 3.18281 15.8382C3.28645 15.9418 3.42693 16 3.57352 16C3.72003 16 3.86058 15.9418 3.96423 15.8382C4.04319 15.7591 5.89884 13.8807 5.89884 11.9017C5.89869 10.6195 4.85562 9.57629 3.57344 9.57629ZM3.57344 14.6116C3.09633 14.0227 2.35299 12.9385 2.35299 11.9017C2.35299 11.2287 2.90053 10.6812 3.57344 10.6812C4.24636 10.6812 4.79375 11.2288 4.79375 11.9017C4.79375 12.9385 4.05041 14.0227 3.57344 14.6116Z"
                    fill="currentColor"
                />
                <path
                    d="M12.3791 6.42357C12.5205 6.42357 12.6618 6.36965 12.7697 6.26188C12.8487 6.18292 14.7054 4.30443 14.7054 2.32532C14.7054 1.04314 13.6618 0 12.3791 0C11.0969 0 10.0537 1.04314 10.0537 2.32525C10.0537 4.30428 11.9094 6.18277 11.9885 6.26173C12.0963 6.36965 12.2377 6.42357 12.3791 6.42357ZM12.3791 1.10495C13.0526 1.10495 13.6004 1.65241 13.6004 2.32525C13.6004 3.36213 12.8564 4.44645 12.3792 5.03532C11.9021 4.44645 11.1587 3.36221 11.1587 2.32525C11.1586 1.65241 11.7061 1.10495 12.3791 1.10495Z"
                    fill="currentColor"
                />
            </>
        </Icon>
    );
};
