import { __ } from './TranslationService';
import { IUrgencyLabel } from '../interfaces/ISearchApi';

export const formatItineraryInfo = (
    numOfNights?: number,
    numOfPorts?: number,
    numOfDaysAtSea?: number,
) => {
    let acc: string[] = [];

    const isRU = 'ночей' === __('Nights', 'dreamlines'); // Yes is unreliable

    if (isRU) {
        if (numOfNights && Number.isInteger(numOfNights) && numOfNights > 0) {
            acc.push(`Ночи: ${numOfNights}`);
        }

        if (numOfPorts && Number.isInteger(numOfPorts) && numOfPorts > 0) {
            acc.push(`Порты: ${numOfPorts}`);
        }
    } else {
        if (numOfNights && Number.isInteger(numOfNights) && numOfNights > 0) {
            acc.push(`${numOfNights} ${__(numOfNights === 1 ? 'Night' : 'Nights', 'dreamlines')}`);
        }

        if (numOfPorts && Number.isInteger(numOfPorts) && numOfPorts > 0) {
            acc.push(`${numOfPorts} ${__(numOfPorts === 1 ? 'Port' : 'Ports', 'dreamlines')}`);
        }

        if (numOfDaysAtSea && Number.isInteger(numOfDaysAtSea) && numOfDaysAtSea > 0) {
            acc.push(
                `${numOfDaysAtSea} ${__(
                    numOfDaysAtSea === 1 ? 'dayAtSea' : 'daysAtSea',
                    'dreamlines',
                )}`,
            );
        }
    }

    return acc.join(', ');
};

export const formatShipTitleArticle = (str: string) => {
    return str?.indexOf('AIDA') !== -1 ? __('with', 'dreamlines') : __('with the', 'dreamlines');
};

export const formatOfferTitle = (
    offerTitle: string,
    startHarbour: string,
    shipName: string,
): string => {
    const replacementMap: any = {
        '[xharbour]': startHarbour,
        '[xship]': shipName,
    };
    return offerTitle.replace(/\[(([A-Za-z0-9#]+\s?))+]/g, function (matched: string) {
        return replacementMap[matched];
    });
};

export const getRatingLabel = (rating: number): string => {
    if (rating) {
        if (rating < 3) {
            return __('Average', 'dreamlines');
        }
        if (rating < 4.5) {
            return __('Ok', 'dreamlines');
        }
        if (rating < 7) {
            return __('Good', 'dreamlines');
        }
        if (rating < 8.5) {
            return __('Very Good', 'dreamlines');
        }
        return __('Excellent', 'dreamlines');
    }
    return '';
};
export const formatUrgencyLabels = (
    urgencyLabels: IUrgencyLabel | IUrgencyLabel[],
    obc?: number,
    isPackageCruise?: boolean,
) => {
    if (obc && !isPackageCruise) {
        const formattedOnBoardCredit: IUrgencyLabel = {
            type: 'OnBoardCredit',
            value: __('up to', 'dreamlines') + ' ' + __('%s $ onboard credit', 'dreamlines', obc),
        };

        return urgencyLabels instanceof Array
            ? urgencyLabels.concat(formattedOnBoardCredit)
            : urgencyLabels instanceof Object
            ? [urgencyLabels, formattedOnBoardCredit]
            : [formattedOnBoardCredit];
    }

    return urgencyLabels instanceof Array
        ? urgencyLabels
        : urgencyLabels instanceof Object
        ? [urgencyLabels]
        : [];
};
