import * as React from 'react';
import styled from '@emotion/styled';
import { __ } from '../../../helpers/TranslationService';
import { getEmailAddress } from '../../../helpers/contactInfoHelpers';
import { ArrowRightIcon } from '../../../components/Icons/ArrowRightIcon';
import { Seal } from '../../../components/Seal/Seal';
import FAQContactItem from './FAQContactItem';
import { RootStateOrAny, useSelector } from 'react-redux';
import { searchResultPage, landingPage, faqPage } from '../../../consts/pageTypes';
interface IProps {
    countryCode: string;
    phoneNumber: string;
    contactPageUrl: string;
}

const FAQContactHeader = styled.div`
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.65;
    text-align: center;
    background-color: ${({ theme }) => theme.background.brandColor2};
    color: ${({ theme }) => theme.text.color.primaryInverse};
`;

const ArrowRightIconStyled = styled(ArrowRightIcon)`
    fill: ${({ theme }) => theme.icon.brandColor1};
    width: 9px;
    height: 12px;
    top: 2px;
    position: relative;
    left: 3px;
`;

const FAQContactLink = styled.a`
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    color: ${({ theme }) => theme.link.primary};
    line-height: 22px;
    &:hover {
        color: ${({ theme }) => theme.link.primaryHover};
        ${ArrowRightIconStyled} {
            fill: ${({ theme }) => theme.link.primaryHover};
        }
    }
`;

export const FAQContact = (props: IProps) => {
    const pageType = useSelector((state: RootStateOrAny) => state.router.pageType);

    const showSeal =
        (pageType === searchResultPage || pageType === landingPage || pageType === faqPage) &&
        props.countryCode === 'de';

    return (
        <div className="faqPage--contact">
            <FAQContactHeader>{__('Get in Contact with us!', 'dreamlines')}</FAQContactHeader>
            <div className="faqPage--contact-data">
                {props.phoneNumber && (
                    <FAQContactItem type="phone">
                        <span className="faqPage--contact-item-title">
                            {__('Call us', 'dreamlines')}
                        </span>
                        <span className="faqPage--contact-item-phNo">{props.phoneNumber}</span>
                    </FAQContactItem>
                )}
                <FAQContactItem type="email">
                    <span className="faqPage--contact-item-title">
                        {__('Write us a mail', 'dreamlines')}
                    </span>
                    <FAQContactLink
                        href={`mailto:${getEmailAddress()}`}
                        className="faqPage--contact-item-anchor"
                    >
                        {__('Write us', 'dreamlines')}
                        <ArrowRightIconStyled />
                    </FAQContactLink>
                </FAQContactItem>
                <FAQContactItem type="person">
                    <span className="faqPage--contact-item-title">
                        {__('Please contact your experts', 'dreamlines')}
                    </span>
                    <FAQContactLink
                        href={props.contactPageUrl}
                        target="_blank"
                        className="faqPage--contact-item-anchor"
                    >
                        {__('Professional, expert advice', 'dreamlines')}
                        <ArrowRightIconStyled />
                    </FAQContactLink>
                </FAQContactItem>
                {showSeal && <Seal pageType={pageType} isFooter={false} />}
            </div>
        </div>
    );
};
