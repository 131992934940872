import * as React from 'react';

interface HomeProps {
    rating: number;
    isWhite?: boolean;
}

export function RatingValue({ rating, isWhite }: HomeProps) {
    const classes = `rating-value ${isWhite ? 'rating-value--white' : ''}`;
    return (
        <div className={classes}>
            <span className="rating-value__rating">
                {rating && rating.toFixed ? rating.toFixed(1) : '-'}
            </span>
            <span>{` / 10`}</span>
        </div>
    );
}
