import * as React from 'react';
import { Icon } from '../../Icon';
import { IIcon } from '../../../interfaces/IIcon';

export function HotelIcon({ width, height, viewBox, style, className }: IIcon) {
    const newViewProps = '0 0 16 17' ?? viewBox;
    return (
        <Icon
            width={width}
            height={height}
            viewBox={newViewProps}
            style={style}
            className={className}
        >
            <g>
                <path d="M14.7189 10.8419V13.3881H16.0002V9.56875H1.28126V4.35204C1.28126 4.18746 1.21876 4.03531 1.09376 3.90179C0.96876 3.76827 0.818758 3.69995 0.640631 3.69995C0.462505 3.69995 0.312503 3.76827 0.187502 3.90179C0.0625006 4.03531 0 4.18746 0 4.35204V13.3881H1.28126V10.8419H14.7189ZM3.36073 6.92934C3.67903 6.92934 3.95716 6.81348 4.19512 6.58487C4.43307 6.35627 4.55051 6.07757 4.55051 5.75502C4.55051 5.43247 4.43307 5.15376 4.19512 4.92516C3.95716 4.69656 3.67903 4.58069 3.36073 4.58069C3.04243 4.58069 2.76739 4.69656 2.54179 4.92516C2.3162 5.15376 2.20186 5.43247 2.20186 5.75502C2.20186 6.07757 2.3162 6.35627 2.54179 6.58487C2.76739 6.81348 3.04243 6.92934 3.36073 6.92934ZM16.0002 7.12778V8.69083H2.79366C2.39809 8.69083 2.20186 8.52827 2.20186 8.20628C2.20186 7.8843 2.39809 7.72174 2.79366 7.72174H5.16086V5.9086C5.16086 5.74292 5.21693 5.60225 5.33217 5.48658C5.44742 5.37092 5.58758 5.31465 5.75266 5.31465H5.81496L15.0657 6.22121C15.3367 6.26185 15.5579 6.36189 15.7354 6.51819C15.9129 6.6745 16.0002 6.87769 16.0002 7.12778Z" />
            </g>
        </Icon>
    );
}
