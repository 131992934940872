import React from 'react';
import ContentLoader from 'react-content-loader';
interface IComponentProps {
    className?: string;
    width?: number;
    height?: number;
    title?: string;
    id?: string;
}
export const ActiveFiltersLoader = (props: IComponentProps) => {
    return (
        <ContentLoader
            speed={2}
            height={36}
            width={370}
            viewBox="0 0 370 36"
            backgroundColor="#fff"
            foregroundColor="#dde7ec"
            uniqueKey="active-filter-loader"
            {...props}
        >
            <rect x="0" y="0" rx="5" ry="5" width="235" height="36" />
            <rect x="251" y="7" rx="3" ry="3" width="117" height="22" />
        </ContentLoader>
    );
};
