import * as React from 'react';
import { Icon } from '../../Icon';
import { IIcon } from '../../../interfaces/IIcon';

export const OutlinedMoonIcon = (props: IIcon) => {
    return (
        <Icon
            width={props.width ?? 16}
            height={props.height ?? 16}
            viewBox={props.viewBox ?? '0 0 16 16'}
            style={props.style}
            className={props.className}
        >
            <path
                d="M15.6232 9.10259C15.4597 9.0617 15.2961 9.10259 15.153 9.20483C14.6213 9.65467 14.0079 10.0227 13.3331 10.2681C12.6993 10.5135 12.0041 10.6361 11.268 10.6361C9.61172 10.6361 8.09862 9.96138 7.01491 8.87767C5.9312 7.79396 5.25643 6.28086 5.25643 4.62463C5.25643 3.92942 5.37912 3.25465 5.58359 2.64123C5.80851 1.98692 6.13567 1.39394 6.56506 0.88276C6.74909 0.657839 6.7082 0.330681 6.48328 0.146655C6.34014 0.0444185 6.17656 0.00352385 6.01299 0.0444185C4.27496 0.514708 2.76186 1.55752 1.67815 2.94794C0.635333 4.31791 0.00146484 6.01504 0.00146484 7.87575C0.00146484 10.1045 0.901148 12.1288 2.37336 13.601C3.84557 15.0732 5.86985 15.9729 8.09862 15.9729C9.97977 15.9729 11.7178 15.3186 13.1082 14.2349C14.5191 13.1307 15.5415 11.5563 15.9708 9.75691C16.0731 9.4502 15.9095 9.16394 15.6232 9.10259ZM12.4948 13.3761C11.3088 14.3167 9.79575 14.8892 8.13951 14.8892C6.19701 14.8892 4.43854 14.0917 3.1708 12.824C1.90307 11.5563 1.10562 9.79781 1.10562 7.85531C1.10562 6.23997 1.63725 4.76776 2.55738 3.58181C3.19125 2.76392 3.9887 2.08915 4.90883 1.61886C4.80659 1.84379 4.70436 2.06871 4.62257 2.31407C4.35675 3.05018 4.23407 3.82718 4.23407 4.64507C4.23407 6.58757 5.03151 8.36649 6.29925 9.63423C7.56698 10.902 9.3459 11.6994 11.2884 11.6994C12.1472 11.6994 12.9651 11.5563 13.7216 11.27C13.9875 11.1678 14.2533 11.0655 14.4986 10.9429C14.0079 11.8834 13.3331 12.7218 12.4948 13.3761Z"
                fill="currentColor"
            />
        </Icon>
    );
};
