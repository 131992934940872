import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { MarkAsFavorite } from '../../components/MarkAsFavorite';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { useActions } from '../../hooks/useActions';
import { __ } from '../../helpers/TranslationService';
import { ShipIcon } from '../Icons/ShipIcon';

export type CardHeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
interface Card {
    cruiseNid?: number;
    imageUrl: string;
    companyLogoUrl?: string;
    title: string;
    titleVariant?: CardHeadingVariant;
    price?: string;
    withFlight?: boolean;
    currency?: string;
    url?: string;
    description?: string | ReactNode;
    features?: string[];
    discount?: string | number;
    handleClick?: () => void;
    shipTitle?: string;
}

const CardStyled = styled.div`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 274px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`;

const CardInfo = styled.div`
    display: flex;
    flex: 2 0 auto;
    flex-direction: column;
    padding: 16px;
`;

const CardImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    flex: 0 1 180px;
    > img {
        width: 100%;
        height: 100%;
    }
    @media (min-width: ${BreakpointsEnum.md}) {
        height: 220px;
        flex: 0 1 220px;
    }
`;

const CardCompanyLogo = styled.div`
    > img {
        max-width: 80px;
        max-height: 40px;
    }
`;

const CardFavoriteButton = styled.div`
    position: absolute;
    width: 34px;
    height: 34px;
    top: 11px;
    right: 11px;
`;

const CardTitle = styled.h3`
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin: 0 0 8px;
    color: ${({ theme }) => theme.colors.black[100]};
`;

const CardTitleLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black[100]};
    &:hover {
        color: ${({ theme }) => theme.colors.black[100]};
        text-decoration: none;
    }
`;

const CardDescription = styled.div`
    color: ${({ theme }) => theme.colors.black[100]};
    font-size: 14px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const CardPrice = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black[100]};
`;

const CardPriceSum = styled.span`
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
`;

const CardPriceSubText = styled.div`
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gunmetal[70]};
`;

const CardPriceWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 16px 16px;
    justify-content: space-between;
`;

const CardPriceDiscount = styled.div`
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.watermelon[100]};
`;

const FeatureList = styled.ul`
    padding: 0;
    margin: 8px 10px 10px 0;
`;

const FeatureListItem = styled.li`
    color: ${({ theme }) => theme.colors.black[100]};
    font-size: 14px;
    line-height: 22px;
    position: relative;
    padding-left: 22px;
    margin-top: 4px;
    overflow: hidden;
    @media (max-width: ${BreakpointsEnum.md}) {
        white-space: pre-wrap;
    }
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 6px;
        margin-top: -6px;
        border-style: solid;
        border-width: 0 0 3px 3px;
        border-color: ${({ theme }) => theme.icon.brandColor2};
        transform: rotate(-45deg);
    }
`;

const ShipTitle = styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 0 8px;
`;

const StyledShipIcon = styled(ShipIcon)`
    height: 14px;
    width: 14px;
`;

export const Card = (props: Card) => {
    const {
        cruiseNid,
        imageUrl,
        companyLogoUrl,
        title,
        titleVariant,
        price,
        withFlight,
        description,
        discount,
        url,
        features,
        handleClick,
        shipTitle,
    } = props;
    const featuresList = features?.length > 0 ? features : null;
    const { addToFavorites, removeFromFavorites } = useActions();

    const favoritesData = useSelector((state: RootStateOrAny) => state.favoritePage);
    const onCardClicked = () => {
        handleClick?.();
        if (url) {
            window.location.href = url;
        }
    };

    const shouldShowFavoriteBtn =
        !!cruiseNid && !!favoritesData && !!addToFavorites && !!removeFromFavorites;

    return (
        <CardStyled onClick={onCardClicked}>
            <CardImage>
                {shouldShowFavoriteBtn && (
                    <CardFavoriteButton>
                        <MarkAsFavorite dropShadow="none" cruiseNid={cruiseNid} />
                    </CardFavoriteButton>
                )}
                <img src={imageUrl} loading="lazy" alt={title} />
            </CardImage>
            <CardInfo>
                <CardTitle as={titleVariant}>
                    <CardTitleLink href={url}>{title}</CardTitleLink>
                </CardTitle>
                {description && <CardDescription>{description}</CardDescription>}
                {shipTitle && (
                    <ShipTitle>
                        <StyledShipIcon />
                        {shipTitle}
                    </ShipTitle>
                )}
                {featuresList && (
                    <FeatureList>
                        {featuresList.map((item) => (
                            <FeatureListItem key={item} title={item}>
                                {item}
                            </FeatureListItem>
                        ))}
                    </FeatureList>
                )}
            </CardInfo>
            {!!price && (
                <CardPriceWrapper>
                    <CardPrice>
                        {!!discount && (
                            <CardPriceDiscount>
                                {__('Reduced up to %s', 'dreamlines', `-${discount}%`)}
                            </CardPriceDiscount>
                        )}
                        {__('from', 'dreamlines')} <CardPriceSum>{price}</CardPriceSum>{' '}
                        {__('p.P.', 'dreamlines')}
                        {withFlight && (
                            <CardPriceSubText>
                                {__('Including flight', 'dreamlines')}
                            </CardPriceSubText>
                        )}
                    </CardPrice>
                    {companyLogoUrl && (
                        <CardCompanyLogo>
                            <img src={companyLogoUrl} loading="lazy" alt="Company logo" />
                        </CardCompanyLogo>
                    )}
                </CardPriceWrapper>
            )}
        </CardStyled>
    );
};
