import * as React from 'react';
import { __ } from '../../helpers/TranslationService';

interface HomeProps {
    constellation: string;
    atmosphere: string[];
}

export function ReviewTags({ constellation, atmosphere }: HomeProps) {
    const getTags = () =>
        [constellation]
            .concat(atmosphere)
            // we are filtering negative tags
            .filter(
                (tag) =>
                    ['stressful', 'chaotic', 'impersonal', 'loud', 'boring'].indexOf(tag) === -1,
            )
            .map((tag) => (
                <span className="review-tags__tag" key={tag}>
                    {__(tag, 'dreamlines')}
                </span>
            ));
    return <div className="review-tags">{getTags()}</div>;
}
