import styled from '@emotion/styled';

export const SeoText = styled.div`
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 80px;
    margin: 40px 16px 60px 16px;
    color: ${({ theme }) => theme.text.color.primary};

    & h4,
    & h3 {
        text-align: center;
    }

    & a {
        text-decoration: underline;
        color: ${({ theme }) => theme.link.primary};
    }
`;
