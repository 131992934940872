import React from 'react';
import { __ } from '../../helpers/TranslationService';
import { LabelledArrow } from '../LabelledArrow/LabelledArrow';

interface IDiscountProps {
    amount: number;
    color?: string;
    textColor?: string;
    left?: boolean;
    right?: boolean;
    showUpToText?: boolean;
    size?: Size;
}

interface ISizeConfig {
    labelSize: any;
    amountSize: any;
    height: number;
}
export type Size = 'S' | 'M' | 'L';

const sizes: Record<Size, ISizeConfig> = {
    S: {
        labelSize: '9px',
        amountSize: '11px',
        height: 24,
    },
    M: {
        labelSize: '11px',
        amountSize: '14px',
        height: 30,
    },
    L: {
        labelSize: '11px',
        amountSize: '16px',
        height: 38,
    },
};

const containerWidths = {
    L: 48,
    M: 40,
    S: 30,
};

export const Discount = ({
    amount,
    color = '#e01a59',
    textColor = 'white',
    left,
    right = true,
    showUpToText = false,
    size = 'S',
}: IDiscountProps) => {
    return (
        <div
            className="discountContainer"
            style={{ width: containerWidths[size], marginRight: '10px' }}
        >
            <LabelledArrow
                heightBox={sizes[size].height}
                backgroundColor={color}
                left={left}
                right={right}
            >
                <div className="discountArrowContent" style={{ color: textColor || 'white' }}>
                    {showUpToText && (
                        <div className="discountLabel" style={{ fontSize: sizes[size].labelSize }}>
                            {__('upTo', 'dreamlines')}
                        </div>
                    )}
                    <div className="discountDiscount" style={{ fontSize: sizes[size].amountSize }}>
                        {`-${amount}%`}
                    </div>
                </div>
            </LabelledArrow>
        </div>
    );
};
