import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import { ISearchReducer } from '../../reducers/search';
import { SelectDropdown } from './SelectDropdown';
import { transformOptions } from '../../helpers/searchHelpers';

const styleSelect: any = {
    marginTop: '10px',
    fontSize: '15px',
};

interface IProps {
    search: ISearchReducer;
    banderoles: string;
    onBanderoleChange(param: any): void;
    autoSetFocus?: boolean;
}

export const ThemeCruises = (props: IProps) => {
    const defaultThemeCruiseOption = { value: 'default', label: __('All Themes', 'dreamlines') };
    const { banderoles } = props.search.searchFacets;
    return (
        <div style={styleSelect}>
            <SelectDropdown
                nids={transformOptions(defaultThemeCruiseOption, banderoles)}
                onChange={props.onBanderoleChange}
                value={props.banderoles}
                instanceId="theme_cruises"
                searchable
                autoSetFocus={props.autoSetFocus}
            />
        </div>
    );
};
