import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export const DrinksIcon = (props: IIcon) => {
    return (
        <Icon
            width={props.width ?? 12}
            height={props.height ?? 12}
            viewBox={props.viewBox ?? '0 0 12 12'}
            style={props.style}
            className={props.className}
        >
            <path
                d="M10.7142 2.35719C10.7117 1.75235 10.4767 1.17164 10.0579 0.735213C9.63915 0.298783 9.06863 0.0400374 8.4644 0.0125103C7.86018 -0.0150168 7.2685 0.190782 6.81177 0.587325C6.35505 0.983869 6.06824 1.54081 6.0107 2.14291H4.55356L4.27928 0.771503C4.26233 0.688856 4.22137 0.61305 4.16152 0.553585C4.10168 0.49412 4.02561 0.453641 3.94285 0.437222L1.80001 0.008657C1.74486 -0.002599 1.68803 -0.00288129 1.63276 0.00782619C1.5775 0.0185337 1.52489 0.0400212 1.47793 0.071062C1.38309 0.133752 1.31704 0.231548 1.2943 0.342938C1.27157 0.454327 1.29402 0.570185 1.35671 0.665023C1.4194 0.759861 1.5172 0.825912 1.62859 0.848645L3.49114 1.2215L3.67714 2.14291H3C2.88634 2.14291 2.77733 2.18806 2.69696 2.26844C2.61659 2.34881 2.57144 2.45781 2.57144 2.57148V10.7142C2.57245 11.0549 2.70824 11.3813 2.94913 11.6222C3.19003 11.8631 3.51646 11.9989 3.85714 11.9999H7.28569C7.62636 11.9989 7.9528 11.8631 8.19369 11.6222C8.43459 11.3813 8.57037 11.0549 8.57139 10.7142V4.70359C9.15662 4.64958 9.70064 4.37912 10.097 3.94514C10.4933 3.51117 10.7134 2.94491 10.7142 2.35719ZM3.42857 6V3.00004H3.84857L4.44857 6H3.42857ZM7.71426 6H5.32284L4.72285 3.00004H7.71426V6ZM8.57139 3.83531V2.57148C8.57139 2.45781 8.52624 2.34881 8.44587 2.26844C8.3655 2.18806 8.25649 2.14291 8.14282 2.14291H6.87898C6.91813 1.86768 7.03317 1.6088 7.21121 1.39528C7.38924 1.18176 7.62324 1.02206 7.88695 0.93407C8.15066 0.846081 8.43367 0.833284 8.70424 0.897116C8.97482 0.960948 9.22227 1.09888 9.41885 1.29546C9.61543 1.49204 9.75336 1.73949 9.8172 2.01006C9.88103 2.28063 9.86823 2.56364 9.78024 2.82735C9.69225 3.09106 9.53255 3.32505 9.31903 3.50308C9.10551 3.68112 8.84662 3.79616 8.57139 3.83531Z"
                fill="currentColor"
            />
        </Icon>
    );
};
