import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function ShipSizeSIcon({ width, height, viewBox = '0 0 512 256', style, className }: IIcon) {
    return (
        <Icon width={width} height={height} viewBox={viewBox} style={style} className={className}>
            <path
                id="shipsizeS"
                d="M424.4,154.1c-1-1.8-2.8-2.9-4.9-2.9h-53.3l-15.5-23.7l0,0l-5.7-8.8c-8-12.2-21.6-19.6-36.2-19.6h-21.6l-11.7-22.6c-1.3-2.8-4.6-4-7.4-2.7c-2.8,1.3-4,4.6-2.7,7.4c0,0.1,0.1,0.2,0.1,0.3l9.3,17.7h-20c-1.5,0-2.9,0.6-3.9,1.6l-7.8,7.8h-25.3l-7.8-7.8c-1-1-2.4-1.6-3.9-1.6h-19.7l-8.1-21c-0.6-2.5-2.8-4.2-5.4-4.2h-28.2c-3,0-5.5,2.5-5.5,5.5L139.1,99h-5.7c-6.1,0-11.3,4.3-12.6,10.2l-5.3,24.4v0.1l-4.1,19.5c-0.4,2.1-0.5,4.2-0.3,6.3l6.9,67.1c0.3,2.8,2.7,4.9,5.5,4.9h5.9v3.9c0,3.1,2.5,5.5,5.6,5.5c0,0,0,0,0,0h237.8c3,0,5.5-2.5,5.5-5.5c0-5,1.5-10,4.3-14.1l41.6-61.5C425.3,158,425.3,155.9,424.4,154.1zM373.4,215.1c-3,4.4-5,9.5-5.8,14.8H140.4V226c0-3-2.5-5.5-5.5-5.5h-6.5l-3.1-30h247.3c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5H124.2l-1.8-17.2h179.6c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5h-179l2.3-10.8h75.5c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5h-73.1l2.8-13.2l-1.8,8.8l2.8-13.4c0.2-0.8,0.9-1.4,1.8-1.5h30c3-0.3,5.3-2.9,5-6c-0.2-2.7-2.4-4.8-5-5H150l0.2-14.1h18.4l8.1,21v0.1c0.1,0.3,0.2,0.6,0.3,0.8c0,0,0,0.1,0.1,0.1h0c0.1,0.2,0.3,0.5,0.4,0.7c0,0,0,0.1,0.1,0.1h0c0.1,0.2,0.3,0.4,0.5,0.6l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0,0.1,0.1,0.1,0.1c0.4,0.3,0.9,0.6,1.4,0.8c0.1,0,0.1,0,0.1,0.1l0.7,0.2h0.1c0.2,0.1,0.5,0.1,0.7,0.1h21.8l7.8,7.8c1,1,2.4,1.6,3.9,1.6h29.8c1.5,0,2.9-0.6,3.9-1.6l7.8-7.8h51.6c10.9,0,21,5.5,27,14.6l0.2,0.3l2.8,4.4h-41.5c-3,0.3-5.3,2.9-5,6c0.2,2.7,2.4,4.8,5,5H346l7,10.8h-27.5c-3-0.3-5.7,2-6,5c-0.3,3,2,5.7,5,6c0.3,0,0.6,0,0.9,0H409L373.4,215.1zM274.5,134.9c0,3-2.5,5.5-5.5,5.5h-39.9c-3-0.3-5.3-2.9-5-6c0.2-2.7,2.4-4.8,5-5H269C272,129.4,274.5,131.8,274.5,134.9C274.5,134.9,274.5,134.9,274.5,134.9L274.5,134.9z"
            />
        </Icon>
    );
}
