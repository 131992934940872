import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function PackageLabelIcon({ width, height, viewBox, style, className }: IIcon) {
    const newViewProps = '0 0 20 20' ?? viewBox;
    return (
        <Icon
            width={width}
            height={height}
            viewBox={newViewProps}
            style={style}
            className={className}
        >
            <g>
                <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.9231 4.61537V16.9231H4.61537C3.7657 16.9231 3.0769 17.6119 3.0769 18.4615C3.0769 19.3112 3.7657 20 4.61537 20L16.9231 20H18.4615C19.3112 20 20 19.3112 20 18.4615V16.9231V4.61537C20 3.7657 19.3112 3.0769 18.4615 3.0769C17.6119 3.0769 16.9231 3.7657 16.9231 4.61537Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.53846 0C0.688793 0 0 0.688793 0 1.53846V13.0769C0 13.9266 0.688793 14.6154 1.53846 14.6154H14.6154V1.53846C14.6154 0.688793 13.9266 0 13.0769 0H1.53846ZM6.34615 4.03846C6.34615 3.50742 6.77665 3.07692 7.30769 3.07692C7.83874 3.07692 8.26923 3.50742 8.26923 4.03846V6.34615H10.5769C11.108 6.34615 11.5385 6.77665 11.5385 7.30769C11.5385 7.83874 11.108 8.26923 10.5769 8.26923H8.26923V10.5769C8.26923 11.108 7.83874 11.5385 7.30769 11.5385C6.77665 11.5385 6.34615 11.108 6.34615 10.5769V8.26923H4.03846C3.50742 8.26923 3.07692 7.83874 3.07692 7.30769C3.07692 6.77665 3.50742 6.34615 4.03846 6.34615H6.34615V4.03846Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    );
}
