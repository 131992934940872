import * as React from 'react';
import * as Colors from '../../consts/variables';
import { TestSelector } from '../../helpers/TestSelector';
import { __ } from '../../helpers/TranslationService';
import { RangeSlider } from '../RangeSlider/RangeSlider';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        margin: '15px 0',
        padding: '15px',
        background: Colors.white,
        borderRadius: '6px',
    },
    heading: {
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '10px',
        fontSize: '20px',
    },
    priceText: {
        fontWeight: 'bold',
        fontSize: 15,
    },
    itemStyle: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        display: 'inline-block',
        verticalAlign: 'top',
        marginRight: '8px',
        marginTop: 0,
        height: '21.1px',
        width: '22px',
    },
    label: {
        display: 'inline-block',
        verticalAlign: 'top',
        whiteSpace: 'normal',
        fontSize: 15,
        margin: 0,
    },
};

interface IProps {
    nightsMax: number;
    nightsMin: number;
    onDurationChange(param: any): void;
    maxNightsRange: number;
    isMobile?: boolean;
}

export const DurationFilter: React.FC<IProps> = ({
    onDurationChange,
    nightsMax,
    nightsMin,
    maxNightsRange,
    isMobile = false,
}) => {
    const [maxValue] = React.useState(maxNightsRange);
    const [value, setValue] = React.useState([nightsMin, nightsMax]);

    const onSliderChange = (rangeValue: number[]) => {
        setValue(rangeValue);
    };
    const onAfterChange = (rangeValue: number[]) => {
        onDurationChange(rangeValue);
    };

    React.useEffect(() => {
        setValue([nightsMin, nightsMax]);
    }, [nightsMax, nightsMin]);

    const getNightsString = (val) =>
        val > 1 ? __('Nights', 'dreamlines') : __('Night', 'dreamlines');

    const [minNightsValue, maxNightsValue] = value;

    const rangeSliderOptions = {
        min: 0,
        max: maxValue,
        value: value,
        onChange: onSliderChange,
        onAfterChange: onAfterChange,
        suffixMin: getNightsString(minNightsValue),
        suffixMax: getNightsString(maxNightsValue),
        isMobile,
    };

    return (
        <div style={style.wrapper}>
            <div style={style.heading}>
                <span>{__('duration', 'dreamlines')}</span>
            </div>
            <div>
                <div
                    style={style.rangeSlider}
                    {...TestSelector.getSelector('searchFilterChangePrice')}
                >
                    <RangeSlider {...rangeSliderOptions} />
                </div>
            </div>
        </div>
    );
};

export default DurationFilter;
