import * as React from 'react';
import { Icon } from '../../Icon';
import { IIcon } from '../../../interfaces/IIcon';

export const OutlinedCalendarIcon = (props: IIcon) => {
    return (
        <Icon
            width={props.width ?? 16}
            height={props.height ?? 16}
            viewBox={props.viewBox ?? '0 0 16 16'}
            style={props.style}
            className={props.className}
        >
            <path
                d="M0.468788 13.1563H2.81253V14.5625C2.81253 14.8214 3.02241 15.0313 3.28128 15.0313H15.5313C15.7901 15.0313 16 14.8214 16 14.5625V2.37506C16 2.11618 15.7901 1.90631 15.5313 1.90631H13.1875V1.43756C13.1875 1.17869 12.9776 0.968811 12.7188 0.968811C12.4599 0.968811 12.25 1.17869 12.25 1.43756V1.90631H9.87502V1.43756C9.87502 1.17869 9.66514 0.968811 9.40627 0.968811C9.1474 0.968811 8.93752 1.17869 8.93752 1.43756V1.90631H6.59378V1.43756C6.59378 1.17869 6.3839 0.968811 6.12503 0.968811C5.86615 0.968811 5.65628 1.17869 5.65628 1.43756V1.90631H3.28128C3.02241 1.90631 2.81253 2.11618 2.81253 2.37506V5.18755C2.81253 8.64223 1.37676 11.2999 0.168695 12.3274C0.0170703 12.4538 -0.0391483 12.6615 0.0280078 12.8471C0.0952264 13.0327 0.271414 13.1563 0.468788 13.1563ZM15.0625 14.0938H3.75003V13.1563H12.7188C12.8284 13.1563 12.9346 13.1178 13.0189 13.0476C13.5824 12.5783 14.4552 11.4609 15.0625 10.0297V14.0938ZM3.75003 2.84381H5.65628V3.31256C5.65628 3.57143 5.86615 3.78131 6.12503 3.78131C6.3839 3.78131 6.59378 3.57143 6.59378 3.31256V2.84381H8.93752V3.31256C8.93752 3.57143 9.1474 3.78131 9.40627 3.78131C9.66514 3.78131 9.87502 3.57143 9.87502 3.31256V2.84381H12.25V3.31256C12.25 3.57143 12.4599 3.78131 12.7188 3.78131C12.9776 3.78131 13.1875 3.57143 13.1875 3.31256V2.84381H15.0625V4.7188H3.75003V2.84381ZM3.742 5.65599H15.0542C14.9486 8.62958 13.8073 10.9692 12.535 12.2188H1.535C2.97666 10.3819 3.66466 7.99011 3.742 5.65599Z"
                fill="currentColor"
            />
        </Icon>
    );
};
