import React from 'react';

type PaginationItemType = 'leftArrow' | 'rightArrow' | 'dots' | 'item' | 'highlight';

interface IProps {
    type: PaginationItemType;
}

export const PaginationItem = ({ type }: IProps) => {
    return <div className={`paginationItem ${type}`}></div>;
};
