import * as React from 'react';
import styled from '@emotion/styled';
import { SearchMask } from '../../components/SearchFilters/SearchMask';
import { ZoneAndCompanyInputSelection } from '../../components/SearchFilters/ZoneAndCompanyInputSelection';
import * as Colors from '../../consts/variables';
import { deepClone } from '../../helpers/deepClone';
import { TestSelector } from '../../helpers/TestSelector';
import { __ } from '../../helpers/TranslationService';
import { IPageDataReducer } from '../../reducers/pageData';
import { ISearchReducer } from '../../reducers/search';
import { DurationSelect } from './DurationSelect';
import { FilterFooter } from './FilterFooter';
import { ResetButton } from './ResetButton';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        background: Colors.blue,
        padding: '15px 0',
    },
    checkboxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        color: Colors.white,
        fontSize: '15px',
        padding: '0 15px 10px',
    },
    checkboxGroup: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    separatorLine: {
        background: Colors.white,
        width: '1px',
        height: '21.1px',
        marginRight: '10px',
        flex: '1 0 auto',
    },
    itemStyle: {
        whiteSpace: 'nowrap',
        display: 'flex',
    },
    input: {
        display: 'inline-block',
        verticalAlign: 'top',
        margin: '0px 6px 0 0',
        flex: '1 0 auto',
        height: '21.1px',
        width: '22px',
    },
    label: {
        whiteSpace: 'normal',
        display: 'flex',
        alignItems: 'center',
    },
};

const itemSpacing: any = {
    paddingRight: '10px',
};

interface StateProps {
    search: ISearchReducer;
    pageData: IPageDataReducer;
}

interface DispatchProps {
    setFilterParam: (ICruiseFilterParamValue) => () => any;
}

interface OwnProps {
    dateMax: number;
    dateMin: number;
    zone: string;
    company: string;
    inclFlight: boolean;
    shipType: number[];
    nightsMin: number;
    nightsMax: number;
    onCompanyChange(param: any): void;
    onZoneChange(param: any): void;
    onDateMinChange(param: any): void;
    onDateMaxChange(param: any): void;
    onFlightChange(param: any): void;
    onShipTypeChange(param: any): void;
    onDateChange(param: any[]): void;
    onDurationChange(param: any): void;
    clearFilterParams(param: any): void;
    deviceType?: string;
    footerProps?: any;
    forceDirection?: 'up' | 'down';
    autoSetFocus?: boolean;
}

type IProps = StateProps & DispatchProps & OwnProps;

const FilterInputWrapper = styled.div`
    background: ${({ theme }) => theme.background.brandColor2};
    padding: 15px 0;
    border-radius: ${({ theme }) => theme.container.borderRadius};
`;
export class FilterInputForm extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.handleShipTypeCheckboxChange = this.handleShipTypeCheckboxChange.bind(this);
        this.handleFlightCheckboxChange = this.handleFlightCheckboxChange.bind(this);
    }

    handleFlightCheckboxChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.props.onFlightChange(value ? true : false);
    };

    handleShipTypeCheckboxChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let shipType = [];
        const shipTypesPropCopy = deepClone(this.props.pageData.brandConfig.shipType);

        if (target.id === 'sea') {
            if (value) {
                shipType = shipTypesPropCopy.sea;
            }
            shipTypesPropCopy.river.forEach((riverShipType) => {
                if (
                    this.props.shipType.indexOf(riverShipType) !== -1 &&
                    shipType.indexOf(riverShipType) === -1
                ) {
                    shipType.push(riverShipType);
                }
            });
        }

        if (target.id === 'river') {
            if (value) {
                shipType = shipTypesPropCopy.river;
            }
            shipTypesPropCopy.sea.forEach((seaShipType) => {
                if (
                    this.props.shipType.indexOf(seaShipType) !== -1 &&
                    shipType.indexOf(seaShipType) === -1
                ) {
                    shipType.push(seaShipType);
                }
            });
        }

        this.props.onShipTypeChange(shipType);
    };

    render() {
        const { zoneNids, companyNids, topCompanies, topZones } = this.props.search.searchFacets;
        const { deviceType } = this.props;
        const zoneAndCompanyInputProps = {
            zoneNids,
            companyNids,
            topCompanies,
            topZones,
            onZoneChange: this.props.onZoneChange,
            zone: this.props.zone,
            onCompanyChange: this.props.onCompanyChange,
            company: this.props.company,
            noPaddingTop: true,
            forceDirection: this.props.forceDirection,
            autoSetFocus: this.props.autoSetFocus,
        };
        const numberOfResults = this.props.search.searchFacets.numResults;
        const disableSearchButton = !!numberOfResults;
        const loadingSearchButton = !!numberOfResults && numberOfResults !== 0;
        const filterInputProps = {
            pageType: 'Search Results',
            dateMin: this.props.dateMin,
            dateMax: this.props.dateMax,
            onDateMinChange: this.props.onDateMinChange,
            onDateMaxChange: this.props.onDateMaxChange,
            onDateChange: this.props.onDateChange,
            isLoading: loadingSearchButton,
            sticky: false,
            showSearchBtn: false,
            isDisabled: disableSearchButton,
            noPaddingTop: true,
            languageCode: this.props.pageData.appConfig.languageCode,
            countryCode: this.props.pageData.appConfig.countryCode,
        };

        return (
            <FilterInputWrapper>
                <div>
                    <div style={style.checkboxRow}>
                        <div style={style.checkboxGroup}>
                            <div style={{ ...style.itemStyle, ...itemSpacing }}>
                                <input
                                    style={style.input}
                                    id="river"
                                    name="shipType"
                                    type="checkbox"
                                    onChange={this.handleShipTypeCheckboxChange}
                                    checked={
                                        this.props.shipType.indexOf(
                                            this.props.pageData.brandConfig.shipType.river[0],
                                        ) !== -1
                                    }
                                />
                                <label style={style.label} htmlFor="river">
                                    {__('River', 'dreamlines')}
                                </label>
                            </div>
                            <div style={{ ...style.itemStyle, ...itemSpacing }}>
                                <input
                                    style={style.input}
                                    id="sea"
                                    name="shipType"
                                    type="checkbox"
                                    onChange={this.handleShipTypeCheckboxChange}
                                    checked={
                                        this.props.shipType.indexOf(
                                            this.props.pageData.brandConfig.shipType.sea[0],
                                        ) !== -1
                                    }
                                />
                                <label style={style.label} htmlFor="sea">
                                    {__('all sea', 'dreamlines')}
                                </label>
                            </div>
                        </div>
                        <div
                            style={style.checkboxGroup}
                            {...TestSelector.getSelector('searchFilterIncFlight')}
                        >
                            <div style={style.separatorLine} />
                            <div style={style.checkboxGroup}>
                                <input
                                    style={style.input}
                                    id="inclFlight"
                                    name="inclFlight"
                                    type="checkbox"
                                    onChange={this.handleFlightCheckboxChange}
                                    checked={this.props.inclFlight}
                                />
                                <label style={style.label} htmlFor="inclFlight">
                                    {__('with Flight', 'dreamlines')}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <SearchMask {...filterInputProps} pageData={this.props.pageData}>
                    <ZoneAndCompanyInputSelection {...zoneAndCompanyInputProps} />
                </SearchMask>

                {deviceType === 'desktop' && (
                    <>
                        <ResetButton variant="light" onClick={this.props.clearFilterParams} />
                    </>
                )}
            </FilterInputWrapper>
        );
    }
}
