import * as React from 'react';
import { RatingValue } from './RatingValue';
import { ReviewTags } from './ReviewTags';
import { ReviewComment } from './ReviewComment';
import { IReviewRating } from '../../interfaces/IReviews';
import { __ } from '../../helpers/TranslationService';
import { formatCruiseTitle } from '../../helpers/reviews';

interface HomeProps {
    review: IReviewRating;
}
export class Review extends React.Component<HomeProps, any> {
    constructor(props) {
        super(props);
        this.state = { isMobileBodyShown: null };
    }
    render() {
        const { review } = this.props;
        const {
            positivePointsComment,
            negativePointsComment,
            constellation,
            atmosphere,
            cruise,
            company,
            cabinComment,
            cabinType,
        } = review.attributes;
        const shipReviewTagsProps = { constellation, atmosphere };
        const isCommentsExists = positivePointsComment || negativePointsComment;
        const bodyStyle = {
            display:
                this.state.isMobileBodyShown !== null && this.state.isMobileBodyShown
                    ? 'block'
                    : undefined,
        };
        // some ugc cruise titles have following format 234234 - cruise name.
        // This replacement replace number at the begining
        let cruiseTitle =
            cruise && cruise.indexOf(' - ') !== -1
                ? cruise.slice(cruise.indexOf(' - ') + 3, cruise.length)
                : cruise;

        cruiseTitle =
            cruiseTitle && cruiseTitle !== 'N\\A'
                ? cruiseTitle === 'Other'
                    ? __('Other', 'dreamlines')
                    : cruiseTitle
                : '';

        cruiseTitle = formatCruiseTitle(cruiseTitle, company);

        const ageRange =
            review.attributes.ageRange && review.attributes.ageRange !== 'unknown'
                ? __(
                      `${review.attributes.ageRange
                          .replace('-', ' - ')
                          .replace('> ', '>')
                          .replace('44 - 59', '45 - 59')} years`,
                      'dreamlines',
                  )
                : '';

        const ReviewBody = () => {
            return (
                <div className="review__body" style={bodyStyle}>
                    <div className="review__cruise-and-tags">
                        <div className="review__cruise">{cruiseTitle}</div>
                        <ReviewTags {...shipReviewTagsProps} />
                    </div>
                    <ReviewComment comment={positivePointsComment} icon="plus" />
                    <ReviewComment comment={negativePointsComment} icon="minus" />
                    {cabinComment && (
                        <div className="review__desktop-only">
                            <hr className="review__separator" />
                            <ReviewComment
                                heading={cabinType}
                                comment={cabinComment}
                                icon="cabin"
                            />
                        </div>
                    )}
                </div>
            );
        };

        const toggleClickHandler = () =>
            isCommentsExists &&
            this.setState((prev) => ({ isMobileBodyShown: !prev.isMobileBodyShown }));

        const MobileHeader = () => {
            const arrowClasses = `review__mobile-header-arrow ${
                this.state.isMobileBodyShown ? 'icon-arrowTop' : 'icon-arrowDown'
            }`;
            const scoreClasses = `review__score ${
                isCommentsExists ? 'review__score--with-comments' : ''
            }`;
            const created = new Date(review.meta.created);
            return (
                <div className="review__mobile-header" onClick={toggleClickHandler}>
                    <div className="review__header-left">
                        <div className="review__personal-info">
                            <span className="icon-pegasus_user review__user-icon" />
                            <span className="review__age">{ageRange}</span>
                        </div>
                    </div>
                    <div className={scoreClasses}>
                        <RatingValue rating={review.attributes.overallRating} />
                        {isCommentsExists && <span className={arrowClasses} />}
                    </div>
                </div>
            );
        };
        const DesktopHeader = () => {
            // ages replacing is required for translation compatibilty between
            // UGC ages groups and drupal reviews ages groups

            const created = new Date(review.meta.created);

            return (
                <div className="review__desktop-header">
                    <div className="review__desktop-header-rating">
                        <RatingValue rating={review.attributes.overallRating} />
                    </div>
                    <div className="review__desktop-header-icon">
                        <span className="icon-pegasus_user review__user-icon" />
                    </div>
                    <div className="review__desktop-header-name">
                        {(review.attributes as any).name}
                    </div>
                    {ageRange}
                </div>
            );
        };
        return (
            <div className="review">
                <div className="review__wrapper">
                    <MobileHeader />
                    <DesktopHeader />
                    <ReviewBody />
                </div>
            </div>
        );
    }
}
