import * as React from 'react';
import * as Colors from '../consts/variables';
import { __ } from '../helpers/TranslationService';
import { ArrowDownIcon } from './Icons/ArrowDownIcon';

interface ILoadMoreButtonProps {
    onClick: () => void;
}

const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        padding: 15,
        paddingTop: 0,
        background: Colors.lightGray,
    },
    button: {
        background: Colors.acqa,
        color: Colors.white,
        minWidth: 140,
        textAlign: 'center',
        padding: '15px 5px',
        borderRadius: 5,
        position: 'relative',
        fontSize: 20,
    },
    arrowRight: {
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: 'translate(0%, -50%)',
        fill: Colors.white,
    },
};

export const LoadMoreButtonWrapper = (props: ILoadMoreButtonProps) => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.button} onClick={props.onClick}>
                {__('load more', 'dreamlines')}
                <ArrowDownIcon style={styles.arrowRight} width={20} height={20} />
            </div>
        </div>
    );
};
