import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function FreeWiFiIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <path d="M22.9518 7.42359L23.5343 8.00608C24.1572 8.62902 24.1549 9.64377 23.5296 10.2713C22.9032 10.8977 21.8873 10.9001 21.2644 10.2771L20.4369 9.44845C15.7735 5.04276 8.43459 5.07049 3.80818 9.53975C3.80188 9.545 3.79654 9.55215 3.79042 9.56034C3.7898 9.56117 3.78917 9.56201 3.78853 9.56286L2.73681 10.6134C2.11271 11.2387 1.09913 11.2341 0.472726 10.6077C-0.154838 9.98009 -0.158305 8.9665 0.465792 8.34356L1.51751 7.29068C1.53274 7.27545 1.549 7.26336 1.56517 7.25134C1.57846 7.24147 1.59168 7.23165 1.60419 7.22018C7.32508 1.71653 16.3132 1.55704 22.2225 6.74517C22.315 6.80526 22.4028 6.87461 22.4837 6.95435L22.9437 7.41665C22.947 7.41748 22.9485 7.41951 22.95 7.42145C22.9506 7.4222 22.9511 7.42294 22.9518 7.42359ZM18.6612 11.3809L18.6611 11.3809L18.6293 11.3519L18.5033 11.2329C18.4868 11.2155 18.4688 11.2017 18.4488 11.1863L18.4328 11.1739C14.8073 7.88008 9.2586 8.01068 5.79833 11.471L5.39266 11.8778L4.83098 12.4395C4.14678 13.1237 4.15025 14.239 4.8356 14.9243C5.5221 15.612 6.63739 15.6131 7.32043 14.9289L8.28894 13.9604C10.4895 11.7611 14.0688 11.7611 16.2681 13.9593L16.9674 14.6365C17.6446 15.3138 18.7495 15.3127 19.4302 14.6308C19.7596 14.3025 19.9411 13.8645 19.9422 13.3987C19.9434 12.9353 19.7642 12.4973 19.4348 12.1702L18.7576 11.4721C18.7263 11.4399 18.6936 11.4104 18.6612 11.3809ZM12.0567 21.0315C13.6052 21.0315 14.8605 19.7762 14.8605 18.2277C14.8605 16.6791 13.6052 15.4238 12.0567 15.4238C10.5082 15.4238 9.25287 16.6791 9.25287 18.2277C9.25287 19.7762 10.5082 21.0315 12.0567 21.0315Z" />
        </Icon>
    );
}
