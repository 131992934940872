import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function TrainIcon({ width, height, viewBox, style, className }: IIcon) {
    return (
        <Icon width={width} height={height} viewBox={viewBox} style={style} className={className}>
            <g>
                <path
                    d="M348.5,377l-1-2c0-0.667,0.167-1,0.5-1s0.5-0.333,0.5-1c10.667-4,20.333-9.333,29-16
			s14.667-16.333,18-29l0.5-3.5c0.333-2.333,0.833-4.5,1.5-6.5V57c0-6.667-1.833-13.333-5.5-20c-3.67-6.667-8.336-12.667-14-18
			c-5.667-5.333-12-9.833-19-13.5S345.5,0,339.5,0h-187c-6,0-12.5,1.667-19.5,5s-13.333,7.667-19,13s-10.334,11.333-14,18
			c-3.667,6.667-5.5,13-5.5,19v265c0,4.667,1,9.333,3,14s4.667,9.167,8,13.5s7,8.333,11,12s8,6.5,12,8.5
			c2,0.667,5.167,1.834,9.5,3.5c4.333,1.667,6.5,2.834,6.5,3.5l-77,116h45l56-81h154l56,81h45L348.5,377z M201.5,23
			c0-2,0.833-4,2.5-6s3.5-3,5.5-3h72c0.667,0,2,0.667,4,2s3,2.667,3,4v28c0,2-0.833,3.667-2.5,5s-3.167,2-4.5,2h-72
			c-1.333-0.667-2.333-1.333-3-2c-1.333-0.667-2.333-1.667-3-3c-1.333-1.337-2-2.67-2-4V23z M129.5,99c0-3.333,0.667-7,2-11
			c2-3.339,4.333-6.673,7-10c2-2.667,4.833-5,8.5-7s7.5-3,11.5-3h174c2.667,0,6,1,10,3c2.667,1.333,5.667,3.333,9,6
			c2.667,2,4.667,4.667,6,8c2,2.667,3,6,3,10v59c0,3.333-1,6.5-3,9.5s-4.333,5.833-7,8.5c-3.333,2.667-6.667,4.667-10,6
			c-2.667,1.333-6,2-10,2h-171c-0.667,0-1.667-0.167-3-0.5s-2.333-0.833-3-1.5c-6.667-0.667-12.333-3.833-17-9.5s-7-11.833-7-18.5
			V99z M181,330c-5.667,6-12.833,9-21.5,9s-15.667-3-21-9s-8-13.333-8-22c0-8,2.833-14.833,8.5-20.5s12.5-8.5,20.5-8.5
			c8.667,0,15.834,2.667,21.5,8c5.667,5.333,8.5,12.333,8.5,21S186.667,324,181,330z M309,330c-5.667-6-8.5-13.333-8.5-22
			s3-15.667,9-21s13-8,21-8c8.667,0,15.667,2.833,21,8.5s8,12.5,8,20.5c0,8.667-2.667,16-8,22s-12.333,9-21,9
			C321.832,339,314.665,336,309,330z"
                />
            </g>
        </Icon>
    );
}
