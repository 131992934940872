import * as React from 'react';
import { Range } from 'rc-slider';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { debounce } from 'lodash-es';

interface IProps {
    min?: number;
    max?: number;
    defaultValue?: number[];
    value?: number[];
    onChange?: any;
    onAfterChange?: any;
    suffixMin?: string;
    suffixMax?: string;
    prefix?: string;
    isMobile?: boolean;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const InputsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 16px;
`;

const InputWrapper = styled.div`
    border: 1px solid #c3c3c3;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const Input = styled.input`
    width: 50%;
    height: 40px;
    outline: none;
    border: none;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 9px;
    flex: 1;
`;

const InputPrefix = styled.span`
    font-size: 14px;
    line-height: 20px;
    padding: 0 9px;
`;

const InputSuffix = styled.span`
    font-size: 14px;
    line-height: 20px;
    padding: 0 9px;
`;

const rangeSlider = {
    marginLeft: '33px',
    width: 'intial',
};

const parseNumber = (string) => string.match(/\d+/g)?.[0];

export function RangeSlider({
    min,
    max,
    defaultValue,
    value,
    onChange,
    onAfterChange,
    suffixMin,
    suffixMax,
    prefix,
    isMobile,
}: IProps) {
    const [innerValue, setInnerValue] = React.useState(value || []);
    const [inputValue, setInputValue] = React.useState(value || []);

    const roundedMax = Math.ceil(max);
    const [minValue, maxValue] = [...innerValue];
    const [minInputValue, maxInputValue] = [...inputValue];
    const theme = useTheme();

    const Options = {
        min,
        max: roundedMax,
        defaultValue,
        value: innerValue,
        onChange,
        onAfterChange,
        trackStyle: [{ backgroundColor: theme.background.brandColor2 }],
        handleStyle: [
            { backgroundColor: theme.background.brandColor2 },
            { backgroundColor: theme.background.brandColor2 },
        ],
    };

    React.useEffect(() => {
        setInnerValue(value);
        setInputValue(value);
    }, [value]);

    const onMinValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseNumber(e.target.value) || min;
        let newValue = Number(inputValue);

        setInputValue((value) => {
            return [newValue, value[1]];
        });
        !isMobile && debounceChangeMin(newValue);
    };

    const onMaxValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseNumber(e.target.value) || min;
        let newValue = Number(inputValue);

        setInputValue((value) => {
            return [value[0], newValue];
        });
        !isMobile && debounceChangeMax(newValue);
    };

    const onBlurMin = (val?: number) => {
        let newValue = val || minInputValue;

        if (newValue > maxValue) {
            onBlurMax(newValue);
            newValue = value[1];
        }

        if (newValue <= min) {
            newValue = min;
        }

        setInnerValue((value) => {
            const updatedValue = [newValue, value[1]];
            onAfterChange(updatedValue);
            onChange(updatedValue);
            return updatedValue;
        });
    };

    const onBlurMax = (val?: number) => {
        let newValue = val || maxInputValue;

        if (newValue < minValue) {
            onBlurMin(newValue);
            newValue = value[0];
        }
        if (newValue >= max) {
            newValue = max;
        }

        setInnerValue((value) => {
            const updatedValue = [value[0], newValue];
            onAfterChange(updatedValue);
            onChange(updatedValue);
            return updatedValue;
        });
    };

    const debounceChangeMin = React.useMemo(() => debounce(onBlurMin, 1200), [maxValue, value]);
    const debounceChangeMax = React.useMemo(() => debounce(onBlurMax, 1200), [minValue, value]);

    return (
        <>
            <Wrapper>
                <InputsWrapper>
                    <InputWrapper>
                        {prefix && <InputSuffix>{prefix}</InputSuffix>}
                        <Input
                            type="number"
                            value={`${minInputValue}`}
                            onChange={onMinValChange}
                            onBlur={() => isMobile && onBlurMin()}
                        />
                        {suffixMin && <InputPrefix>{suffixMin}</InputPrefix>}
                    </InputWrapper>
                    <InputWrapper>
                        {prefix && <InputSuffix>{prefix}</InputSuffix>}
                        <Input
                            type="number"
                            value={`${maxInputValue}`}
                            onChange={onMaxValChange}
                            onBlur={() => isMobile && onBlurMax()}
                        />
                        {suffixMax && <InputPrefix>{suffixMax}</InputPrefix>}
                    </InputWrapper>
                </InputsWrapper>
            </Wrapper>
            <Range {...Options} style={rangeSlider} />
        </>
    );
}
