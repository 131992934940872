import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function FlightExtraIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <g id="flight">
                <rect
                    x="133.33"
                    y="118.05"
                    width="15.15"
                    height="15.52"
                    transform="translate(-40.89 177.1) rotate(-57.52)"
                />
                <path d="M417.45,363.56l27.61-27.61-37.5-37.5-7.3,7.3-27-90.85,41.85-41.85a53.45,53.45,0,0,0,6.63-8.08l23.55-35.33A44.54,44.54,0,0,0,383.5,67.88L348.17,91.43a53.9,53.9,0,0,0-8.07,6.63L298.25,139.9l-90.85-27,7.3-7.3-37.5-37.5L149.59,95.7,100,81l-30,30,11.18,11.18L219,219.1l-42.41,42.41a189,189,0,0,0-32.74,44.26L51.39,290.33,21.84,319.87l11.37,11.37,75.15,45.08-10,20,18.43,18.43,20-10,44.6,74.35,11.86,12.18,29.55-29.55-15.43-92.51a188.91,188.91,0,0,0,44.26-32.74l42.41-42.41,96.61,137.28,11.54,11.72,30-30ZM177.2,89.51l16.07,16.07L190.88,108l-24.78-7.37ZM251.15,187l-86.54-55.07-8.14,12.79L240.17,198l-10.24,10.24L91.57,110.86l12.8-12.8,181.7,54ZM43.44,319.71l13.15-13.15,80,13.34-21.42,42.84Zm76.33,76.66-3-3,37.94-75.87a173.93,173.93,0,0,1,32.64-45.28L350.81,108.77a37.61,37.61,0,0,1,5.77-4.73l35.33-23.55a29.39,29.39,0,0,1,40.76,40.75l-23.54,35.33a38.46,38.46,0,0,1-4.75,5.78L240.92,325.8a173.85,173.85,0,0,1-45.28,32.64Zm86.82,60.19-13.15,13.15-43-71.72,42.84-21.42Zm201-136.69,16.07,16.07L412.54,347l-7.37-24.78Zm-5.28,101.71L304.92,283.23,315.17,273l53.26,83.7,12.79-8.14L326.14,262l34.92-34.92,54,181.7Z" />
                <rect
                    x="379.78"
                    y="364.5"
                    width="15.15"
                    height="15.52"
                    transform="translate(-139.3 266.18) rotate(-32.47)"
                />
            </g>
        </Icon>
    );
}
