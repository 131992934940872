import styled from '@emotion/styled';
import React, { Ref } from 'react';
import Slider from 'react-slick';
import { ArrowRightIcon } from '../../../../components/Icons/ArrowRightIcon';
import { ITopSailRates, TopSailCabinRate } from '../../../../interfaces/ISearchApi';
import { __ } from '../../../../helpers/TranslationService';
import { getCurrencyString } from '../../../../components/PriceString';
import useWindowSize from '../../../../hooks/useViewport';
import { isDesktop } from '../../../../helpers/responsiveHelpers/responsiveHelpers';
import { SHIP_IN_SHIP_CABIN } from '../../../../reducers/cruisePage/cruisePageMappers';
import { BreakpointsEnumWithoutUnits } from '../../../../helpers/breakpoints';
import { ArrowLeftIcon } from '../../../../components/Icons/ArrowLeftIcon';

const StyledContainer = styled.div`
    margin: 0 0 0 16px;
    position: relative;

    @media (min-width: ${BreakpointsEnumWithoutUnits.md}) {
        margin-right: 16px;
    }

    .slick-track {
        display: flex;
        align-items: stretch;
        margin: 0;
    }

    .slick-slide {
        margin-right: 4px;
        display: flex;

        > div {
            height: 100%;
            min-width: 120px;
        }
    }
`;

const StyledPriceNumber = styled.span`
    font-size: 16px;
`;

const StyledPriceRow = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 700;
`;

const StyledPriceBox = styled.div<{ isFiltered?: boolean }>`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`;

const StyledPriceBoxInner = styled.div<{ isFiltered?: boolean }>`
    flex: 1;
    border: 2px solid ${(props) => props.theme.border.onBg};
    border-width: ${(props) => (props.isFiltered ? '0px' : '2px')};
    background-color: ${(props) =>
        props.isFiltered ? props.theme.background.layerTertiary : props.theme.colors.white};
    border-radius: 8px;
    padding: 16px 12px 8px 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 120px;
    box-shadow: ${(props) => (props.isFiltered ? 'none' : '0px 6px 8px 0px rgba(0, 0, 0, 0.02)')};
`;

const HelperText = styled.p<{ isReduced?: boolean }>`
    margin: 0;
    width: 100%;
    font-size: 12px;
    color: ${(props) => props.theme.text.color.primary2};
    text-decoration: ${(props) => (props.isReduced ? 'line-through' : 'none')};
`;

const SliderNextArrow = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 50px;
    display: flex;
    align-items: center;

    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

    button {
        width: 36px;
        height: 36px;
        border: 2px solid ${(props) => props.theme.border.onBg};
        background: ${({ theme }) => theme.colors.white[100]};
        border-radius: 50%;

        svg {
            fill: ${(props) => props.theme.icon.brandColor1};
        }
    }
`;

const SliderPrevArrow = styled(SliderNextArrow)`
    left: 0;
    top: 0;
    bottom: 0;
    right: initial;
    padding-left: 20px;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
`;

const FilterSeparator = styled.div`
    height: 100%;
    padding: 8px 8px 8px 12px;

    > div {
        height: 100%;
        width: 2px;
        background: ${(props) => props.theme.border.onBg};
    }
`;

const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    responsive: [
        {
            breakpoint: BreakpointsEnumWithoutUnits.lg,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: BreakpointsEnumWithoutUnits.md,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: BreakpointsEnumWithoutUnits.sm,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: BreakpointsEnumWithoutUnits.xs,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};

const cabins = ['inside', 'outside', 'balcony', 'suite', 'shipinship'];

const shouldShowDiscount = (discountThreshold: number, cabin: TopSailCabinRate): boolean =>
    cabin.price > 0 &&
    cabin.discountPercentage > 0 &&
    cabin.discountPercentage >= discountThreshold;

const calculateOriginalPrice = (discountedPrice, discountPercentage) =>
    Number((discountedPrice / (1 - discountPercentage / 100)).toFixed());

const CabinPriceInfo = React.forwardRef(
    (
        props: {
            cabin: TopSailCabinRate;
            cabinKind: string;
            operator: string;
            discountThreshold: number;
            cabinFilter: string[];
            isLastSelectedCabinType?: boolean;
        },
        ref: Ref<HTMLDivElement>,
    ) => {
        if (!props.cabin) return null;

        const displayCabinKind =
            props.cabinKind === SHIP_IN_SHIP_CABIN
                ? `shipCabin${props.operator.toUpperCase()}`
                : props.cabinKind;

        // calculate per person price, original price is for 2 ppl
        const perPersonPrice = Number((props.cabin.price / 2).toFixed());

        const isFiltered = props.cabinFilter.length
            ? !props.cabinFilter.includes(props.cabinKind)
            : false;

        return (
            <StyledPriceBox ref={ref}>
                <StyledPriceBoxInner isFiltered={isFiltered}>
                    <HelperText>{__(displayCabinKind, 'dreamlines')}</HelperText>
                    <StyledPriceRow>
                        {__('pricePrefix', 'dreamlines')}{' '}
                        <StyledPriceNumber>
                            {getCurrencyString(perPersonPrice, props.cabin.currency)}
                        </StyledPriceNumber>{' '}
                        {__('priceSuffix', 'dreamlines')}
                    </StyledPriceRow>

                    {shouldShowDiscount(props.discountThreshold, props.cabin) && (
                        <HelperText isReduced>
                            {getCurrencyString(
                                calculateOriginalPrice(
                                    perPersonPrice,
                                    props.cabin.discountPercentage,
                                ),
                                props.cabin.currency,
                            )}
                        </HelperText>
                    )}
                </StyledPriceBoxInner>

                {props.cabinFilter.length > 0 && props.isLastSelectedCabinType && (
                    <FilterSeparator>
                        <div />
                    </FilterSeparator>
                )}
            </StyledPriceBox>
        );
    },
);

const isElementFullyVisible = (container, element) => {
    if (!container || !element) return false;

    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    return (
        elementRect.top >= containerRect.top &&
        elementRect.bottom <= containerRect.bottom &&
        elementRect.left >= containerRect.left &&
        elementRect.right <= containerRect.right
    );
};

export const SearchResultItemPrices = (props: {
    topSail?: ITopSailRates | null;
    operator: string;
    discountThreshold: number;
    cabinFilter: string[];
}) => {
    if (!props.topSail) {
        return null;
    }

    const elementsRef = React.useRef([]);
    const sliderRef = React.useRef(null);
    const [isDesktopAndUp, setIsDesktopAnUp] = React.useState(false);
    const [showSliderArrows, setShowSliderArrows] = React.useState(false);
    const [isLastSlideVisible, setIsLastSlideVisible] = React.useState(false);
    const [isFirstSlideVisible, setIsFirstSlideVisible] = React.useState(true);

    const isCabinFilterActive = props.cabinFilter.length > 0;

    const allAvailableRateCabinTypes = cabins
        .filter((cabinType) => !!props.topSail.rates[`${cabinType}_cabin`])
        .sort((a, b) => {
            const indexA = props.cabinFilter.indexOf(a);
            const indexB = props.cabinFilter.indexOf(b);

            if (indexA !== -1 && indexB !== -1) return indexA - indexB;
            if (indexA !== -1) return -1;
            if (indexB !== -1) return 1;
            return 0;
        });

    const selectedCabinTypes = isCabinFilterActive
        ? allAvailableRateCabinTypes.filter((c) => props.cabinFilter.includes(c))
        : [];

    const isLastSelectedCabinType = (cabinType: string) => {
        if (!isCabinFilterActive) return false;
        if (selectedCabinTypes.length === allAvailableRateCabinTypes.length) return false;
        return selectedCabinTypes[selectedCabinTypes.length - 1] === cabinType;
    };

    const handleSliderNextClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        sliderRef?.current.slickNext();
    };

    const handleSliderPrevClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        sliderRef?.current.slickPrev();
    };

    const handleSlideChange = () => {
        if (!isDesktopAndUp) {
            return;
        }

        const isLastElementVisible = isElementFullyVisible(
            sliderRef.current?.innerSlider.list,
            elementsRef.current?.[allAvailableRateCabinTypes.length - 1],
        );

        const isFirstElementVisible = isElementFullyVisible(
            sliderRef.current?.innerSlider.list,
            elementsRef.current?.[0],
        );

        setIsLastSlideVisible(isLastElementVisible);
        setIsFirstSlideVisible(isFirstElementVisible);
    };

    const { width } = useWindowSize();

    React.useEffect(() => {
        const isDesktopAndUpView = isDesktop(width);
        setIsDesktopAnUp(isDesktopAndUpView);

        if (!isDesktopAndUpView) {
            return;
        }

        const isLastElementVisible = isElementFullyVisible(
            sliderRef.current?.innerSlider.list,
            elementsRef.current?.[allAvailableRateCabinTypes.length - 1],
        );

        setIsLastSlideVisible(isLastElementVisible);
        setShowSliderArrows(!isLastElementVisible);
    }, []);

    return (
        <StyledContainer>
            <Slider
                {...settings}
                draggable={!isDesktopAndUp}
                ref={sliderRef}
                afterChange={handleSlideChange}
            >
                {allAvailableRateCabinTypes.map((cabinType, idx) => (
                    <CabinPriceInfo
                        ref={(el) => (elementsRef.current[idx] = el)}
                        key={cabinType}
                        cabinKind={cabinType}
                        cabin={props.topSail.rates[`${cabinType}_cabin`]}
                        operator={props.operator}
                        discountThreshold={props.discountThreshold}
                        cabinFilter={props.cabinFilter}
                        isLastSelectedCabinType={isLastSelectedCabinType(cabinType)}
                    />
                ))}
            </Slider>
            {isDesktopAndUp && showSliderArrows && !isLastSlideVisible && (
                <SliderNextArrow>
                    <button onClick={handleSliderNextClick}>
                        <ArrowRightIcon width={12} />
                    </button>
                </SliderNextArrow>
            )}

            {isDesktopAndUp && showSliderArrows && !isFirstSlideVisible && (
                <SliderPrevArrow>
                    <button onClick={handleSliderPrevClick}>
                        <ArrowLeftIcon width={12} />
                    </button>
                </SliderPrevArrow>
            )}
        </StyledContainer>
    );
};
