import classnames from 'classnames';
import * as React from 'react';

interface HomeProps {
    rating: number | string;
    isWhite?: boolean;
}

export function RatingBox({ rating, isWhite }: HomeProps) {
    const ratingAvailable: boolean = rating && rating !== '-';
    const roundedOffRating = ratingAvailable ? (rating as number).toFixed(1) : rating;
    const formattedRating = ratingAvailable
        ? parseInt(roundedOffRating as string, 10) === 10
            ? 10
            : roundedOffRating
        : '-';
    const classes = classnames('ratingBox', { ratingBoxWhite: isWhite });
    return (
        <div className={classes}>
            <span className="ratingBoxScore">{formattedRating}</span>
            <span>{` / 10`}</span>
        </div>
    );
}
