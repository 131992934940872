import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function PlusCircleIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <g>
                <path d="M376.94,240.21H270.24v-101a14,14,0,0,0-28.07,0v101H142a14,14,0,0,0,0,28.07H242.17V374.21a14,14,0,0,0,28.07,0V268.28h106.7a14,14,0,1,0,0-28.07Z" />
                <path d="M259.45,21.12c-129.91,0-235.6,105.69-235.6,235.6s105.69,235.6,235.6,235.6,235.6-105.69,235.6-235.6S389.36,21.12,259.45,21.12Zm0,444.6c-115.24,0-209-93.76-209-209s93.76-209,209-209,209,93.75,209,209S374.59,465.72,259.45,465.72Z" />
            </g>
        </Icon>
    );
}
