import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function AllInclusiveIcon(props: IIcon) {
    const newViewProps = '0 -420 512 512' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g id="allinclusive">
                <path
                    transform="scale(1,-1)"
                    d="M401 270l-34 50l7 9l52 15c1 -4 1 -9 1 -13c0 -24 -10 -46 -26 -61zM355 415c28 -4 51 -22 63 -46l-51 -15h-12v61zM302 256l46 46l32 -47c-11 -6 -24 -9 -38 -9s-28 4 -40 10zM329 415v-61h-8l-53 18c12 22 35 39 61 43zM425 414c22 -22 34 -52 34 -83s-12 -61 -34 -83
s-52 -35 -83 -35c-23 0 -45 7 -64 19l16 15c14 -8 30 -13 48 -13c53 0 96 44 96 97s-43 96 -96 96c-45 0 -82 -31 -93 -73h-22c5 22 15 43 32 60c22 22 52 34 83 34s61 -12 83 -34zM195 -31h56l12 -33h-178l12 33h56v207l-153 153v12h348v-12l-153 -153v-207zM122 354h-30
l-60 60l15 15z"
                />
            </g>
        </Icon>
    );
}
