import * as React from 'react';
import styled from '@emotion/styled';
import * as Colors from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { CrossIcon } from '../Icons/CrossIcon';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '19px',
        padding: '0px 15px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.19)',
        position: 'relative',
    },
    resetButton: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        color: Colors.acqa,
    },
    filterSpan: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        position: 'absolute',
        left: 0,
        right: 0,
        minHeight: '100%',
        zIndex: -1,
    },
    closeButton: {
        alignSelf: 'flex-end',
        margin: '10px',
        cursor: 'pointer',
        fill: Colors.black,
        display: 'block',
    },
};

interface DispatchProps {
    toggleSearchFilter: any;
}

interface OwnProps {
    clearFilterParams(): void;
}

type IProps = DispatchProps & OwnProps;

const ResetButtonStyled = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    color: ${({ theme }) => theme.link.primary};
`;
export class FilterHeader extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.clearFilterParams = this.clearFilterParams.bind(this);
    }

    clearFilterParams = (event) => {
        this.props.clearFilterParams();
    };

    render() {
        return (
            <div style={style.wrapper}>
                <ResetButtonStyled onClick={this.clearFilterParams}>
                    <span>{__('reset', 'dreamlines')}</span>
                </ResetButtonStyled>
                <span style={style.filterSpan}>{__('filter', 'dreamlines')}</span>
                <div onClick={this.props.toggleSearchFilter}>
                    <CrossIcon style={style.closeButton} width={28} height={28} />
                </div>
            </div>
        );
    }
}
