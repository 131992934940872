import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function ImagesIcon(props: IIcon) {
    const newViewProps = '0 0 37.066 37.066' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g>
                <path
                    d="M36.785,12.556L15.787,2.525c-0.244-0.118-0.539-0.016-0.654,0.227l-4.57,9.565h2.49l3.354-7.028
		l18.205,8.696L28.919,25.9l-3.205-1.53v6.299l1.971,0.941c0.246,0.119,0.537,0.012,0.654-0.232l8.678-18.171
		C37.135,12.965,37.031,12.672,36.785,12.556z"
                />
                <path
                    d="M23.764,13.472H0.492C0.223,13.472,0,13.691,0,13.96v20.139c0,0.27,0.223,0.491,0.492,0.491h23.271
		c0.271,0,0.492-0.221,0.492-0.491V13.96C24.256,13.691,24.035,13.472,23.764,13.472z M22.42,28.906h-1.475
		c-0.859-2.203-1.916-5.287-3.521-4.875c-1.881,0.48-2.82,4.875-2.82,4.875s-0.963-4.959-3.617-7.614
		c-2.655-2.655-5.227,7.614-5.227,7.614H2.246V15.699H22.42V28.906z"
                />
                <circle cx="5.502" cy="18.533" r="1.646" />
                <path
                    d="M16.545,19.695c0.369,0,0.715-0.039,1.023-0.1c0.359,0.152,0.797,0.245,1.268,0.245
		c1.215,0,2.197-0.593,2.197-1.325c0-0.73-0.982-1.323-2.197-1.323c-0.455,0-0.877,0.083-1.227,0.228
		c-0.145-0.141-0.338-0.228-0.553-0.228h-0.318c-0.408,0-0.729,0.309-0.775,0.702c-0.982,0.103-1.713,0.459-1.713,0.883
		C14.25,19.284,15.277,19.695,16.545,19.695z"
                />
            </g>
        </Icon>
    );
}
