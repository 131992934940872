import React from 'react';
import styled from '@emotion/styled';
import { CTA } from '../../../components/CTA';
import { __ } from '../../../helpers/TranslationService';
import { hasHotelServiceCharges } from '../../../helpers/hotelServiceChargesHelper';
import * as InclusivesIcons from '../helper/InclusiveIcons';
import { EuroIcon } from '../helper/InclusiveIcons';
import { eventTracker } from '../../../helpers/EventTracker/EventTracker';
import { TrackComponent } from '../../../components/TrackComponent';
import { Link } from '../../../components/Link';
import { BreakpointsEnum } from '../../../helpers/breakpoints';

const style: Record<string, React.CSSProperties> = {
    icon: {
        fill: '#7D7D7D',
    },
};

const eventTrackingData = {
    category: 'search results',
    event: 'click',
    action: 'search result click',
    label: 'dreamlines package',
};

const LinkStyled = styled(Link)`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
    color: ${({ theme }) => theme.link.primary};
    &:hover {
        color: ${({ theme }) => theme.link.primaryHover};
    }
    @media only screen and (min-width: ${BreakpointsEnum.sm}) {
        justify-content: left;
        width: 65%;
        line-height: 3.5;
    }
`;

export const SearchResultItemOverlay = (props: any) => {
    const { cruise, selectedSail } = props;
    const { cruisePageUrl } = cruise;
    const cruisePageUrlWithoutHash = cruisePageUrl.slice(0, cruisePageUrl.lastIndexOf('#'));
    const searchResultItemUrl = cruisePageUrlWithoutHash + '#selectedSail=' + selectedSail.sailNid;

    const TrackableLink = TrackComponent(Link, eventTrackingData);

    const includesHotelServiceCharges = hasHotelServiceCharges(
        props.portalId,
        cruise.companyTitle,
        cruise.shipTitle,
        cruise.earliestDeparture,
    );

    const onClick = () => {
        eventTracker.track(eventTrackingData);
    };

    return (
        <>
            <div className="searchResultItemOverlay">
                <div className="searchResultItemOverlay__headline">
                    {__('Dreamlines Package', 'dreamlines')}
                </div>
                <div className="searchResultItemOverlay__info">
                    {__(
                        'Discover some of the most dazzling destinations with exclusive benefits tailored to your needs and desires.',
                        'dreamlines',
                    )}
                </div>
                <div className="searchResultItemOverlay__title">
                    {__('This Package Include', 'dreamlines')}
                </div>

                <div className="searchResultItemOverlay__infoArea">
                    <ul className="searchResultItemOverlay__list">
                        {cruise?.icons.map((icon, i) => {
                            const IconComp = InclusivesIcons[icon?.icon];
                            if (!IconComp) {
                                return null;
                            }
                            return (
                                <li key={`${i}`} className="searchResultItemOverlay__listItem">
                                    <span className={`searchResultItemOverlay__item__icon`}>
                                        <IconComp width={25} height={25} style={style.icon} />
                                    </span>
                                    <span className={`searchResultItemOverlay__item__title`}>
                                        {__(icon.text, 'dreamlines')}
                                    </span>
                                </li>
                            );
                        })}
                        {includesHotelServiceCharges && (
                            <li className={`searchResultItemOverlay__listItem`}>
                                <span className={`searchResultItemOverlay__item__icon`}>
                                    <EuroIcon width={25} height={25} style={style.icon} />
                                </span>
                                <span className="hotelServiceChargesLabel ">
                                    {__('incl. hotel service charges', 'dreamlines')}
                                </span>
                            </li>
                        )}
                    </ul>

                    <div className="hr" />

                    <div className="searchResultItemOverlay__list__links">
                        <LinkStyled href={props.packagesUrl} followLink>
                            <span>{__('More about Dreamlines Package', 'dreamlines')}</span>
                        </LinkStyled>
                        <TrackableLink
                            className="searchResultItemOverlay__button__wrapper"
                            href={searchResultItemUrl}
                        >
                            <CTA modal={true} onClick={onClick} className="CTA">
                                <span>{__('View Offer', 'dreamlines')}</span>
                            </CTA>
                        </TrackableLink>
                    </div>
                </div>
            </div>
        </>
    );
};
