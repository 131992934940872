import React from 'react';
import { PhoneIcon } from '../../../components/Icons/PhoneIcon';
import { EnvelopeIcon } from '../../../components/Icons/EnvelopeIcon';
import { UserIcon } from '../../../components/Icons/UserIcon';

interface IProps {
    type: string;
    children: React.ReactNode;
}
const FAQContactItem = (props: IProps) => {
    const { type } = props;
    return (
        <div className="faqPage--contact-item">
            <div className="faqPage--contact-icon-container">
                {type === 'phone' ? (
                    <PhoneIcon width={29} height={29} />
                ) : type === 'email' ? (
                    <EnvelopeIcon width={56} height={35} />
                ) : (
                    <UserIcon width={30} height={30} />
                )}
            </div>
            <div className="faqPage--contact-text-container">{props.children}</div>
        </div>
    );
};
export default FAQContactItem;
