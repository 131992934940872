import * as React from 'react';
import * as Colors from '../../consts/variables';
import { transformOptions } from '../../helpers/searchHelpers';
import { __ } from '../../helpers/TranslationService';
import { ISearchReducer } from '../../reducers/search';
import { SelectDropdown } from './SelectDropdown';
import styled from '@emotion/styled';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        margin: '15px 0',
        padding: '15px',
        background: Colors.white,
        borderRadius: '6px',
    },
    heading: {
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '10px',
    },
};

const SelectWrapper = styled.div`
    margin-top: 10px;
    font-size: 15px;

    .Select-option {
        &.country {
            font-weight: bold;
        }
        &.harbour {
            margin-left: 8px;
        }
    }
`;

interface StateProps {
    search: ISearchReducer;
    startHarbour: string;
    endHarbour: string;
    stopHarbour: string;
    startHarbourCountry: string;
    endHarbourCountry: string;
    stopHarbourCountry: string;
    onStartHarbourChange(param: any): void;
    onEndHarbourChange(param: any): void;
    onHarbourChange(param: any): void;
    onStartCountryChange(param: any): void;
    onEndCountryChange(param: any): void;
    onCountryChange(param: any): void;
    autoSetFocus?: boolean;
}

interface DispatchProps {}

type IProps = StateProps & DispatchProps;

export const RouteFilters = (props: IProps) => {
    const defaultOptions = {
        start: {
            value: 'default',
            label: __('Preferred Starting port', 'dreamlines'),
        },
        end: {
            value: 'default',
            label: __('Preferred end port', 'dreamlines'),
        },
        stop: { value: 'default', label: __('Optional stopover', 'dreamlines') },
    };

    const {
        startHarbourCountriesCombined: startOptions,
        endHarbourCountriesCombined: endOptions,
        harbourCountriesCombined: stopOptions,
    } = props.search.searchFacets;

    function getValue(harbour = 'default', country = 'default') {
        if (harbour !== 'default') {
            return harbour;
        } else if (country !== 'default') {
            return country;
        }
        return 'default';
    }
    return (
        <div style={style.wrapper}>
            <div style={style.heading} className="filterSectionTitle">
                <span>{__('Route', 'dreamlines')}</span>
            </div>
            <SelectWrapper>
                <SelectDropdown
                    onChangeHarbour={props.onStartHarbourChange}
                    onChangeCountry={props.onStartCountryChange}
                    nids={transformOptions(defaultOptions.start, startOptions)}
                    onChange={props.onStartHarbourChange}
                    value={getValue(props.startHarbour, props.startHarbourCountry)}
                    instanceId="start harbour"
                    searchPlaceholder="Type in a start harbour"
                    searchable
                    autoSetFocus={props.autoSetFocus}
                    testSelectorName="start-harbour-select"
                />
            </SelectWrapper>

            <SelectWrapper>
                <SelectDropdown
                    onChangeHarbour={props.onEndHarbourChange}
                    onChangeCountry={props.onEndCountryChange}
                    nids={transformOptions(defaultOptions.end, endOptions)}
                    onChange={props.onEndHarbourChange}
                    value={getValue(props.endHarbour, props.endHarbourCountry)}
                    instanceId="end harbour"
                    searchPlaceholder="Type in an end harbour"
                    searchable
                    autoSetFocus={props.autoSetFocus}
                    testSelectorName="end-harbour-select"
                />
            </SelectWrapper>
            <SelectWrapper>
                <SelectDropdown
                    onChangeHarbour={props.onHarbourChange}
                    onChangeCountry={props.onCountryChange}
                    nids={transformOptions(defaultOptions.stop, stopOptions)}
                    onChange={props.onHarbourChange}
                    value={getValue(props.stopHarbour, props.stopHarbourCountry)}
                    instanceId="stop harbour"
                    searchPlaceholder="Type in a stop harbour"
                    searchable
                    autoSetFocus={props.autoSetFocus}
                    testSelectorName="stop-harbour-select"
                />
            </SelectWrapper>
        </div>
    );
};
