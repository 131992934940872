import * as React from 'react';

interface HomeProps {
    name: string;
    reviewsType: string;
    ratingValue?: number;
    reviewCount?: number;
    offerCount: number;
    lowPrice: number;
    priceCurrency: string;
}

export function ReviewsGoogleScript({
    name,
    ratingValue,
    reviewCount,
    offerCount,
    lowPrice,
    priceCurrency,
    reviewsType,
}: HomeProps) {
    const isDestination = reviewsType === 'destinationReviews';
    const script = `
        {
            "@context": "http://schema.org",
            "@type": "Product",
            "name": "${name}"
            ${
                lowPrice > 0
                    ? `,"offers": {
                "@type": "AggregateOffer",
                "offerCount": "${offerCount}",
                "lowPrice": "${lowPrice}",
                "priceCurrency": "${priceCurrency}"
            }`
                    : ''
            }
            ${
                ratingValue > 0 && !isDestination
                    ? `,"aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "${(ratingValue / 2).toFixed(1)}",
                        "reviewCount": "${reviewCount}"
                    }`
                    : ''
            }
            
        }
    `;
    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: script }} />;
}
