import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function TourIcon({ width, height, viewBox, style, className }: IIcon) {
    const newViewProps = '0 -420 512 512' ?? viewBox;
    return (
        <Icon
            width={width}
            height={height}
            viewBox={newViewProps}
            style={style}
            className={className}
        >
            <g>
                <path
                    transform="scale(1,-1)"
                    d="M218 422c6 0 10 -4 10 -10v-440c0 -6 -4 -10 -10 -10h-23c-6 0 -11 4 -11 10v225h-90c-4 0 -10 1 -15 3s-10 4 -13 6l-61 41c-3 2 -5 6 -5 9s2 7 5 9l61 41c3 2 8 4 13 6s11 3 15 3h90v97c0 6 5 10 11 10h23zM507 316c3 -2 5 -6 5 -9s-2 -6 -5 -8l-61 -42
c-3 -2 -8 -4 -13 -6s-11 -3 -15 -3h-154l-21 118h175c4 0 10 0 15 -2s10 -4 13 -6z"
                />
            </g>
        </Icon>
    );
}
