import React from 'react';

import {
    C1AU_DOMAIN,
    C1UK_DOMAIN,
    CRUISE_AWAY_DOMAIN,
    DE_DOMAIN,
    NL_DOMAIN,
} from '../../../context/markets';

export const getPackageDataByMarket = (portalId, companyTitle) => {
    switch (portalId) {
        case DE_DOMAIN:
        case NL_DOMAIN:
            return {
                title: (
                    <>
                        DREAM<span>LINES</span> Package
                    </>
                ),
                url: '/reederei/dreamlines-package',
            };
        case CRUISE_AWAY_DOMAIN:
            return {
                title: (
                    <>
                        DREAM<span>AWAY</span> Package
                    </>
                ),
                url: '/cruiseline/dreamaway-package',
            };
        case C1AU_DOMAIN:
        case C1UK_DOMAIN:
            return {
                title: (
                    <>
                        CRUISE<span>1ST</span> Package
                    </>
                ),
                url: '/cruise-lines/cruise1st-package',
            };
        default: {
            return {
                title: companyTitle || '',
                url: '/cruiseline/dreamaway-package',
            };
        }
    }
};
