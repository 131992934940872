import * as React from 'react';
import { PriceString } from '../../components/PriceString';
import * as Colors from '../../consts/variables';
import { currencyMap, roundBy } from '../../helpers/priceHelpers';
import { TestSelector } from '../../helpers/TestSelector';
import { __ } from '../../helpers/TranslationService';
import { ISearchReducer } from '../../reducers/search';
import { RangeSlider } from '../RangeSlider/RangeSlider';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        margin: '15px 0',
        padding: '15px',
        background: Colors.white,
        borderRadius: '6px',
    },
    heading: {
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '10px',
        fontSize: '20px',
    },
    priceText: {
        fontWeight: 'bold',
        fontSize: 15,
    },
    itemStyle: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        display: 'inline-block',
        verticalAlign: 'top',
        marginRight: '8px',
        marginTop: 0,
        height: '21.1px',
        width: '22px',
    },
    label: {
        display: 'inline-block',
        verticalAlign: 'top',
        whiteSpace: 'normal',
        fontSize: 15,
        margin: 0,
    },
};

interface IState {
    min: number;
    max: number;
}

interface IProps {
    search: ISearchReducer;
    priceMin: number;
    priceMax: number;
    discount: number;
    onDiscountChange(param: any): void;
    onPriceChange(param: any[]): void;
    currency: string;
    dualCurrency: string | null;
    exchangeRate: number;
    isMobile: boolean;
}

const defaultPriceMin = 0;
const defaultPriceMax = 10000;

export class PriceFilters extends React.Component<IProps, IState> {
    rangeSliderOptions = {
        min: defaultPriceMin,
        max: this.props.search.searchResults.maxPrice || defaultPriceMax,
        defaultValue: [this.props.priceMin, this.props.priceMax],
        value: [defaultPriceMin, this.props.search.searchResults.maxPrice || defaultPriceMax],
        onChange: (val: any) => null,
        onAfterChange: (val: any) => null,
        suffixMin: '',
        suffixMax: '',
        prefix: '',
        isMobile: false,
    };

    constructor(props) {
        super(props);

        const priceMin = this.props.search.filterParamsList
            .filter((param) => param.paramName === 'priceMin')
            .shift();
        const min = priceMin ? parseInt(priceMin.paramValue as string, 10) : defaultPriceMin;
        const priceMax = this.props.search.filterParamsList
            .filter((param) => param.paramName === 'priceMax')
            .shift();
        const max = priceMax
            ? parseInt(priceMax.paramValue as string, 10)
            : this.props.search.searchResults.maxPrice;

        this.state = {
            min: min,
            max: max,
        };

        this.rangeSliderOptions = {
            min: defaultPriceMin,
            max:
                this.props.dualCurrency && this.props.exchangeRate
                    ? roundBy(
                          this.props.search.searchResults.maxPrice * this.props.exchangeRate ||
                              this.props.search.searchResults.maxPrice * this.props.exchangeRate,
                          0,
                      )
                    : this.props.search.searchResults.maxPrice,
            defaultValue: [this.props.priceMin, this.props.priceMax],
            value: [this.state.min, this.state.max],
            onChange: this.onSliderChange,
            onAfterChange: this.onAfterChange,
            suffixMin: '',
            suffixMax: '',
            prefix: '',
            isMobile: this.props.isMobile,
        };
        switch (this.props.currency) {
            case 'EUR':
                this.rangeSliderOptions.suffixMin = `${currencyMap.EUR}`;
                this.rangeSliderOptions.suffixMax = `${currencyMap.EUR}`;
                break;
            case 'GBP':
                this.rangeSliderOptions.prefix = `${currencyMap.GBP}`;
                break;
            default:
                this.rangeSliderOptions.prefix = `${currencyMap.USD}`;
                break;
        }
    }

    onSliderChange = (priceValues) => {
        if (this.props.dualCurrency && this.props.exchangeRate) {
            this.setState({
                min: roundBy(priceValues[0] / this.props.exchangeRate, 0),
                max: roundBy(priceValues[1] / this.props.exchangeRate, 0),
            });
        } else {
            this.setState({
                min: priceValues[0],
                max: priceValues[1],
            });
        }
    };

    onAfterChange = (priceValues) => {
        if (this.props.dualCurrency && this.props.exchangeRate) {
            const priceMaxDefaultCurrency = roundBy(priceValues[1] / this.props.exchangeRate, 0);
            const priceMin = roundBy(priceValues[0] / this.props.exchangeRate, 0);
            const priceMax =
                priceMaxDefaultCurrency < defaultPriceMax ? priceMaxDefaultCurrency : null;
            this.props.onPriceChange([priceMin, priceMax]);
        } else {
            const priceMin = priceValues[0];
            const priceMax = priceValues[1];
            this.props.onPriceChange([priceMin, priceMax]);
        }
    };

    handleDiscountCheckboxChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.props.onDiscountChange(value ? 1 : 0);
    };

    UNSAFE_componentWillReceiveProps({ priceMin, priceMax }) {
        if (priceMin !== this.state.min || priceMax !== this.state.max) {
            this.setState({
                min: priceMin,
                max: priceMax || this.state.max,
            });
        }
    }

    render() {
        const { exchangeRate, dualCurrency } = this.props;
        const isDualCurrency = dualCurrency && exchangeRate;
        const discoutCheckboxDisabled = this.props.search.searchFacets.hasDiscount.length === 0;
        const currency = this.props.dualCurrency || this.props.currency;
        const min = isDualCurrency ? roundBy(this.state.min * exchangeRate, 0) : this.state.min;
        const max = isDualCurrency ? roundBy(this.state.max * exchangeRate, 0) : this.state.max;
        this.rangeSliderOptions.value = [min, max];
        return (
            <div style={style.wrapper}>
                <div style={style.heading}>
                    <span>{__('price', 'dreamlines')}</span>
                </div>
                <div>
                    <div {...TestSelector.getSelector('searchFilterChangePrice')}>
                        <RangeSlider {...this.rangeSliderOptions} />
                    </div>
                    <div style={style.itemStyle}>
                        <input
                            style={style.input}
                            id="discount"
                            name="discount"
                            type="checkbox"
                            onChange={this.handleDiscountCheckboxChange}
                            checked={this.props.discount === 1}
                            disabled={discoutCheckboxDisabled}
                        />
                        <label style={style.label} htmlFor="discount">
                            {__('Only reduced offers', 'dreamlines')}
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}
