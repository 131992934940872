import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from '@emotion/styled';
import { PaginationLoader } from './PaginationLoader';
interface IComponentProps {
    className?: string;
    width?: number;
    height?: number;
    title?: string;
    id?: string;
}
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 25px;
`;
const SkeletonItem = () => (
    <ContentLoader
        speed={2}
        viewBox="0 0 768 358"
        backgroundColor="#e3ecf1"
        foregroundColor="#dde7ec"
        uniqueKey="search-loader"
        style={{ backgroundColor: 'white' }}
    >
        <rect x="12" y="12" rx="5" ry="5" width="744" height="41" />
        <rect x="12" y="61" rx="5" ry="5" width="234" height="27" />
        <rect x="654" y="61" rx="5" ry="5" width="102" height="27" />
        <rect x="12" y="96" rx="5" ry="5" width="358" height="250" />
        <rect x="378" y="96" rx="5" ry="5" width="159" height="16" />
        <rect x="378" y="120" rx="5" ry="5" width="125" height="16" />
        <rect x="378" y="144" rx="5" ry="5" width="211" height="16" />
        <rect x="378" y="152" rx="5" ry="5" width="159" height="8" />
        <rect x="378" y="168" rx="5" ry="5" width="125" height="8" />
        <rect x="378" y="184" rx="5" ry="5" width="211" height="8" />
        <rect x="378" y="200" rx="5" ry="5" width="173" height="8" />
        <rect x="648" y="281" rx="5" ry="5" width="112" height="19" />
        <rect x="601" y="307" rx="5" ry="5" width="159" height="39" />
    </ContentLoader>
);
export const SearchLoader = (props: IComponentProps) => {
    const list = Array.from({ length: 11 });
    return (
        <Wrapper {...props}>
            <PaginationLoader />
            {list.map((_, idx) => (
                <SkeletonItem key={`SearchLoader-${idx}`} />
            ))}
        </Wrapper>
    );
};
