import * as React from 'react';
import { RatingBar } from '../RatingBar';
import { __ } from '../../helpers/TranslationService';

interface HomeProps {
    ratings: any;
}

export function DetailedReviews(props: HomeProps) {
    const { ratings } = props;
    const ratingData = [
        { caption: __('Ship', 'dreamlines'), column: 'shipRating' },
        { caption: __('Cabin', 'dreamlines'), column: 'cabinRating' },
        { caption: __('Dining', 'dreamlines'), column: 'gastronomyRating' },
        { caption: __('Excursions', 'dreamlines'), column: 'excursionsRating' },
        { caption: __('Service', 'dreamlines'), column: 'serviceRating' },
        { caption: __('Sport', 'dreamlines'), column: 'sportRating' },
        { caption: __('Entertiment', 'dreamlines'), column: 'entertainmentRating' },
    ];
    return (
        <table className="detailed-reviews">
            <thead>
                <tr>
                    <th className="detailed-reviews__heading">
                        {__('Detailed Reviews', 'dreamlines')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {ratingData.map(
                    ({ caption, column }) =>
                        ratings[column] && (
                            <RatingBar
                                key={column}
                                caption={caption}
                                rating={ratings[column] !== '-' ? ratings[column] : 0}
                            />
                        ),
                )}
            </tbody>
        </table>
    );
}
