import React from 'react';
import styled from '@emotion/styled';

import { OutlinedCalendarIcon } from '../../../../components/Icons/newIcons/CalendarOutlined';
import { OutlinedMoonIcon } from '../../../../components/Icons/newIcons/MoonOutlined';
import { OutlinedRouteIcon } from '../../../../components/Icons/newIcons/RouteOutlined';
import { OutlinedShipIcon } from '../../../../components/Icons/newIcons/ShipOutlined';
import { __ } from '../../../../helpers/TranslationService';
import { BreakpointsEnum } from '../../../../helpers/breakpoints';
import { IMobileSearchResultSail } from '../../../../interfaces/ISearchApi';

interface IProps {
    nights: number;
    shipTitle: string;
    dateRange: string;
    wayPointCities: string[];
    sails: IMobileSearchResultSail[];
    earliestDeparture: number;
    latestArrival: number;
}

const TripItem = styled.p<{ isStandalone?: boolean }>`
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: ${(props) => (props.isStandalone ? '100%' : 'auto')};

    span {
        margin-left: 8px;
    }

    @media (min-width: ${BreakpointsEnum.md}) {
        margin-right: 24px;
    }

    svg {
        flex: 0 0 16px;
    }
`;

const SailOptionsHint = styled.span`
    color: ${(props) => props.theme.text.color.primary2};
    font-size: 12px;
    align-self: flex-end;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;

    @media (min-width: ${BreakpointsEnum.md}) {
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 0;
    }

    ${TripItem}:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }
`;

export const SearchResultItemTripInfo = (props: IProps) => {
    const startPoint = props.wayPointCities[0];
    const endPoint = props.wayPointCities[props.wayPointCities.length - 1];

    // TODO: add translation
    const routeString = __('harbourFromTo', 'dreamlines')
        .replace('{{from}}', startPoint)
        .replace('{{to}}', endPoint);
    const formattedNumberOfSails = `${props.sails.length} ${__('Dates', 'dreamlines')}`;

    return (
        <StyledContainer>
            <TripItem>
                <OutlinedMoonIcon />
                <span>
                    {props.nights} {__('general.nights', 'dreamlines')}
                </span>
            </TripItem>

            {startPoint && endPoint && (
                <TripItem>
                    <OutlinedRouteIcon />
                    <span>{routeString}</span>
                </TripItem>
            )}
            <TripItem>
                <OutlinedShipIcon /> <span>{props.shipTitle}</span>
            </TripItem>
            <TripItem isStandalone>
                <OutlinedCalendarIcon /> <span>{props.dateRange}</span>
                {props.sails.length > 1 && (
                    <SailOptionsHint>{formattedNumberOfSails}</SailOptionsHint>
                )}
            </TripItem>
        </StyledContainer>
    );
};
