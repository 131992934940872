import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function KidsIcon({ width, height, viewBox, style, className }: IIcon) {
    return (
        <Icon
            width={width}
            height={height}
            viewBox={viewBox ?? '0 0 14 16'}
            style={style}
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.96745 1.96875C8.96745 3.05606 8.08605 3.9375 6.99872 3.9375C5.91141 3.9375 5.02997 3.05606 5.02997 1.96875C5.02997 0.88144 5.91141 0 6.99872 0C8.08605 0 8.96745 0.88144 8.96745 1.96875ZM11.6797 2.01575C12.0765 1.54387 12.7609 1.43762 13.2797 1.77512C13.9015 2.17825 14.0203 3.04075 13.5391 3.60012C12.7765 4.46887 11.4984 5.63137 9.71405 6.26887V6.27825V14.8845C9.71405 15.5001 9.21405 16.0001 8.59839 16.0001C7.98279 16.0001 7.48279 15.5001 7.48279 14.8845V11.3939H6.52652V14.8814C6.52652 15.497 6.02653 15.997 5.4109 15.997C4.79527 15.997 4.29527 15.497 4.29527 14.8814V6.27512V6.26575C2.5109 5.62825 1.23277 4.46575 0.470272 3.59699C-0.0203511 3.04075 0.0983989 2.17825 0.720272 1.77199C1.2359 1.43762 1.92027 1.54075 2.31715 2.01575C3.07653 2.91887 4.50777 4.22512 6.52339 4.40637H7.47339C9.48905 4.22512 10.9203 2.91887 11.6797 2.01575Z"
            />
        </Icon>
    );
}
