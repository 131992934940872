import * as React from 'react';
import { connect } from 'react-redux';
import { setFilterParam } from '../../actions/searchActions';
import * as Colors from '../../consts/variables';
import { transformOptions } from '../../helpers/searchHelpers';
import { __ } from '../../helpers/TranslationService';
import { ISearchReducer } from '../../reducers/search';
import { SelectDropdown } from './SelectDropdown';
import { ShipRatings } from './ShipRatings';
import { ShipSizes } from './ShipSizes';
import { ThemeCruises } from './ThemeCruises';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        margin: '15px 0',
        padding: '15px',
        background: Colors.white,
        borderRadius: '6px',
    },
    heading: {
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '10px',
    },
};

const styleSelect: any = {
    marginTop: '10px',
    fontSize: '15px',
};

interface StateProps {
    search: ISearchReducer;
}

interface DispatchProps {
    setFilterParam: (ICruiseFilterParamValue) => () => any;
}

interface OwnProps {
    banderoles: string;
    ship: string;
    onShipChange(param: any): void;
    shipSizeMin: number;
    shipSizeMax: number;
    onShipSizeChange(param: any[]): void;
    shipCategory: number[];
    onShipCategoryChange(param: any[]): void;
    onBanderoleChange(param: any): void;
    autoSetFocus?: boolean;
}

type IProps = StateProps & DispatchProps & OwnProps;

function getInitialState(props: IProps) {
    const state = {
        ship: 'default',
    };
    const { filterParamsList } = props.search;
    const states = Object.keys(state);
    states.forEach((val, key) => {
        const param = filterParamsList.filter((_) => _.paramName === val);
        if (param.length === 1) {
            state[val] = Number(param.shift().paramValue);
        }
    });
    return state;
}

class ShipFiltersClass extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.state = getInitialState(props);
    }

    render() {
        const { shipNids } = this.props.search.searchFacets;
        const defaultShipOptions = { value: 'default', label: __('All ships', 'dreamlines') };
        const { search, banderoles, onBanderoleChange } = this.props;
        const themeCruiseProps = {
            search,
            banderoles,
            onBanderoleChange,
            autoSetFocus: this.props.autoSetFocus,
        };
        return (
            <div style={style.wrapper}>
                <div style={style.heading} className="filterSectionTitle">
                    <span>{__('ship', 'dreamlines')}</span>
                </div>

                <div style={styleSelect}>
                    <SelectDropdown
                        testSelectorName="ship-dropdown"
                        wrapperStyle={style.wrapperStyle}
                        nids={transformOptions(defaultShipOptions, shipNids)}
                        onChange={this.props.onShipChange}
                        value={
                            Array.isArray(this.props.ship) && this.props.ship.length > 1
                                ? 'default'
                                : this.props.ship
                        }
                        instanceId="ship company"
                        searchPlaceholder="Type in a ship name"
                        searchable
                        autoSetFocus={this.props.autoSetFocus}
                    />
                </div>
                <ThemeCruises {...themeCruiseProps} />
                <ShipSizes
                    search={this.props.search}
                    shipSizeMin={this.props.shipSizeMin}
                    shipSizeMax={this.props.shipSizeMax}
                    onShipSizeChange={this.props.onShipSizeChange}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: StateProps) => ({
    search: state.search,
});

const mapDispatchToProps = { setFilterParam };

// @TODO: Fix dispatch props
export const ShipFilters = connect<StateProps, any, any>(
    mapStateToProps,
    mapDispatchToProps,
)(ShipFiltersClass);
