import React from 'react';
import ContentLoader from 'react-content-loader';

export const PaginationLoader = () => (
    <ContentLoader
        speed={2}
        viewBox="0 0 768 36"
        backgroundColor="#fff"
        foregroundColor="#dde7ec"
        uniqueKey="pagination-loader"
    >
        <rect x="0" y="0" rx="5" ry="5" width="405" height="36" />
        <rect x="578" y="0" rx="5" ry="5" width="190" height="36" />
    </ContentLoader>
);
